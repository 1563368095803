import { $ as s, a as d, b } from "./useFocusRing.ff9f944e.js";
import { c as n } from "./utils.ec96ba96.js";
import l, { useRef as $, useEffect as p, useContext as P } from "react";
function f(e) {
  if (!e)
    return;
  let t = !0;
  return (o) => {
    let r = {
      ...o,
      preventDefault() {
        o.preventDefault();
      },
      isDefaultPrevented() {
        return o.isDefaultPrevented();
      },
      stopPropagation() {
        t ? console.error("stopPropagation is now the default behavior for events in React Spectrum. You can use continuePropagation() to revert this behavior.") : t = !0;
      },
      continuePropagation() {
        t = !1;
      },
      isPropagationStopped() {
        return t;
      }
    };
    e(r), t && o.stopPropagation();
  };
}
function x(e) {
  return {
    keyboardProps: e.isDisabled ? {} : {
      onKeyDown: f(e.onKeyDown),
      onKeyUp: f(e.onKeyUp)
    }
  };
}
let v = /* @__PURE__ */ l.createContext(null);
function D(e) {
  let t = P(v) || {};
  b(t, e);
  let { ref: o, ...r } = t;
  return r;
}
function h(e, t) {
  let { focusProps: o } = s(e), { keyboardProps: r } = x(e), u = n(o, r), c = D(t), i = e.isDisabled ? {} : c, a = $(e.autoFocus);
  return p(() => {
    a.current && t.current && d(t.current), a.current = !1;
  }, [
    t
  ]), {
    focusableProps: n({
      ...u,
      tabIndex: e.excludeFromTabOrder && !e.isDisabled ? -1 : void 0
    }, i)
  };
}
export {
  h as $
};
