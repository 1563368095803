import { find, pickBy } from 'lodash';
import { markets as baseMarkets } from 'config-ts';
import type { Market, MarketId, OptionalMarketIdRecord } from 'modules/market';

type ExistingMarkets = MarketId[] | OptionalMarketIdRecord<string>[];

const isExistingMarket = ({
  locale,
  market: routeMarket,
  markets,
}: {
  markets: ExistingMarkets;
  market: Market;
  locale?: string;
}) =>
  !!find(markets, (market) => {
    if (typeof market === 'object') {
      const [marketKey] = Object.keys(market);
      return (
        marketKey === routeMarket.id &&
        market[marketKey as keyof typeof market] === (locale || routeMarket.flavors[0].locale)
      );
    }

    return market === routeMarket.id;
  });

const getAvailableMarkets = (excluded?: Market['id'][]) =>
  pickBy(baseMarkets, (market) => !market.hidden && !excluded?.includes(market.id));

export { isExistingMarket, getAvailableMarkets };
export type { ExistingMarkets };
