import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import emailMisspelled, { top100 } from 'email-misspelled';
import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import { LOG_IN_TIMEOUT } from '../constants';

const useUserFields = () => {
  const [emailSuggestion, setEmailSuggestion] = useState<string>();
  const [ready, setReady] = useState(false);

  const { isApp } = useMode();
  const form = useFormContext();

  const formEmail = form.watch('email') || '';
  const emailSuggestionShown = emailSuggestion;

  const {
    pages: { privacy: privacyPage, terms: termsPage },
  } = useRouteData();

  const checkMisspelledEmail = () => {
    const emailChecker = emailMisspelled({ domains: top100 });
    const correctedEmail = emailChecker(formEmail)[0]?.corrected;
    setEmailSuggestion(correctedEmail);
  };

  const acceptSuggestedEmail = () => {
    form.setValue('email', emailSuggestion);
    form.trigger('email');
    setEmailSuggestion(undefined);
  };

  const updateEmailSuggestion = (email?: string) => setEmailSuggestion(email);

  return {
    emailSuggestion,
    emailSuggestionShown,
    form,
    formEmail,
    isApp,
    privacyPage,
    ready,
    termsPage,
    setReady,
    updateEmailSuggestion,
    acceptSuggestedEmail,
    checkMisspelledEmail,
  };
};

export { useUserFields, LOG_IN_TIMEOUT };
