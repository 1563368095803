import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as a } from "./chunks/Tabs.5d8afeff.js";
import { T as t } from "./chunks/TabsContext.9430d3b8.js";
import "react";
import "./chunks/Collection.2f43ceb9.js";
import "./chunks/Hidden.dae830d2.js";
import "react-dom";
import "./chunks/utils.ec96ba96.js";
import "./chunks/clsx.0d472180.js";
import "./chunks/useFocusRing.ff9f944e.js";
import "./chunks/useSingleSelectListState.a749d1f9.js";
import "./chunks/useListState.accd1c78.js";
import "./chunks/useHasTabbableChild.9dcc9a74.js";
const e = ({ disabled: r, ...i }) => /* @__PURE__ */ o.jsx(t, { children: /* @__PURE__ */ o.jsx(a, { keyboardActivation: "manual", isDisabled: r, ...i }) });
try {
  e.displayName = "Tabs", e.__docgenInfo = { description: "", displayName: "Tabs", props: { disabled: { defaultValue: null, description: "", name: "disabled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  e as Tabs
};
