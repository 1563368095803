import { Button } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useTranslation } from 'next-i18next';
import { useClickAway } from 'react-use';
import { useRef } from 'react';
import Flag from 'components/common/Flag';
import { useRouteData } from 'context/RouteDataProvider';
import { getCountryName } from 'services/countries';
import { getCountry } from 'utils/locale';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
import { MARKET_SWITCHER_DIALOG_ID, MarketSwitcherModal } from './MarketSwitcherModal';

type MarketSwitcherProps = {
  buttonClassName?: string;
};

const BUTTON_DATA_ATTRIBUTE = 'data-point-button';

const MarketSwitcher = ({ buttonClassName }: MarketSwitcherProps) => {
  const { t } = useTranslation();
  const { locale, market } = useRouteData();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isModalOpen: hamburgerModalOpen } = useModal('hamburger');
  const { isModalOpen: languageModalOpen } = useModal('languageSelection');
  const { toggle: toggleMarketModal, close: closeMarketSelectionModal } =
    useModal('marketSelection');

  useClickAway<MouseEvent>(buttonRef, (event) => {
    const targetElement = event.target as HTMLElement;
    const insideModalClicked = targetElement.closest(`#${MARKET_SWITCHER_DIALOG_ID}`);
    const anyHeaderButtonClicked = targetElement.closest('img') || targetElement.closest('a');
    const closeAllowed =
      anyHeaderButtonClicked && !insideModalClicked && !hamburgerModalOpen && !languageModalOpen;

    if (closeAllowed) {
      closeMarketSelectionModal();
    }
  });

  return (
    <>
      <Button
        className={cn(
          'bg-white pl-1 pr-1.5 uppercase shadow-none transition-colors duration-100 ease-out enabled:hover:bg-transparent enabled:hover:text-blue enabled:hover:shadow-none',
          buttonClassName,
        )}
        size="m"
        ref={buttonRef}
        variant="outlined"
        onClick={toggleMarketModal}
        aria-label={`${t('marketSwitcher.changeLabel')} ${getCountryName(market.countryCode)}`}
        {...{ [BUTTON_DATA_ATTRIBUTE]: true }}
      >
        <span className="flex flex-row items-center gap-1">
          <Flag
            as={undefined}
            aria-hidden="true"
            countryCode={market.countryCode}
            size={24}
            rounded
            className="-my-1"
          />

          {locale.length > 2 ? locale : getCountry(locale)}
        </span>
      </Button>

      {!hamburgerModalOpen && <MarketSwitcherModal />}
    </>
  );
};

export { MarketSwitcher };
export type { MarketSwitcherProps };
