import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import store from 'services/store';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
import { LanguageSelectionModal } from '../MarketSwitcher';
import { PREVENT_SUGGEST_MARKET_NAME } from '../../utils';
import { SuggestLocationModal } from './SuggestLocationModal';
import { useSuggestedMarket, useForced, useModalType } from './hooks';
import { SuggestCheckoutModal } from './SuggestCheckoutModal';

type RouteData = {
  preventGeoModal?: boolean;
};

const SuggestCorrectMarketModal = () => {
  const { isApp } = useMode();
  const { alternativePaths, preventGeoModal } = useRouteData<RouteData>();
  const { isModalOpen: marketSelectionModalOpen } = useModal('marketSelection');
  const suggestedMarket = useSuggestedMarket();

  const modalType = useModalType();
  const forced = useForced();

  const prevented = !!(
    isApp ||
    (!forced && (preventGeoModal || store.has(PREVENT_SUGGEST_MARKET_NAME)))
  );

  if (prevented || !suggestedMarket || !alternativePaths?.[suggestedMarket.flavors[0].locale]) {
    return null;
  }

  return (
    <>
      {modalType === 'checkout' ? <SuggestCheckoutModal /> : <SuggestLocationModal />}

      {!marketSelectionModalOpen && <LanguageSelectionModal />}
    </>
  );
};

export { SuggestCorrectMarketModal };
