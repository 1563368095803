import { createContext, useContext } from 'react';
import type { BusinessTestimonial, Faq, Founder, Page, Review } from 'types/content';
import type { Market } from 'modules/market';
import type { CanonicalType } from 'constants/seo';
import type { ContentEntries } from 'modules/prerender/types/content';

type Countries = Record<string, string>;

type RouteDataContextState<T = Record<string, unknown>> = {
  page?: Page;
  content?: ContentEntries;
  pages: Record<string, Page>;
  market: Market;
  locale: string;
  faq?: Faq[];
  basePath?: string;
  reviews?: Review[];
  preventGeoModal?: boolean;
  preventVoucherBar?: boolean;
  businessTestimonials?: BusinessTestimonial[];
  founders?: Founder[];
  alternativePaths?: Record<string, string>;
  meta?: {
    noIndex: boolean;
    canonical: {
      basePathNeeded: boolean;
      path: string;
      type: CanonicalType;
    };
    title?: string;
    description?: string;
  };
  countries?: {
    countries: Countries;
  };
  identifierPopupEnabled?: boolean;
} & T;

const RouteDataContext = createContext<RouteDataContextState | null>(null);
const RouteDataProvider = RouteDataContext.Provider;
const useRouteData = <T>() => useContext(RouteDataContext) as RouteDataContextState<T>;

export { RouteDataContext, RouteDataProvider, useRouteData };
export type { RouteDataContextState };
