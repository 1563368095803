import { c as x } from "./cn.fbf70112.js";
function N(e) {
  var r, d, l = "";
  if (typeof e == "string" || typeof e == "number")
    l += e;
  else if (typeof e == "object")
    if (Array.isArray(e)) {
      var t = e.length;
      for (r = 0; r < t; r++)
        e[r] && (d = N(e[r])) && (l && (l += " "), l += d);
    } else
      for (d in e)
        e[d] && (l && (l += " "), l += d);
  return l;
}
function h() {
  for (var e, r, d = 0, l = "", t = arguments.length; d < t; d++)
    (e = arguments[d]) && (r = N(e)) && (l && (l += " "), l += r);
  return l;
}
const b = (e) => typeof e == "boolean" ? `${e}` : e === 0 ? "0" : e, O = (e) => {
  const r = function() {
    for (var t = arguments.length, n = new Array(t), o = 0; o < t; o++)
      n[o] = arguments[o];
    var a, s;
    return typeof (e == null || (a = e.hooks) === null || a === void 0 ? void 0 : a["cx:done"]) < "u" ? e == null ? void 0 : e.hooks["cx:done"](h(n)) : typeof (e == null || (s = e.hooks) === null || s === void 0 ? void 0 : s.onComplete) < "u" ? e == null ? void 0 : e.hooks.onComplete(h(n)) : h(n);
  };
  return {
    compose: function() {
      for (var t = arguments.length, n = new Array(t), o = 0; o < t; o++)
        n[o] = arguments[o];
      return (a) => {
        const s = Object.fromEntries(Object.entries(a || {}).filter((i) => {
          let [f] = i;
          return ![
            "class",
            "className"
          ].includes(f);
        }));
        return r(n.map((i) => i(s)), a == null ? void 0 : a.class, a == null ? void 0 : a.className);
      };
    },
    cva: (t) => (n) => {
      var o;
      if ((t == null ? void 0 : t.variants) == null)
        return r(t == null ? void 0 : t.base, n == null ? void 0 : n.class, n == null ? void 0 : n.className);
      const { variants: a, defaultVariants: s } = t, i = Object.keys(a).map((u) => {
        const c = n == null ? void 0 : n[u], m = s == null ? void 0 : s[u], v = b(c) || b(m);
        return a[u][v];
      }), f = {
        ...s,
        // remove `undefined` props
        ...n && Object.entries(n).reduce((u, c) => {
          let [m, v] = c;
          return typeof v > "u" ? u : {
            ...u,
            [m]: v
          };
        }, {})
      }, A = t == null || (o = t.compoundVariants) === null || o === void 0 ? void 0 : o.reduce((u, c) => {
        let { class: m, className: v, ...j } = c;
        return Object.entries(j).every((V) => {
          let [g, y] = V;
          const C = f[g];
          return Array.isArray(y) ? y.includes(C) : C === y;
        }) ? [
          ...u,
          m,
          v
        ] : u;
      }, []);
      return r(t == null ? void 0 : t.base, i, A, n == null ? void 0 : n.class, n == null ? void 0 : n.className);
    },
    cx: r
  };
}, { cva: P, compose: w } = O({
  hooks: {
    onComplete: (e) => x(e)
  }
});
export {
  P as a,
  w as c
};
