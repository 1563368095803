import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c } from "./chunks/cn.fbf70112.js";
import { $ as d } from "./chunks/ListBox.18f7c588.js";
import { forwardRef as m } from "react";
import "./chunks/Collection.2f43ceb9.js";
import "./chunks/Hidden.dae830d2.js";
import "react-dom";
import "./chunks/utils.ec96ba96.js";
import "./chunks/clsx.0d472180.js";
import "./chunks/Header.8e15f16d.js";
import "./chunks/useFocusRing.ff9f944e.js";
import "./chunks/useListState.accd1c78.js";
const p = ({
  title: t,
  titleId: o,
  ...s
}, r) => /* @__PURE__ */ e.jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 16 16", width: 16, height: 16, "aria-hidden": !0, "data-slot": "icon", ref: r, "aria-labelledby": o, ...s, children: [
  t ? /* @__PURE__ */ e.jsx("title", { id: o, children: t }) : null,
  /* @__PURE__ */ e.jsx("path", { fill: "currentColor", fillRule: "evenodd", d: "M13.143 4.202a.65.65 0 0 0-1.147-.312L6.5 10.654 3.504 6.967a.65.65 0 1 0-1.008.82l3.5 4.308a.65.65 0 0 0 1.008 0l6-7.385a.647.647 0 0 0 .139-.508Z", clipRule: "evenodd" })
] }), i = m(p), x = i;
i.displayName = "CheckMarkIconXS";
const a = ({ children: t, className: o, disabled: s, ...r }) => {
  const l = r.textValue || (typeof t == "string" ? t : void 0);
  return /* @__PURE__ */ e.jsx(
    d,
    {
      ...r,
      isDisabled: s,
      textValue: l,
      className: c(
        "flex cursor-pointer items-center justify-between px-2 py-1.5 text-s text-black",
        "hover:bg-grey-100 focus:bg-grey-100 selected:pointer-events-none selected:bg-blue-50 selected:focus:bg-blue-100 disabled:pointer-events-none disabled:text-grey-600",
        o
      ),
      children: ({ isSelected: n }) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
        /* @__PURE__ */ e.jsx("span", { className: "flex items-center gap-2 truncate", children: t }),
        n && /* @__PURE__ */ e.jsx(x, { className: "text-black" })
      ] })
    }
  );
};
try {
  a.displayName = "ComboBoxItem", a.__docgenInfo = { description: "", displayName: "ComboBoxItem", props: { disabled: { defaultValue: null, description: "", name: "disabled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  a as ComboBoxItem
};
