import { j as r } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as e } from "./chunks/clsx.0d472180.js";
import { b as i } from "./chunks/Tabs.5d8afeff.js";
import { m } from "./chunks/motion.bdfaf94a.js";
import "react";
import "./chunks/Collection.2f43ceb9.js";
import "./chunks/Hidden.dae830d2.js";
import "react-dom";
import "./chunks/utils.ec96ba96.js";
import "./chunks/useFocusRing.ff9f944e.js";
import "./chunks/useSingleSelectListState.a749d1f9.js";
import "./chunks/useListState.accd1c78.js";
import "./chunks/useHasTabbableChild.9dcc9a74.js";
const o = ({ className: t, ...a }) => /* @__PURE__ */ r.jsx(m.div, { layoutScroll: !0, className: "relative -m-1 flex snap-x snap-mandatory overflow-x-auto p-1", children: /* @__PURE__ */ r.jsx(
  i,
  {
    ...a,
    className: e(
      t,
      "relative flex flex-1",
      // Bottom border
      'after:absolute after:-inset-x-0 after:bottom-0 after:block after:h-[2px] after:bg-grey-200 after:content-[""]'
    )
  }
) });
try {
  o.displayName = "TabList", o.__docgenInfo = { description: "", displayName: "TabList", props: {} };
} catch {
}
export {
  o as TabList
};
