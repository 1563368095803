import { useTranslation } from 'next-i18next';
import { Badge, Container } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import QuotationMarkIllustration from 'assets/illustrations/quotation-mark.svg';

type ReviewCardProps = {
  comment: string;
  name: string;
  stroke?: boolean;
};

const ReviewCard = ({ comment, name, stroke = false }: ReviewCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        'rounded-lg flex flex-col gap-3 rounded-s bg-white p-3',
        stroke && 'border border-solid border-grey-400 shadow-m',
      )}
    >
      <header className="flex flex-row items-start justify-between">
        <QuotationMarkIllustration className="h-6 w-6 text-grey-400" />

        <Badge icon="check" variant="greenLight" size="s">
          <p className="text-xs font-bold">{t('userReviews.verifiedReview')}</p>
        </Badge>
      </header>
      <p className="text-m">{comment}</p>
      <div className="flex-1" />
      <label className="text-s font-bold text-grey-600">{name}</label>
    </div>
  );
};

type UserReviewsSectionProps = {
  variant?: 'white' | 'blue';
};

const UserReviewsSection = ({ variant = 'white' }: UserReviewsSectionProps) => {
  const { t } = useTranslation('common');

  return (
    <section className={cn('py-16', variant === 'white' ? 'bg-white' : 'bg-blue-50')}>
      <Container>
        <div className="mb-8 flex flex-col items-center gap-2">
          <h2 className="text-h-l text-center font-bold">{t('userReviews.title')}</h2>

          <p className="text-center text-xl">{t('userReviews.subtitle')}</p>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <ReviewCard comment={t('userReviews.jcReview')} name="Jc" stroke={variant === 'white'} />

          <ReviewCard
            comment={t('userReviews.sashaReview')}
            name="Sasha"
            stroke={variant === 'white'}
          />

          <ReviewCard
            comment={t('userReviews.rolandoReview')}
            name="Rolando"
            stroke={variant === 'white'}
          />
        </div>
      </Container>
    </section>
  );
};

export { UserReviewsSection };
