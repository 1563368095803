import { create } from 'zustand';

type IdentifierPopupStore = {
  shown?: boolean;
  actions: {
    setShown: (shown: boolean) => void;
  };
};

const useIdentifierPopupStore = create<IdentifierPopupStore>((set) => ({
  visible: false,
  actions: {
    setShown: (shown: boolean) => set({ shown }),
  },
}));

const useIdentifierPopupShown = () => useIdentifierPopupStore((store) => store.shown);
const useIdentifierPopupActions = () => useIdentifierPopupStore((store) => store.actions);

export { useIdentifierPopupShown, useIdentifierPopupActions };
