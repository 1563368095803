import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as v } from "./chunks/index.72a67dcc.js";
import { b as y } from "./chunks/TagGroup.45dff395.js";
import { forwardRef as N, useContext as z } from "react";
import { c as a } from "./chunks/cn.fbf70112.js";
import { Icon as s } from "./icon.mjs";
import { a as w } from "./chunks/TagGroupContext.b8226d23.js";
import "./chunks/utils.ec96ba96.js";
import "./chunks/clsx.0d472180.js";
import "./chunks/Hidden.dae830d2.js";
import "react-dom";
import "./chunks/useFocusRing.ff9f944e.js";
import "./chunks/useFocusable.9c354ded.js";
import "./chunks/Collection.2f43ceb9.js";
import "./chunks/useField.5a868857.js";
import "./chunks/useListState.accd1c78.js";
import "./chunks/ListBox.18f7c588.js";
import "./chunks/Header.8e15f16d.js";
import "./chunks/useHasTabbableChild.9dcc9a74.js";
import "./chunks/styles.077839a8.js";
const c = N(
  ({
    children: o,
    size: d,
    variant: p,
    leadingAddon: u,
    status: l,
    className: m,
    ...g
  }, b) => {
    const { size: x, variant: f } = z(w), e = d || x, r = p || f, h = typeof o == "string" ? o : void 0, i = l === "error" ? /* @__PURE__ */ t.jsx(s, { name: "attention-filled", size: "xs", className: "flex-shrink-0 text-red" }) : u;
    return /* @__PURE__ */ t.jsx(
      y,
      {
        textValue: h,
        ...g,
        ref: b,
        className: a(
          "twp inline-flex items-center rounded-pill text-center outline outline-1 transition ease-out selection-single:cursor-pointer selection-multiple:cursor-pointer focus-visible:ring pressed:scale-95",
          {
            "px-1 py-0.75": e === "l",
            "px-0.75 py-0.5": e === "m"
          },
          {
            "bg-white text-black outline-transparent hover:bg-grey-200 hover:outline-grey-200 selected:bg-blue selected:text-white selected:outline-blue": r === "white",
            "bg-white text-black outline-grey-200 hover:bg-grey-200 hover:outline-grey-200 selected:bg-blue selected:text-white selected:outline-blue": r === "outline",
            "bg-blue-500 text-white outline-blue-500 hover:bg-blue hover:outline-blue selected:bg-blue-900 selected:text-white selected:outline-blue-900": r === "blue",
            "bg-grey-900 text-white outline-transparent hover:bg-grey-700 hover:outline-grey-700 selected:bg-blue selected:outline-blue": r === "black",
            "bg-red-100 text-black outline-red-300": l === "error"
          },
          m
        ),
        children: ({ allowsRemoving: n }) => /* @__PURE__ */ t.jsxs(
          "div",
          {
            className: a("flex items-center gap-0.5", {
              "text-s": e === "l",
              "text-xs": e === "m"
            }),
            children: [
              i,
              /* @__PURE__ */ t.jsx(
                "div",
                {
                  className: a(
                    {
                      "[--spacing:2px]": e === "m",
                      "[--spacing:4px]": e === "l"
                    },
                    {
                      "pr-[--spacing]": !n,
                      "pl-[--spacing]": !i
                    }
                  ),
                  children: o
                }
              ),
              n && /* @__PURE__ */ t.jsxs(
                v,
                {
                  slot: "remove",
                  className: "group relative isolate translate-x-[1px] outline-none",
                  children: [
                    /* @__PURE__ */ t.jsx(
                      "div",
                      {
                        className: a(
                          "absolute inset-0 -z-10 rounded-full bg-transparent transition-colors group-hover:bg-black/10",
                          {
                            "group-hover:bg-white/20": r === "black",
                            "group-hover:bg-red/10": l === "error"
                          },
                          {
                            "scale-125": e === "m",
                            "scale-150": e === "l"
                          }
                        )
                      }
                    ),
                    /* @__PURE__ */ t.jsx(s, { name: "close", size: "xs" })
                  ]
                }
              )
            ]
          }
        )
      }
    );
  }
);
try {
  c.displayName = "Tag", c.__docgenInfo = { description: "", displayName: "Tag", props: { size: { defaultValue: null, description: "", name: "size", required: !1, type: { name: "TagSize" } }, className: { defaultValue: null, description: "The CSS [className](https://developer.mozilla.org/en-US/docs/Web/API/Element/className) for the element. A function may be provided to compute the class based on component state.", name: "className", required: !1, type: { name: "string | (string & ((values: TagRenderProps & { defaultClassName: string; }) => string))" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "TagVariant" } }, leadingAddon: { defaultValue: null, description: "", name: "leadingAddon", required: !1, type: { name: "ReactNode" } }, children: { defaultValue: null, description: "The children of the component. A function may be provided to alter the children based on component state.", name: "children", required: !1, type: { name: "ReactNode & (ReactNode | ((values: TagRenderProps & { defaultChildren: ReactNode; }) => ReactNode))" } } } };
} catch {
}
export {
  c as Tag
};
