import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { g as l } from "./chunks/styles.077839a8.js";
import { Button as n } from "./button.mjs";
import "react";
import "./chunks/cn.fbf70112.js";
import "./chunks/cva.09768d84.js";
import "./icon.mjs";
import "./chunks/clsx.0d472180.js";
import "./chunks/warnings.8f6035a3.js";
import "./chunks/animations.c777cd9c.js";
import "./chunks/motion.bdfaf94a.js";
import "./anchorOrButton.mjs";
import "./chunks/index.1b20cf8f.js";
import "./spinner.mjs";
import "./chunks/useTranslatableUILabel.30fa9f56.js";
import "./chunks/TranslatableUIProvider.d50c2f21.js";
const a = ({ ...e }) => /* @__PURE__ */ t.jsx(n, { className: l.root, size: "s", ...e });
try {
  a.displayName = "TextFieldButton", a.__docgenInfo = { description: "", displayName: "TextFieldButton", props: { fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  a as TextFieldButton
};
