import { useCrawlerDetect } from 'hooks/useCrawlerDetect';
import { useUserInteraction } from 'context/UserInteractionProvider';
import { SuggestCorrectMarketModal } from './SuggestCorrectMarketModal';
import { useSuggestCorrectMarket } from './hooks';

type LazySuggestCorrectMarketProps = {
  immediate?: boolean;
};

const LazySuggestCorrectMarketModal = ({ immediate }: LazySuggestCorrectMarketProps) => {
  const userInteraction = useUserInteraction();
  const crawlerDetected = useCrawlerDetect();

  useSuggestCorrectMarket();

  if (crawlerDetected || (!immediate && !userInteraction)) {
    return null;
  }

  return <SuggestCorrectMarketModal />;
};

export { LazySuggestCorrectMarketModal };
export type { LazySuggestCorrectMarketProps };
