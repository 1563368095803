import { c as d, d as p } from "./useListState.accd1c78.js";
import { $ as m, d as $, c as h } from "./utils.ec96ba96.js";
import { $ as y } from "./Hidden.dae830d2.js";
import w, { createContext as S, useMemo as x } from "react";
import { a as v } from "./ListBox.18f7c588.js";
function z(n, e) {
  let t = n;
  for (d(t, e) && (t = t.parentElement); t && !d(t, e); )
    t = t.parentElement;
  return t || document.scrollingElement || document.documentElement;
}
const L = /* @__PURE__ */ S({}), T = /* @__PURE__ */ y(function(e, t) {
  [e, t] = m(e, t, L);
  let { elementType: r = "label", ...a } = e;
  return /* @__PURE__ */ w.createElement(r, {
    className: "react-aria-Label",
    ...a,
    ref: t
  });
}), P = Symbol.for("react-aria.i18n.locale"), E = Symbol.for("react-aria.i18n.strings");
let i;
class s {
  /** Returns a localized string for the given key and locale. */
  getStringForLocale(e, t) {
    let a = this.getStringsForLocale(t)[e];
    if (!a)
      throw new Error(`Could not find intl message ${e} in ${t} locale`);
    return a;
  }
  /** Returns all localized strings for the given locale. */
  getStringsForLocale(e) {
    let t = this.strings[e];
    return t || (t = F(e, this.strings, this.defaultLocale), this.strings[e] = t), t;
  }
  static getGlobalDictionaryForPackage(e) {
    if (typeof window > "u")
      return null;
    let t = window[P];
    if (i === void 0) {
      let a = window[E];
      if (!a)
        return null;
      i = {};
      for (let l in a)
        i[l] = new s({
          [t]: a[l]
        }, t);
    }
    let r = i == null ? void 0 : i[e];
    if (!r)
      throw new Error(`Strings for package "${e}" were not included by LocalizedStringProvider. Please add it to the list passed to createLocalizedStringDictionary.`);
    return r;
  }
  constructor(e, t = "en-US") {
    this.strings = Object.fromEntries(Object.entries(e).filter(([, r]) => r)), this.defaultLocale = t;
  }
}
function F(n, e, t = "en-US") {
  if (e[n])
    return e[n];
  let r = I(n);
  if (e[r])
    return e[r];
  for (let a in e)
    if (a.startsWith(r + "-"))
      return e[a];
  return e[t];
}
function I(n) {
  return Intl.Locale ? new Intl.Locale(n).language : n.split("-")[0];
}
const b = /* @__PURE__ */ new Map(), u = /* @__PURE__ */ new Map();
class M {
  /** Formats a localized string for the given key with the provided variables. */
  format(e, t) {
    let r = this.strings.getStringForLocale(e, this.locale);
    return typeof r == "function" ? r(t, this) : r;
  }
  plural(e, t, r = "cardinal") {
    let a = t["=" + e];
    if (a)
      return typeof a == "function" ? a() : a;
    let l = this.locale + ":" + r, o = b.get(l);
    o || (o = new Intl.PluralRules(this.locale, {
      type: r
    }), b.set(l, o));
    let c = o.select(e);
    return a = t[c] || t.other, typeof a == "function" ? a() : a;
  }
  number(e) {
    let t = u.get(this.locale);
    return t || (t = new Intl.NumberFormat(this.locale), u.set(this.locale, t)), t.format(e);
  }
  select(e, t) {
    let r = e[t] || e.other;
    return typeof r == "function" ? r() : r;
  }
  constructor(e, t) {
    this.locale = e, this.strings = t;
  }
}
const g = /* @__PURE__ */ new WeakMap();
function B(n) {
  let e = g.get(n);
  return e || (e = new s(n), g.set(n, e)), e;
}
function C(n, e) {
  return e && s.getGlobalDictionaryForPackage(e) || B(n);
}
function U(n, e) {
  let { locale: t } = p(), r = C(n, e);
  return x(() => new M(t, r), [
    t,
    r
  ]);
}
function W(n) {
  let { description: e, errorMessage: t, isInvalid: r, validationState: a } = n, { labelProps: l, fieldProps: o } = v(n), c = $([
    !!e,
    !!t,
    r,
    a
  ]), f = $([
    !!e,
    !!t,
    r,
    a
  ]);
  return o = h(o, {
    "aria-describedby": [
      c,
      // Use aria-describedby for error message because aria-errormessage is unsupported using VoiceOver or NVDA. See https://github.com/adobe/react-spectrum/issues/1346#issuecomment-740136268
      f,
      n["aria-describedby"]
    ].filter(Boolean).join(" ") || void 0
  }), {
    labelProps: l,
    fieldProps: o,
    descriptionProps: {
      id: c
    },
    errorMessageProps: {
      id: f
    }
  };
}
export {
  U as $,
  z as a,
  W as b,
  L as c,
  T as d
};
