import { c as x, $ as I, e as w, f as y } from "./utils.ec96ba96.js";
import L, { createContext as E, useRef as F, useEffect as N } from "react";
import { $ as O } from "./Hidden.dae830d2.js";
import { c as S, d as A, i as V, h as M } from "./useFocusRing.ff9f944e.js";
import { $ as R } from "./useFocusable.9c354ded.js";
function H(t, e) {
  let {
    elementType: i = "button",
    isDisabled: d,
    onPress: n,
    onPressStart: s,
    onPressEnd: o,
    onPressUp: p,
    onPressChange: l,
    preventFocusOnPress: $,
    allowFocusWhenDisabled: g,
    // @ts-ignore
    onClick: f,
    href: h,
    target: P,
    rel: u,
    type: c = "button"
  } = t, a;
  i === "button" ? a = {
    type: c,
    disabled: d
  } : a = {
    role: "button",
    tabIndex: d ? void 0 : 0,
    href: i === "a" && !d ? h : void 0,
    target: i === "a" ? P : void 0,
    type: i === "input" ? c : void 0,
    disabled: i === "input" ? d : void 0,
    "aria-disabled": !d || i === "input" ? void 0 : d,
    rel: i === "a" ? u : void 0
  };
  let { pressProps: b, isPressed: v } = S({
    onPressStart: s,
    onPressEnd: o,
    onPressChange: l,
    onPress: n,
    onPressUp: p,
    isDisabled: d,
    preventFocusOnPress: $,
    ref: e
  }), { focusableProps: m } = R(t, e);
  g && (m.tabIndex = d ? -1 : m.tabIndex);
  let _ = x(m, b, A(t, {
    labelable: !0
  }));
  return {
    isPressed: v,
    buttonProps: x(a, _, {
      "aria-haspopup": t["aria-haspopup"],
      "aria-expanded": t["aria-expanded"],
      "aria-controls": t["aria-controls"],
      "aria-pressed": t["aria-pressed"],
      onClick: (D) => {
        f && (f(D), console.warn("onClick is deprecated, please use onPress"));
      }
    })
  };
}
const T = 7e3;
let r = null;
function C(t, e = "assertive", i = T) {
  r ? r.announce(t, e, i) : (r = new U(), (typeof IS_REACT_ACT_ENVIRONMENT == "boolean" ? IS_REACT_ACT_ENVIRONMENT : typeof jest < "u") ? r.announce(t, e, i) : setTimeout(() => {
    r != null && r.isAttached() && (r == null || r.announce(t, e, i));
  }, 100));
}
class U {
  isAttached() {
    var e;
    return (e = this.node) === null || e === void 0 ? void 0 : e.isConnected;
  }
  createLog(e) {
    let i = document.createElement("div");
    return i.setAttribute("role", "log"), i.setAttribute("aria-live", e), i.setAttribute("aria-relevant", "additions"), i;
  }
  destroy() {
    this.node && (document.body.removeChild(this.node), this.node = null);
  }
  announce(e, i = "assertive", d = T) {
    var n, s;
    if (!this.node)
      return;
    let o = document.createElement("div");
    typeof e == "object" ? (o.setAttribute("role", "img"), o.setAttribute("aria-labelledby", e["aria-labelledby"])) : o.textContent = e, i === "assertive" ? (n = this.assertiveLog) === null || n === void 0 || n.appendChild(o) : (s = this.politeLog) === null || s === void 0 || s.appendChild(o), e !== "" && setTimeout(() => {
      o.remove();
    }, d);
  }
  clear(e) {
    this.node && ((!e || e === "assertive") && this.assertiveLog && (this.assertiveLog.innerHTML = ""), (!e || e === "polite") && this.politeLog && (this.politeLog.innerHTML = ""));
  }
  constructor() {
    this.node = null, this.assertiveLog = null, this.politeLog = null, typeof document < "u" && (this.node = document.createElement("div"), this.node.dataset.liveAnnouncer = "true", Object.assign(this.node.style, {
      border: 0,
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
      whiteSpace: "nowrap"
    }), this.assertiveLog = this.createLog("assertive"), this.node.appendChild(this.assertiveLog), this.politeLog = this.createLog("polite"), this.node.appendChild(this.politeLog), document.body.prepend(this.node));
  }
}
const k = /* @__PURE__ */ E(null), j = /* @__PURE__ */ new Set([
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "name",
  "value"
]), B = /* @__PURE__ */ E({}), J = /* @__PURE__ */ O(function(e, i) {
  [e, i] = I(e, i, B), e = K(e);
  let d = e, { isPending: n } = d, { buttonProps: s, isPressed: o } = H(e, i), { focusProps: p, isFocused: l, isFocusVisible: $ } = V(e), { hoverProps: g, isHovered: f } = M({
    ...e,
    isDisabled: e.isDisabled || n
  }), h = {
    isHovered: f,
    isPressed: (d.isPressed || o) && !n,
    isFocused: l,
    isFocusVisible: $,
    isDisabled: e.isDisabled || !1,
    isPending: n ?? !1
  }, P = w({
    ...e,
    values: h,
    defaultClassName: "react-aria-Button"
  }), u = y(s.id), c = y(), a = s["aria-labelledby"];
  n && (a ? a = `${a} ${c}` : s["aria-label"] && (a = `${u} ${c}`));
  let b = F(n);
  return N(() => {
    let v = {
      "aria-labelledby": a || u
    };
    (!b.current && l && n || b.current && l && !n) && C(v, "assertive"), b.current = n;
  }, [
    n,
    l,
    a,
    u
  ]), /* @__PURE__ */ L.createElement("button", {
    ...A(e, {
      propNames: j
    }),
    ...x(s, p, g),
    ...P,
    type: s.type === "submit" && n ? "button" : s.type,
    id: u,
    ref: i,
    "aria-labelledby": a,
    slot: e.slot || void 0,
    "aria-disabled": n ? "true" : s["aria-disabled"],
    "data-disabled": e.isDisabled || void 0,
    "data-pressed": h.isPressed || void 0,
    "data-hovered": f || void 0,
    "data-focused": l || void 0,
    "data-pending": n || void 0,
    "data-focus-visible": $ || void 0
  }, /* @__PURE__ */ L.createElement(k.Provider, {
    value: {
      id: c
    }
  }, P.children));
});
function K(t) {
  return t.isPending && (t.onPress = void 0, t.onPressStart = void 0, t.onPressEnd = void 0, t.onPressChange = void 0, t.onPressUp = void 0, t.onKeyDown = void 0, t.onKeyUp = void 0, t.onClick = void 0, t.href = void 0), t;
}
export {
  J as $,
  C as a,
  B as b
};
