import isPast from 'date-fns/is_past';
import differenceInDays from 'date-fns/difference_in_days';
import addMonths from 'date-fns/add_months';

const DAYS_UNTIL_EXPIRATION_SOON = 28;

const getExpiryDate = (expiryMonth: string, expiryYear: string) => {
  const currentYear = new Date().getFullYear();
  const centuryPrefix = currentYear.toString().slice(0, 2);
  const fullYear = `${centuryPrefix}${expiryYear}`;
  return addMonths(new Date(`${fullYear}-${expiryMonth}`), 1);
};

const validateCardExpiry = (expiryMonth: string, expiryYear: string) => {
  const expiryDate = getExpiryDate(expiryMonth, expiryYear);
  return isPast(expiryDate);
};

const validateCardDaysUntilExpiry = (expiryMonth: string, expiryYear: string) => {
  const expiryDate = getExpiryDate(expiryMonth, expiryYear);
  const daysUntilExpiration = differenceInDays(expiryDate, new Date());
  return daysUntilExpiration >= 0 && daysUntilExpiration <= DAYS_UNTIL_EXPIRATION_SOON;
};

export { validateCardExpiry, validateCardDaysUntilExpiry };
