import { useQuery } from '@tanstack/react-query';
import { getAccessToken } from 'services/session';
import type { PaymentOption } from '../types';
import { getPaymentMethods } from '../api';
import { validateCardExpiry, validateCardDaysUntilExpiry } from '../utils/date';

const CARD_PAYMENT_METHODS_QUERY_KEY = {
  shared: ['cardPaymentMethods'],
  personal: (accessToken?: string) => ['cardPaymentMethods', accessToken],
};

const mapPaymentMethods = (paymentOption: PaymentOption) => {
  const mappedPaymentMethods = paymentOption.storedPaymentMethods?.map((storedPaymentMethod) => ({
    ...storedPaymentMethod,
    lastFourDigits: storedPaymentMethod.lastFour,
    expired: validateCardExpiry(storedPaymentMethod.expiryMonth, storedPaymentMethod.expiryYear),
    expiresSoon: validateCardDaysUntilExpiry(
      storedPaymentMethod.expiryMonth,
      storedPaymentMethod.expiryYear,
    ),
  }));

  return {
    ...paymentOption,
    storedPaymentMethods: mappedPaymentMethods,
  };
};

const usePaymentMethodsQuery = () => {
  const accessToken = getAccessToken();

  const { data, isPending } = useQuery({
    queryKey: CARD_PAYMENT_METHODS_QUERY_KEY.personal(accessToken),
    queryFn: getPaymentMethods,
    select: mapPaymentMethods,
    staleTime: Infinity,
    retry: false,
  });

  const { paymentMethods, storedPaymentMethods } = data || {};

  const formattedPaymentMethods = storedPaymentMethods?.map(
    ({ id, expiryMonth, expiryYear, expired, lastFourDigits, isPrimary }) => ({
      isPrimary,
      value: id,
      label: { expired, expiryMonth, expiryYear, lastFourDigits },
    }),
  );

  const validPaymentMethods = formattedPaymentMethods?.filter(({ label }) => !label.expired);
  const defaultPaymentMethod =
    validPaymentMethods?.find(({ isPrimary }) => isPrimary)?.value ||
    validPaymentMethods?.[0]?.value;

  return {
    paymentMethods,
    storedPaymentMethods,
    loadingStoredPayments: isPending,
    defaultPaymentMethod,
    formattedPaymentMethods,
  };
};

export { usePaymentMethodsQuery, CARD_PAYMENT_METHODS_QUERY_KEY };
