import Xe, { useContext as xe, createContext as Re, useRef as R, useCallback as x, useEffect as Y, useState as z, useMemo as Ke } from "react";
import { a as Me, m as B, c as Fe, j as je, b as ze } from "./utils.ec96ba96.js";
const m = (e) => {
  var t;
  return (t = e == null ? void 0 : e.ownerDocument) !== null && t !== void 0 ? t : document;
}, S = (e) => e && "window" in e && e.window === e ? e : m(e).defaultView || window, qe = /* @__PURE__ */ new Set([
  "id"
]), Je = /* @__PURE__ */ new Set([
  "aria-label",
  "aria-labelledby",
  "aria-describedby",
  "aria-details"
]), Qe = /* @__PURE__ */ new Set([
  "href",
  "hrefLang",
  "target",
  "rel",
  "download",
  "ping",
  "referrerPolicy"
]), Ze = /^(data-.*)$/;
function At(e, t = {}) {
  let { labelable: r, isLink: o, propNames: a } = t, f = {};
  for (const u in e)
    Object.prototype.hasOwnProperty.call(e, u) && (qe.has(u) || r && Je.has(u) || o && Qe.has(u) || a != null && a.has(u) || Ze.test(u)) && (f[u] = e[u]);
  return f;
}
function D(e) {
  if (et())
    e.focus({
      preventScroll: !0
    });
  else {
    let t = tt(e);
    e.focus(), rt(t);
  }
}
let Q = null;
function et() {
  if (Q == null) {
    Q = !1;
    try {
      document.createElement("div").focus({
        get preventScroll() {
          return Q = !0, !0;
        }
      });
    } catch {
    }
  }
  return Q;
}
function tt(e) {
  let t = e.parentNode, r = [], o = document.scrollingElement || document.documentElement;
  for (; t instanceof HTMLElement && t !== o; )
    (t.offsetHeight < t.scrollHeight || t.offsetWidth < t.scrollWidth) && r.push({
      element: t,
      scrollTop: t.scrollTop,
      scrollLeft: t.scrollLeft
    }), t = t.parentNode;
  return o instanceof HTMLElement && r.push({
    element: o,
    scrollTop: o.scrollTop,
    scrollLeft: o.scrollLeft
  }), r;
}
function rt(e) {
  for (let { element: t, scrollTop: r, scrollLeft: o } of e)
    t.scrollTop = r, t.scrollLeft = o;
}
function ae(e) {
  var t;
  return typeof window > "u" || window.navigator == null ? !1 : ((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.brands.some((r) => e.test(r.brand))) || e.test(window.navigator.userAgent);
}
function $e(e) {
  var t;
  return typeof window < "u" && window.navigator != null ? e.test(((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.platform) || window.navigator.platform) : !1;
}
function C(e) {
  let t = null;
  return () => (t == null && (t = e()), t);
}
const V = C(function() {
  return $e(/^Mac/i);
}), nt = C(function() {
  return $e(/^iPhone/i);
}), ke = C(function() {
  return $e(/^iPad/i) || // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
  V() && navigator.maxTouchPoints > 1;
}), he = C(function() {
  return nt() || ke();
}), Dt = C(function() {
  return V() || he();
}), ot = C(function() {
  return ae(/AppleWebKit/i) && !it();
}), it = C(function() {
  return ae(/Chrome/i);
}), He = C(function() {
  return ae(/Android/i);
}), at = C(function() {
  return ae(/Firefox/i);
}), st = /* @__PURE__ */ Re({
  isNative: !0,
  open: ut,
  useHref: (e) => e
});
function Ce() {
  return xe(st);
}
function It(e, t) {
  let r = e.getAttribute("target");
  return (!r || r === "_self") && e.origin === location.origin && !e.hasAttribute("download") && !t.metaKey && // open in new tab (mac)
  !t.ctrlKey && // open in new tab (windows)
  !t.altKey && // download
  !t.shiftKey;
}
function X(e, t, r = !0) {
  var o, a;
  let { metaKey: f, ctrlKey: u, altKey: c, shiftKey: d } = t;
  at() && (!((a = window.event) === null || a === void 0 || (o = a.type) === null || o === void 0) && o.startsWith("key")) && e.target === "_blank" && (V() ? f = !0 : u = !0);
  let v = ot() && V() && !ke() ? new KeyboardEvent("keydown", {
    keyIdentifier: "Enter",
    metaKey: f,
    ctrlKey: u,
    altKey: c,
    shiftKey: d
  }) : new MouseEvent("click", {
    metaKey: f,
    ctrlKey: u,
    altKey: c,
    shiftKey: d,
    bubbles: !0,
    cancelable: !0
  });
  X.isOpening = r, D(e), e.dispatchEvent(v), X.isOpening = !1;
}
X.isOpening = !1;
function lt(e, t) {
  if (e instanceof HTMLAnchorElement)
    t(e);
  else if (e.hasAttribute("data-href")) {
    let r = document.createElement("a");
    r.href = e.getAttribute("data-href"), e.hasAttribute("data-target") && (r.target = e.getAttribute("data-target")), e.hasAttribute("data-rel") && (r.rel = e.getAttribute("data-rel")), e.hasAttribute("data-download") && (r.download = e.getAttribute("data-download")), e.hasAttribute("data-ping") && (r.ping = e.getAttribute("data-ping")), e.hasAttribute("data-referrer-policy") && (r.referrerPolicy = e.getAttribute("data-referrer-policy")), e.appendChild(r), t(r), e.removeChild(r);
  }
}
function ut(e, t) {
  lt(e, (r) => X(r, t));
}
function Wt(e) {
  let t = Ce();
  var r;
  const o = t.useHref((r = e.href) !== null && r !== void 0 ? r : "");
  return {
    "data-href": e.href ? o : void 0,
    "data-target": e.target,
    "data-rel": e.rel,
    "data-download": e.download,
    "data-ping": e.ping,
    "data-referrer-policy": e.referrerPolicy
  };
}
function Ut(e) {
  let t = Ce();
  var r;
  const o = t.useHref((r = e == null ? void 0 : e.href) !== null && r !== void 0 ? r : "");
  return {
    href: e != null && e.href ? o : void 0,
    target: e == null ? void 0 : e.target,
    rel: e == null ? void 0 : e.rel,
    download: e == null ? void 0 : e.download,
    ping: e == null ? void 0 : e.ping,
    referrerPolicy: e == null ? void 0 : e.referrerPolicy
  };
}
let G = /* @__PURE__ */ new Map(), de = /* @__PURE__ */ new Set();
function Te() {
  if (typeof window > "u")
    return;
  function e(o) {
    return "propertyName" in o;
  }
  let t = (o) => {
    if (!e(o) || !o.target)
      return;
    let a = G.get(o.target);
    a || (a = /* @__PURE__ */ new Set(), G.set(o.target, a), o.target.addEventListener("transitioncancel", r, {
      once: !0
    })), a.add(o.propertyName);
  }, r = (o) => {
    if (!e(o) || !o.target)
      return;
    let a = G.get(o.target);
    if (a && (a.delete(o.propertyName), a.size === 0 && (o.target.removeEventListener("transitioncancel", r), G.delete(o.target)), G.size === 0)) {
      for (let f of de)
        f();
      de.clear();
    }
  };
  document.body.addEventListener("transitionrun", t), document.body.addEventListener("transitionend", r);
}
typeof document < "u" && (document.readyState !== "loading" ? Te() : document.addEventListener("DOMContentLoaded", Te));
function Oe(e) {
  requestAnimationFrame(() => {
    G.size === 0 ? e() : de.add(e);
  });
}
function ct() {
  let e = R(/* @__PURE__ */ new Map()), t = x((a, f, u, c) => {
    let d = c != null && c.once ? (...v) => {
      e.current.delete(u), u(...v);
    } : u;
    e.current.set(u, {
      type: f,
      eventTarget: a,
      fn: d,
      options: c
    }), a.addEventListener(f, d, c);
  }, []), r = x((a, f, u, c) => {
    var d;
    let v = ((d = e.current.get(u)) === null || d === void 0 ? void 0 : d.fn) || u;
    a.removeEventListener(f, v, c), e.current.delete(u);
  }, []), o = x(() => {
    e.current.forEach((a, f) => {
      r(a.eventTarget, a.type, f, a.options);
    });
  }, [
    r
  ]);
  return Y(() => o, [
    o
  ]), {
    addGlobalListener: t,
    removeGlobalListener: r,
    removeAllGlobalListeners: o
  };
}
function ft(e, t) {
  Me(() => {
    if (e && e.ref && t)
      return e.ref.current = t.current, () => {
        e.ref && (e.ref.current = null);
      };
  });
}
function pe(e) {
  return e.mozInputSource === 0 && e.isTrusted ? !0 : He() && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function dt(e) {
  return !He() && e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType === "mouse";
}
let N = "default", ve = "", re = /* @__PURE__ */ new WeakMap();
function me(e) {
  if (he()) {
    if (N === "default") {
      const t = m(e);
      ve = t.documentElement.style.webkitUserSelect, t.documentElement.style.webkitUserSelect = "none";
    }
    N = "disabled";
  } else
    (e instanceof HTMLElement || e instanceof SVGElement) && (re.set(e, e.style.userSelect), e.style.userSelect = "none");
}
function Z(e) {
  if (he()) {
    if (N !== "disabled")
      return;
    N = "restoring", setTimeout(() => {
      Oe(() => {
        if (N === "restoring") {
          const t = m(e);
          t.documentElement.style.webkitUserSelect === "none" && (t.documentElement.style.webkitUserSelect = ve || ""), ve = "", N = "default";
        }
      });
    }, 300);
  } else if ((e instanceof HTMLElement || e instanceof SVGElement) && e && re.has(e)) {
    let t = re.get(e);
    e.style.userSelect === "none" && (e.style.userSelect = t), e.getAttribute("style") === "" && e.removeAttribute("style"), re.delete(e);
  }
}
const _e = Xe.createContext({
  register: () => {
  }
});
_e.displayName = "PressResponderContext";
function pt(e, t) {
  return t.get ? t.get.call(e) : t.value;
}
function Ae(e, t, r) {
  if (!t.has(e))
    throw new TypeError("attempted to " + r + " private field on non-instance");
  return t.get(e);
}
function vt(e, t) {
  var r = Ae(e, t, "get");
  return pt(e, r);
}
function gt(e, t) {
  if (t.has(e))
    throw new TypeError("Cannot initialize the same private elements twice on an object");
}
function bt(e, t, r) {
  gt(e, t), t.set(e, r);
}
function $t(e, t, r) {
  if (t.set)
    t.set.call(e, r);
  else {
    if (!t.writable)
      throw new TypeError("attempted to set read only private field");
    t.value = r;
  }
}
function Ee(e, t, r) {
  var o = Ae(e, t, "set");
  return $t(e, o, r), r;
}
function ht(e) {
  let t = xe(_e);
  if (t) {
    let { register: r, ...o } = t;
    e = Fe(o, e), r();
  }
  return ft(t, e.ref), e;
}
var ee = /* @__PURE__ */ new WeakMap();
class te {
  continuePropagation() {
    Ee(this, ee, !1);
  }
  get shouldStopPropagation() {
    return vt(this, ee);
  }
  constructor(t, r, o, a) {
    bt(this, ee, {
      writable: !0,
      value: void 0
    }), Ee(this, ee, !0);
    var f;
    let u = (f = a == null ? void 0 : a.target) !== null && f !== void 0 ? f : o.currentTarget;
    const c = u == null ? void 0 : u.getBoundingClientRect();
    let d, v = 0, h, w = null;
    o.clientX != null && o.clientY != null && (h = o.clientX, w = o.clientY), c && (h != null && w != null ? (d = h - c.left, v = w - c.top) : (d = c.width / 2, v = c.height / 2)), this.type = t, this.pointerType = r, this.target = o.currentTarget, this.shiftKey = o.shiftKey, this.metaKey = o.metaKey, this.ctrlKey = o.ctrlKey, this.altKey = o.altKey, this.x = d, this.y = v;
  }
}
const Pe = Symbol("linkClicked");
function Bt(e) {
  let {
    onPress: t,
    onPressChange: r,
    onPressStart: o,
    onPressEnd: a,
    onPressUp: f,
    isDisabled: u,
    isPressed: c,
    preventFocusOnPress: d,
    shouldCancelOnPointerExit: v,
    allowTextSelectionOnPress: h,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: w,
    ...L
  } = ht(e), [g, K] = z(!1), E = R({
    isPressed: !1,
    ignoreEmulatedMouseEvents: !1,
    ignoreClickAfterPress: !1,
    didFirePressStart: !1,
    isTriggeringEvent: !1,
    activePointerId: null,
    target: null,
    isOverTarget: !1,
    pointerType: null
  }), { addGlobalListener: _, removeAllGlobalListeners: W } = ct(), M = B((n, p) => {
    let P = E.current;
    if (u || P.didFirePressStart)
      return !1;
    let l = !0;
    if (P.isTriggeringEvent = !0, o) {
      let b = new te("pressstart", p, n);
      o(b), l = b.shouldStopPropagation;
    }
    return r && r(!0), P.isTriggeringEvent = !1, P.didFirePressStart = !0, K(!0), l;
  }), y = B((n, p, P = !0) => {
    let l = E.current;
    if (!l.didFirePressStart)
      return !1;
    l.ignoreClickAfterPress = !0, l.didFirePressStart = !1, l.isTriggeringEvent = !0;
    let b = !0;
    if (a) {
      let i = new te("pressend", p, n);
      a(i), b = i.shouldStopPropagation;
    }
    if (r && r(!1), K(!1), t && P && !u) {
      let i = new te("press", p, n);
      t(i), b && (b = i.shouldStopPropagation);
    }
    return l.isTriggeringEvent = !1, b;
  }), U = B((n, p) => {
    let P = E.current;
    if (u)
      return !1;
    if (f) {
      P.isTriggeringEvent = !0;
      let l = new te("pressup", p, n);
      return f(l), P.isTriggeringEvent = !1, l.shouldStopPropagation;
    }
    return !0;
  }), A = B((n) => {
    let p = E.current;
    p.isPressed && p.target && (p.isOverTarget && p.pointerType != null && y(k(p.target, n), p.pointerType, !1), p.isPressed = !1, p.isOverTarget = !1, p.activePointerId = null, p.pointerType = null, W(), h || Z(p.target));
  }), J = B((n) => {
    v && A(n);
  }), Ve = Ke(() => {
    let n = E.current, p = {
      onKeyDown(l) {
        if (le(l.nativeEvent, l.currentTarget) && l.currentTarget.contains(l.target)) {
          var b;
          Le(l.target, l.key) && l.preventDefault();
          let i = !0;
          if (!n.isPressed && !l.repeat) {
            n.target = l.currentTarget, n.isPressed = !0, i = M(l, "keyboard");
            let s = l.currentTarget, $ = (F) => {
              le(F, s) && !F.repeat && s.contains(F.target) && n.target && U(k(n.target, F), "keyboard");
            };
            _(m(l.currentTarget), "keyup", je($, P), !0);
          }
          i && l.stopPropagation(), l.metaKey && V() && ((b = n.metaKeyEvents) === null || b === void 0 || b.set(l.key, l.nativeEvent));
        } else
          l.key === "Meta" && (n.metaKeyEvents = /* @__PURE__ */ new Map());
      },
      onClick(l) {
        if (!(l && !l.currentTarget.contains(l.target)) && l && l.button === 0 && !n.isTriggeringEvent && !X.isOpening) {
          let b = !0;
          if (u && l.preventDefault(), !n.ignoreClickAfterPress && !n.ignoreEmulatedMouseEvents && !n.isPressed && (n.pointerType === "virtual" || pe(l.nativeEvent))) {
            !u && !d && D(l.currentTarget);
            let i = M(l, "virtual"), s = U(l, "virtual"), $ = y(l, "virtual");
            b = i && s && $;
          }
          n.ignoreEmulatedMouseEvents = !1, n.ignoreClickAfterPress = !1, b && l.stopPropagation();
        }
      }
    }, P = (l) => {
      var b;
      if (n.isPressed && n.target && le(l, n.target)) {
        var i;
        Le(l.target, l.key) && l.preventDefault();
        let $ = l.target;
        y(k(n.target, l), "keyboard", n.target.contains($)), W(), l.key !== "Enter" && ye(n.target) && n.target.contains($) && !l[Pe] && (l[Pe] = !0, X(n.target, l, !1)), n.isPressed = !1, (i = n.metaKeyEvents) === null || i === void 0 || i.delete(l.key);
      } else if (l.key === "Meta" && (!((b = n.metaKeyEvents) === null || b === void 0) && b.size)) {
        var s;
        let $ = n.metaKeyEvents;
        n.metaKeyEvents = void 0;
        for (let F of $.values())
          (s = n.target) === null || s === void 0 || s.dispatchEvent(new KeyboardEvent("keyup", F));
      }
    };
    if (typeof PointerEvent < "u") {
      p.onPointerDown = (s) => {
        if (s.button !== 0 || !s.currentTarget.contains(s.target))
          return;
        if (dt(s.nativeEvent)) {
          n.pointerType = "virtual";
          return;
        }
        ce(s.currentTarget) && s.preventDefault(), n.pointerType = s.pointerType;
        let $ = !0;
        if (!n.isPressed) {
          n.isPressed = !0, n.isOverTarget = !0, n.activePointerId = s.pointerId, n.target = s.currentTarget, !u && !d && D(s.currentTarget), h || me(n.target), $ = M(s, n.pointerType);
          let F = s.target;
          "releasePointerCapture" in F && F.releasePointerCapture(s.pointerId), _(m(s.currentTarget), "pointerup", l, !1), _(m(s.currentTarget), "pointercancel", i, !1);
        }
        $ && s.stopPropagation();
      }, p.onMouseDown = (s) => {
        s.currentTarget.contains(s.target) && s.button === 0 && (ce(s.currentTarget) && s.preventDefault(), s.stopPropagation());
      }, p.onPointerUp = (s) => {
        !s.currentTarget.contains(s.target) || n.pointerType === "virtual" || s.button === 0 && U(s, n.pointerType || s.pointerType);
      }, p.onPointerEnter = (s) => {
        s.pointerId === n.activePointerId && n.target && !n.isOverTarget && n.pointerType != null && (n.isOverTarget = !0, M(k(n.target, s), n.pointerType));
      }, p.onPointerLeave = (s) => {
        s.pointerId === n.activePointerId && n.target && n.isOverTarget && n.pointerType != null && (n.isOverTarget = !1, y(k(n.target, s), n.pointerType, !1), J(s));
      };
      let l = (s) => {
        s.pointerId === n.activePointerId && n.isPressed && s.button === 0 && n.target && (n.target.contains(s.target) && n.pointerType != null ? y(k(n.target, s), n.pointerType) : n.isOverTarget && n.pointerType != null && y(k(n.target, s), n.pointerType, !1), n.isPressed = !1, n.isOverTarget = !1, n.activePointerId = null, n.pointerType = null, W(), h || Z(n.target), "ontouchend" in n.target && s.pointerType !== "mouse" && _(n.target, "touchend", b, {
          once: !0
        }));
      }, b = (s) => {
        De(s.currentTarget) && s.preventDefault();
      }, i = (s) => {
        A(s);
      };
      p.onDragStart = (s) => {
        s.currentTarget.contains(s.target) && A(s);
      };
    } else {
      p.onMouseDown = (i) => {
        if (i.button !== 0 || !i.currentTarget.contains(i.target))
          return;
        if (ce(i.currentTarget) && i.preventDefault(), n.ignoreEmulatedMouseEvents) {
          i.stopPropagation();
          return;
        }
        n.isPressed = !0, n.isOverTarget = !0, n.target = i.currentTarget, n.pointerType = pe(i.nativeEvent) ? "virtual" : "mouse", !u && !d && D(i.currentTarget), M(i, n.pointerType) && i.stopPropagation(), _(m(i.currentTarget), "mouseup", l, !1);
      }, p.onMouseEnter = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = !0;
        n.isPressed && !n.ignoreEmulatedMouseEvents && n.pointerType != null && (n.isOverTarget = !0, s = M(i, n.pointerType)), s && i.stopPropagation();
      }, p.onMouseLeave = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = !0;
        n.isPressed && !n.ignoreEmulatedMouseEvents && n.pointerType != null && (n.isOverTarget = !1, s = y(i, n.pointerType, !1), J(i)), s && i.stopPropagation();
      }, p.onMouseUp = (i) => {
        i.currentTarget.contains(i.target) && !n.ignoreEmulatedMouseEvents && i.button === 0 && U(i, n.pointerType || "mouse");
      };
      let l = (i) => {
        if (i.button === 0) {
          if (n.isPressed = !1, W(), n.ignoreEmulatedMouseEvents) {
            n.ignoreEmulatedMouseEvents = !1;
            return;
          }
          n.target && ue(i, n.target) && n.pointerType != null ? y(k(n.target, i), n.pointerType) : n.target && n.isOverTarget && n.pointerType != null && y(k(n.target, i), n.pointerType, !1), n.isOverTarget = !1;
        }
      };
      p.onTouchStart = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let s = yt(i.nativeEvent);
        if (!s)
          return;
        n.activePointerId = s.identifier, n.ignoreEmulatedMouseEvents = !0, n.isOverTarget = !0, n.isPressed = !0, n.target = i.currentTarget, n.pointerType = "touch", !u && !d && D(i.currentTarget), h || me(n.target), M(O(n.target, i), n.pointerType) && i.stopPropagation(), _(S(i.currentTarget), "scroll", b, !0);
      }, p.onTouchMove = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!n.isPressed) {
          i.stopPropagation();
          return;
        }
        let s = we(i.nativeEvent, n.activePointerId), $ = !0;
        s && ue(s, i.currentTarget) ? !n.isOverTarget && n.pointerType != null && (n.isOverTarget = !0, $ = M(O(n.target, i), n.pointerType)) : n.isOverTarget && n.pointerType != null && (n.isOverTarget = !1, $ = y(O(n.target, i), n.pointerType, !1), J(O(n.target, i))), $ && i.stopPropagation();
      }, p.onTouchEnd = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!n.isPressed) {
          i.stopPropagation();
          return;
        }
        let s = we(i.nativeEvent, n.activePointerId), $ = !0;
        s && ue(s, i.currentTarget) && n.pointerType != null ? (U(O(n.target, i), n.pointerType), $ = y(O(n.target, i), n.pointerType)) : n.isOverTarget && n.pointerType != null && ($ = y(O(n.target, i), n.pointerType, !1)), $ && i.stopPropagation(), n.isPressed = !1, n.activePointerId = null, n.isOverTarget = !1, n.ignoreEmulatedMouseEvents = !0, n.target && !h && Z(n.target), W();
      }, p.onTouchCancel = (i) => {
        i.currentTarget.contains(i.target) && (i.stopPropagation(), n.isPressed && A(O(n.target, i)));
      };
      let b = (i) => {
        n.isPressed && i.target.contains(n.target) && A({
          currentTarget: n.target,
          shiftKey: !1,
          ctrlKey: !1,
          metaKey: !1,
          altKey: !1
        });
      };
      p.onDragStart = (i) => {
        i.currentTarget.contains(i.target) && A(i);
      };
    }
    return p;
  }, [
    _,
    u,
    d,
    W,
    h,
    A,
    J,
    y,
    M,
    U
  ]);
  return Y(() => () => {
    var n;
    h || Z((n = E.current.target) !== null && n !== void 0 ? n : void 0);
  }, [
    h
  ]), {
    isPressed: c || g,
    pressProps: Fe(L, Ve)
  };
}
function ye(e) {
  return e.tagName === "A" && e.hasAttribute("href");
}
function le(e, t) {
  const { key: r, code: o } = e, a = t, f = a.getAttribute("role");
  return (r === "Enter" || r === " " || r === "Spacebar" || o === "Space") && !(a instanceof S(a).HTMLInputElement && !Ie(a, r) || a instanceof S(a).HTMLTextAreaElement || a.isContentEditable) && // Links should only trigger with Enter key
  !((f === "link" || !f && ye(a)) && r !== "Enter");
}
function yt(e) {
  const { targetTouches: t } = e;
  return t.length > 0 ? t[0] : null;
}
function we(e, t) {
  const r = e.changedTouches;
  for (let o = 0; o < r.length; o++) {
    const a = r[o];
    if (a.identifier === t)
      return a;
  }
  return null;
}
function O(e, t) {
  let r = 0, o = 0;
  return t.targetTouches && t.targetTouches.length === 1 && (r = t.targetTouches[0].clientX, o = t.targetTouches[0].clientY), {
    currentTarget: e,
    shiftKey: t.shiftKey,
    ctrlKey: t.ctrlKey,
    metaKey: t.metaKey,
    altKey: t.altKey,
    clientX: r,
    clientY: o
  };
}
function k(e, t) {
  let r = t.clientX, o = t.clientY;
  return {
    currentTarget: e,
    shiftKey: t.shiftKey,
    ctrlKey: t.ctrlKey,
    metaKey: t.metaKey,
    altKey: t.altKey,
    clientX: r,
    clientY: o
  };
}
function Tt(e) {
  let t = 0, r = 0;
  return e.width !== void 0 ? t = e.width / 2 : e.radiusX !== void 0 && (t = e.radiusX), e.height !== void 0 ? r = e.height / 2 : e.radiusY !== void 0 && (r = e.radiusY), {
    top: e.clientY - r,
    right: e.clientX + t,
    bottom: e.clientY + r,
    left: e.clientX - t
  };
}
function mt(e, t) {
  return !(e.left > t.right || t.left > e.right || e.top > t.bottom || t.top > e.bottom);
}
function ue(e, t) {
  let r = t.getBoundingClientRect(), o = Tt(e);
  return mt(r, o);
}
function ce(e) {
  return !(e instanceof HTMLElement) || !e.hasAttribute("draggable");
}
function De(e) {
  return e instanceof HTMLInputElement ? !1 : e instanceof HTMLButtonElement ? e.type !== "submit" && e.type !== "reset" : !ye(e);
}
function Le(e, t) {
  return e instanceof HTMLInputElement ? !Ie(e, t) : De(e);
}
const Et = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function Ie(e, t) {
  return e.type === "checkbox" || e.type === "radio" ? t === " " : Et.has(e.type);
}
class Pt {
  isDefaultPrevented() {
    return this.nativeEvent.defaultPrevented;
  }
  preventDefault() {
    this.defaultPrevented = !0, this.nativeEvent.preventDefault();
  }
  stopPropagation() {
    this.nativeEvent.stopPropagation(), this.isPropagationStopped = () => !0;
  }
  isPropagationStopped() {
    return !1;
  }
  persist() {
  }
  constructor(t, r) {
    this.nativeEvent = r, this.target = r.target, this.currentTarget = r.currentTarget, this.relatedTarget = r.relatedTarget, this.bubbles = r.bubbles, this.cancelable = r.cancelable, this.defaultPrevented = r.defaultPrevented, this.eventPhase = r.eventPhase, this.isTrusted = r.isTrusted, this.timeStamp = r.timeStamp, this.type = t;
  }
}
function We(e) {
  let t = R({
    isFocused: !1,
    observer: null
  });
  Me(() => {
    const o = t.current;
    return () => {
      o.observer && (o.observer.disconnect(), o.observer = null);
    };
  }, []);
  let r = B((o) => {
    e == null || e(o);
  });
  return x((o) => {
    if (o.target instanceof HTMLButtonElement || o.target instanceof HTMLInputElement || o.target instanceof HTMLTextAreaElement || o.target instanceof HTMLSelectElement) {
      t.current.isFocused = !0;
      let a = o.target, f = (u) => {
        t.current.isFocused = !1, a.disabled && r(new Pt("blur", u)), t.current.observer && (t.current.observer.disconnect(), t.current.observer = null);
      };
      a.addEventListener("focusout", f, {
        once: !0
      }), t.current.observer = new MutationObserver(() => {
        if (t.current.isFocused && a.disabled) {
          var u;
          (u = t.current.observer) === null || u === void 0 || u.disconnect();
          let c = a === document.activeElement ? null : document.activeElement;
          a.dispatchEvent(new FocusEvent("blur", {
            relatedTarget: c
          })), a.dispatchEvent(new FocusEvent("focusout", {
            bubbles: !0,
            relatedTarget: c
          }));
        }
      }), t.current.observer.observe(a, {
        attributes: !0,
        attributeFilter: [
          "disabled"
        ]
      });
    }
  }, [
    r
  ]);
}
function wt(e) {
  let { isDisabled: t, onFocus: r, onBlur: o, onFocusChange: a } = e;
  const f = x((d) => {
    if (d.target === d.currentTarget)
      return o && o(d), a && a(!1), !0;
  }, [
    o,
    a
  ]), u = We(f), c = x((d) => {
    const v = m(d.target);
    d.target === d.currentTarget && v.activeElement === d.target && (r && r(d), a && a(!0), u(d));
  }, [
    a,
    r,
    u
  ]);
  return {
    focusProps: {
      onFocus: !t && (r || a || o) ? c : void 0,
      onBlur: !t && (o || a) ? f : void 0
    }
  };
}
let H = null, q = /* @__PURE__ */ new Set(), j = /* @__PURE__ */ new Map(), I = !1, ge = !1;
const Lt = {
  Tab: !0,
  Escape: !0
};
function se(e, t) {
  for (let r of q)
    r(e, t);
}
function St(e) {
  return !(e.metaKey || !V() && e.altKey || e.ctrlKey || e.key === "Control" || e.key === "Shift" || e.key === "Meta");
}
function ne(e) {
  I = !0, St(e) && (H = "keyboard", se("keyboard", e));
}
function T(e) {
  H = "pointer", (e.type === "mousedown" || e.type === "pointerdown") && (I = !0, se("pointer", e));
}
function Ue(e) {
  pe(e) && (I = !0, H = "virtual");
}
function Be(e) {
  e.target === window || e.target === document || (!I && !ge && (H = "virtual", se("virtual", e)), I = !1, ge = !1);
}
function Ge() {
  I = !1, ge = !0;
}
function oe(e) {
  if (typeof window > "u" || j.get(S(e)))
    return;
  const t = S(e), r = m(e);
  let o = t.HTMLElement.prototype.focus;
  t.HTMLElement.prototype.focus = function() {
    I = !0, o.apply(this, arguments);
  }, r.addEventListener("keydown", ne, !0), r.addEventListener("keyup", ne, !0), r.addEventListener("click", Ue, !0), t.addEventListener("focus", Be, !0), t.addEventListener("blur", Ge, !1), typeof PointerEvent < "u" ? (r.addEventListener("pointerdown", T, !0), r.addEventListener("pointermove", T, !0), r.addEventListener("pointerup", T, !0)) : (r.addEventListener("mousedown", T, !0), r.addEventListener("mousemove", T, !0), r.addEventListener("mouseup", T, !0)), t.addEventListener("beforeunload", () => {
    Ne(e);
  }, {
    once: !0
  }), j.set(t, {
    focus: o
  });
}
const Ne = (e, t) => {
  const r = S(e), o = m(e);
  t && o.removeEventListener("DOMContentLoaded", t), j.has(r) && (r.HTMLElement.prototype.focus = j.get(r).focus, o.removeEventListener("keydown", ne, !0), o.removeEventListener("keyup", ne, !0), o.removeEventListener("click", Ue, !0), r.removeEventListener("focus", Be, !0), r.removeEventListener("blur", Ge, !1), typeof PointerEvent < "u" ? (o.removeEventListener("pointerdown", T, !0), o.removeEventListener("pointermove", T, !0), o.removeEventListener("pointerup", T, !0)) : (o.removeEventListener("mousedown", T, !0), o.removeEventListener("mousemove", T, !0), o.removeEventListener("mouseup", T, !0)), j.delete(r));
};
function xt(e) {
  const t = m(e);
  let r;
  return t.readyState !== "loading" ? oe(e) : (r = () => {
    oe(e);
  }, t.addEventListener("DOMContentLoaded", r)), () => Ne(e, r);
}
typeof document < "u" && xt();
function Ye() {
  return H !== "pointer";
}
function Kt() {
  return H;
}
function Gt(e) {
  H = e, se(e, null);
}
function Nt() {
  oe();
  let [e, t] = z(H);
  return Y(() => {
    let r = () => {
      t(H);
    };
    return q.add(r), () => {
      q.delete(r);
    };
  }, []), ze() ? null : e;
}
const Mt = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function Ft(e, t, r) {
  var o;
  const a = typeof window < "u" ? S(r == null ? void 0 : r.target).HTMLInputElement : HTMLInputElement, f = typeof window < "u" ? S(r == null ? void 0 : r.target).HTMLTextAreaElement : HTMLTextAreaElement, u = typeof window < "u" ? S(r == null ? void 0 : r.target).HTMLElement : HTMLElement, c = typeof window < "u" ? S(r == null ? void 0 : r.target).KeyboardEvent : KeyboardEvent;
  return e = e || (r == null ? void 0 : r.target) instanceof a && !Mt.has(r == null || (o = r.target) === null || o === void 0 ? void 0 : o.type) || (r == null ? void 0 : r.target) instanceof f || (r == null ? void 0 : r.target) instanceof u && (r == null ? void 0 : r.target.isContentEditable), !(e && t === "keyboard" && r instanceof c && !Lt[r.key]);
}
function kt(e, t, r) {
  oe(), Y(() => {
    let o = (a, f) => {
      Ft(!!(r != null && r.isTextInput), a, f) && e(Ye());
    };
    return q.add(o), () => {
      q.delete(o);
    };
  }, t);
}
function Ht(e) {
  let { isDisabled: t, onBlurWithin: r, onFocusWithin: o, onFocusWithinChange: a } = e, f = R({
    isFocusWithin: !1
  }), u = x((v) => {
    f.current.isFocusWithin && !v.currentTarget.contains(v.relatedTarget) && (f.current.isFocusWithin = !1, r && r(v), a && a(!1));
  }, [
    r,
    a,
    f
  ]), c = We(u), d = x((v) => {
    !f.current.isFocusWithin && document.activeElement === v.target && (o && o(v), a && a(!0), f.current.isFocusWithin = !0, c(v));
  }, [
    o,
    a,
    c
  ]);
  return t ? {
    focusWithinProps: {
      // These should not have been null, that would conflict in mergeProps
      onFocus: void 0,
      onBlur: void 0
    }
  } : {
    focusWithinProps: {
      onFocus: d,
      onBlur: u
    }
  };
}
let ie = !1, fe = 0;
function be() {
  ie = !0, setTimeout(() => {
    ie = !1;
  }, 50);
}
function Se(e) {
  e.pointerType === "touch" && be();
}
function Ct() {
  if (!(typeof document > "u"))
    return typeof PointerEvent < "u" ? document.addEventListener("pointerup", Se) : document.addEventListener("touchend", be), fe++, () => {
      fe--, !(fe > 0) && (typeof PointerEvent < "u" ? document.removeEventListener("pointerup", Se) : document.removeEventListener("touchend", be));
    };
}
function Yt(e) {
  let { onHoverStart: t, onHoverChange: r, onHoverEnd: o, isDisabled: a } = e, [f, u] = z(!1), c = R({
    isHovered: !1,
    ignoreEmulatedMouseEvents: !1,
    pointerType: "",
    target: null
  }).current;
  Y(Ct, []);
  let { hoverProps: d, triggerHoverEnd: v } = Ke(() => {
    let h = (g, K) => {
      if (c.pointerType = K, a || K === "touch" || c.isHovered || !g.currentTarget.contains(g.target))
        return;
      c.isHovered = !0;
      let E = g.currentTarget;
      c.target = E, t && t({
        type: "hoverstart",
        target: E,
        pointerType: K
      }), r && r(!0), u(!0);
    }, w = (g, K) => {
      if (c.pointerType = "", c.target = null, K === "touch" || !c.isHovered)
        return;
      c.isHovered = !1;
      let E = g.currentTarget;
      o && o({
        type: "hoverend",
        target: E,
        pointerType: K
      }), r && r(!1), u(!1);
    }, L = {};
    return typeof PointerEvent < "u" ? (L.onPointerEnter = (g) => {
      ie && g.pointerType === "mouse" || h(g, g.pointerType);
    }, L.onPointerLeave = (g) => {
      !a && g.currentTarget.contains(g.target) && w(g, g.pointerType);
    }) : (L.onTouchStart = () => {
      c.ignoreEmulatedMouseEvents = !0;
    }, L.onMouseEnter = (g) => {
      !c.ignoreEmulatedMouseEvents && !ie && h(g, "mouse"), c.ignoreEmulatedMouseEvents = !1;
    }, L.onMouseLeave = (g) => {
      !a && g.currentTarget.contains(g.target) && w(g, "mouse");
    }), {
      hoverProps: L,
      triggerHoverEnd: w
    };
  }, [
    t,
    r,
    o,
    a,
    c
  ]);
  return Y(() => {
    a && v({
      currentTarget: c.target
    }, c.pointerType);
  }, [
    a
  ]), {
    hoverProps: d,
    isHovered: f
  };
}
function Vt(e) {
  const t = m(e);
  if (Kt() === "virtual") {
    let r = t.activeElement;
    Oe(() => {
      t.activeElement === r && e.isConnected && D(e);
    });
  } else
    D(e);
}
function Xt(e = {}) {
  let { autoFocus: t = !1, isTextInput: r, within: o } = e, a = R({
    isFocused: !1,
    isFocusVisible: t || Ye()
  }), [f, u] = z(!1), [c, d] = z(() => a.current.isFocused && a.current.isFocusVisible), v = x(() => d(a.current.isFocused && a.current.isFocusVisible), []), h = x((g) => {
    a.current.isFocused = g, u(g), v();
  }, [
    v
  ]);
  kt((g) => {
    a.current.isFocusVisible = g, v();
  }, [], {
    isTextInput: r
  });
  let { focusProps: w } = wt({
    isDisabled: o,
    onFocusChange: h
  }), { focusWithinProps: L } = Ht({
    isDisabled: !o,
    onFocusWithinChange: h
  });
  return {
    isFocused: f,
    isFocusVisible: c,
    focusProps: o ? L : w
  };
}
export {
  wt as $,
  he as A,
  Vt as a,
  ft as b,
  Bt as c,
  At as d,
  Ce as e,
  Ut as f,
  It as g,
  Yt as h,
  Xt as i,
  Ht as j,
  V as k,
  ot as l,
  Ye as m,
  ct as n,
  S as o,
  m as p,
  He as q,
  it as r,
  Kt as s,
  Dt as t,
  D as u,
  X as v,
  Nt as w,
  Wt as x,
  _e as y,
  Gt as z
};
