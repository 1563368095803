import { CheckCircleFilledIconXS } from '@carvertical/icons/react';
import { Tag, TagGroup } from '@carvertical/ui';
import type { Language } from '../../types';

type PreferredLanguageTagsProps = {
  languages: Language[];
  selectedSlug: string;
  onChange?: (slug: string) => void;
};

const PreferredLanguageTags = ({
  languages,
  selectedSlug,
  onChange,
}: PreferredLanguageTagsProps) => (
  <TagGroup
    selectedKeys={[selectedSlug]}
    onChange={(keys) => {
      const [firstKey] = Object.values(keys);
      const language = languages.find(({ slug }) => slug === firstKey);
      if (!language?.slug) {
        return;
      }

      onChange?.(language.slug);
    }}
    listClassName="gap-1"
  >
    {languages.map(({ slug, label }) => (
      <Tag
        key={slug}
        id={slug}
        leadingAddon={
          selectedSlug === slug ? <CheckCircleFilledIconXS className="text-white" /> : undefined
        }
      >
        <div className="flex items-center gap-1">{label}</div>
      </Tag>
    ))}
  </TagGroup>
);

export { PreferredLanguageTags };
export type { PreferredLanguageTagsProps };
