import { Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { SuggestCountryTag } from './SuggestCountryTag';
import { useSuggestCorrectMarket } from './hooks';
import { LOCALE_TO_LANGUAGE_MAP } from './hooks/useSuggestLocalMarkets';

const CLASS_NAMES = {
  flag: '!absolute -ml-2.5',
  countryTag: 'ml-2.5 gap-0.5',
};

const SuggestLocationDescription = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { localLanguageFound, localCountryCode, recommendedCountryCode, suggestedMarket } =
    useSuggestCorrectMarket();

  if (!suggestedMarket) {
    return null;
  }

  return (
    <Text variant="s">
      <Trans
        t={t}
        i18nKey={`suggestMarketModal.${localLanguageFound ? 'alternativeDescription' : 'description'}`}
        parent="span"
        components={{
          1: (
            <SuggestCountryTag
              language={
                localLanguageFound
                  ? suggestedMarket.countryCode
                  : LOCALE_TO_LANGUAGE_MAP[suggestedMarket.countryCode] ||
                    suggestedMarket.countryCode
              }
              countryCode={localLanguageFound ? localCountryCode : suggestedMarket.countryCode}
              flagClassName={CLASS_NAMES.flag}
              className={CLASS_NAMES.countryTag}
            />
          ),
          2: (
            <SuggestCountryTag
              language={LOCALE_TO_LANGUAGE_MAP[recommendedCountryCode] || recommendedCountryCode}
              countryCode={recommendedCountryCode}
              flagClassName={CLASS_NAMES.flag}
              className={CLASS_NAMES.countryTag}
            />
          ),
        }}
      />
    </Text>
  );
};

export { SuggestLocationDescription };
