import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as B } from "react";
import { c as l } from "./chunks/cn.fbf70112.js";
import { c as A, a as T } from "./chunks/cva.09768d84.js";
import { Icon as q } from "./icon.mjs";
import { v as z } from "./chunks/warnings.8f6035a3.js";
import { b as R, u as E, g as O, S as f, I as w } from "./chunks/animations.c777cd9c.js";
import { AnchorOrButton as C } from "./anchorOrButton.mjs";
import { m as o } from "./chunks/motion.bdfaf94a.js";
import { A as k } from "./chunks/index.1b20cf8f.js";
import { Spinner as W } from "./spinner.mjs";
import "./chunks/clsx.0d472180.js";
import "./chunks/styles.077839a8.js";
import "./chunks/useTranslatableUILabel.30fa9f56.js";
import "./chunks/TranslatableUIProvider.d50c2f21.js";
const x = {
  "2xl": "m",
  l: "m",
  m: "xs",
  s: "xs"
}, M = {
  "2xl": "m",
  l: "m",
  m: "s",
  s: "s"
}, L = A(
  R,
  T({
    base: "relative flex items-center justify-center overflow-hidden text-nowrap [&_svg]:shrink-0",
    variants: {
      size: {
        "2xl": "data-[has-leading-icon=true]:pl-4.5 data-[has-trailing-icon=true]:pr-4.5 gap-1 px-5 py-2 text-m+ [&_svg]:size-3",
        l: "gap-0.75 px-3 py-1.75 text-s+ data-[has-leading-icon=true]:pl-2 data-[has-trailing-icon=true]:pr-2 [&_svg]:size-2.5",
        m: "gap-0.75 px-2.5 py-1.25 text-xs+ !leading-5 data-[has-leading-icon=true]:pl-1.75 data-[has-trailing-icon=true]:pr-1.75 [&_svg]:size-2.5",
        s: "gap-0.5 px-1.75 py-1 text-xs+ data-[has-leading-icon=true]:pl-1.25 data-[has-trailing-icon=true]:pr-1.25 [&_svg]:size-2"
      },
      fullWidth: {
        true: "w-full"
      }
    }
  })
), y = B(
  ({
    variant: m = "yellow",
    size: a = "m",
    fullWidth: g,
    leadingIcon: i,
    trailingIcon: s,
    children: h,
    leadingIconClassName: p,
    trailingIconClassName: N,
    loading: t = !1,
    className: v,
    disabled: c,
    ...I
  }, _) => {
    const r = E(), b = x[a], V = c || t, d = (n, u) => {
      if (typeof n == "string")
        return /* @__PURE__ */ e.jsx(q, { className: u, name: n, size: b });
      const S = n;
      return z({
        iconName: n.displayName,
        componentSize: a,
        sizeMap: x,
        componentName: "Button"
      }), /* @__PURE__ */ e.jsx(S, { className: u });
    }, j = O(!!i);
    return /* @__PURE__ */ e.jsx(
      C,
      {
        unstyled: !0,
        ref: _,
        className: L({
          variant: m,
          size: a,
          fullWidth: g,
          disabled: c,
          loading: t,
          className: v
        }),
        disabled: V,
        "data-has-leading-icon": !!i || !!t,
        "data-has-trailing-icon": !!s,
        "aria-busy": t,
        "aria-live": t ? "polite" : "off",
        ...I,
        children: /* @__PURE__ */ e.jsxs(o.div, { className: "flex items-center gap-[inherit]", children: [
          /* @__PURE__ */ e.jsx(k, { mode: "popLayout", initial: !1, children: t ? /* @__PURE__ */ e.jsx(
            o.span,
            {
              variants: j,
              initial: "initial",
              animate: "visible",
              exit: r ? "initial" : "exit",
              transition: r ? { duration: 0 } : f,
              className: "inline-flex items-center justify-center",
              children: /* @__PURE__ */ e.jsx(
                W,
                {
                  variant: "currentColor",
                  size: M[a],
                  className: l(
                    "shrink-0",
                    p,
                    (a === "m" || a === "l") && "!size-2.5",
                    a === "s" && "!size-2",
                    a === "2xl" && "!size-3"
                  )
                }
              )
            },
            "spinner"
          ) : i && /* @__PURE__ */ e.jsx(
            o.span,
            {
              className: l("inline-flex", p),
              variants: w,
              initial: "initial",
              animate: "visible",
              exit: "exit",
              transition: r ? { duration: 0 } : f,
              children: d(i, "")
            },
            "leadingIcon"
          ) }),
          /* @__PURE__ */ e.jsx("span", { children: h }),
          s && /* @__PURE__ */ e.jsx("span", { className: l("inline-flex", N), children: d(s, "") })
        ] })
      }
    );
  }
);
try {
  y.displayName = "Button", y.__docgenInfo = { description: "", displayName: "Button", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } }, loading: { defaultValue: { value: !1 }, description: "", name: "loading", required: !1, type: { name: "boolean" } }, fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ButtonSize" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } } } };
} catch {
}
export {
  y as Button
};
