import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makePaymentMethodPrimary } from '../api';
import type { MakePrimaryPaymentMethodResponse } from '../types';
import { CARD_PAYMENT_METHODS_QUERY_KEY } from './usePaymentMethodsQuery';

const useMakePrimaryPaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  const { isSuccess, isPending, mutate, reset } = useMutation<
    MakePrimaryPaymentMethodResponse,
    unknown,
    string
  >({
    mutationFn: makePaymentMethodPrimary,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: CARD_PAYMENT_METHODS_QUERY_KEY.shared }),
  });

  return {
    makePrimaryPaymentMethod: mutate,
    makingPrimaryPaymentMethod: isPending,
    paymentMethodSetToPrimary: isSuccess,
    resetPrimary: reset,
  };
};

export { useMakePrimaryPaymentMethodMutation };
