import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as t } from "./chunks/cn.fbf70112.js";
import { $ as c } from "./chunks/Header.8e15f16d.js";
import { $ as l } from "./chunks/Collection.2f43ceb9.js";
import "react";
import "./chunks/utils.ec96ba96.js";
import "./chunks/clsx.0d472180.js";
import "./chunks/Hidden.dae830d2.js";
import "react-dom";
const a = ({
  children: e,
  borderShown: s = !1,
  title: r,
  className: i
}) => {
  const n = navigator.userAgent.toLowerCase().includes("firefox");
  return /* @__PURE__ */ o.jsxs(l, { className: t("scroll-mt-6", i), children: [
    /* @__PURE__ */ o.jsx(
      c,
      {
        className: t(
          "sticky -top-px z-[1] truncate text-grey-700 backdrop-blur-md",
          n && "bg-white",
          s && "border-b border-grey-200",
          !!r && "px-2 py-1.5 text-xs+"
        ),
        children: r
      }
    ),
    e
  ] });
};
try {
  a.displayName = "ComboBoxSection", a.__docgenInfo = { description: "", displayName: "ComboBoxSection", props: { title: { defaultValue: null, description: "", name: "title", required: !1, type: { name: "string" } }, borderShown: { defaultValue: { value: !1 }, description: "", name: "borderShown", required: !1, type: { name: "boolean" } }, className: { defaultValue: null, description: "", name: "className", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  a as ComboBoxSection
};
