import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as a } from "react";
import { Icon as s } from "./icon.mjs";
import { i } from "./chunks/styles.077839a8.js";
import { O as c } from "./chunks/OptionBase.5b234580.js";
import "./chunks/clsx.0d472180.js";
import "./text.mjs";
import "./chunks/cn.fbf70112.js";
import "./chunks/TypographyElement.69585222.js";
const t = a((e, r) => /* @__PURE__ */ o.jsx(
  c,
  {
    ref: r,
    type: "checkbox",
    selectionBoxClassName: i.selectionBox,
    selectionIndicator: /* @__PURE__ */ o.jsx(s, { name: "check-thick", size: "xs" }),
    ...e
  }
));
try {
  t.displayName = "Checkbox", t.__docgenInfo = { description: "", displayName: "Checkbox", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } } } };
} catch {
}
export {
  t as Checkbox
};
