import type { Flavor } from '../types';

const LANGUAGE: Record<Flavor['locale'], string> = {
  en: 'English',
  lt: 'Lietuvių',
  et: 'Eesti',
  lv: 'Latviešu',
  pl: 'Polski',
  ro: 'Română',
  hu: 'Magyar',
  fr: 'Français',
  uk: 'Українська',
  ru: 'Русский',
  sv: 'Svenska',
  nl: 'Nederlands',
  cs: 'Český',
  hr: 'Hrvatski',
  bg: 'Bŭlgarski',
  sk: 'Slovenský',
  sr: 'Srpski',
  fi: 'Suomi',
  es: 'Español',
  sl: 'Slovenski',
  de: 'Deutsch',
  it: 'Italiano',
  da: 'Dansk',
  pt: 'Português',
  el: 'Elliniká',
  ar: 'العربية',
  ua: 'Українська',
  ee: 'Eesti',
  se: 'Svenska',
  cz: 'Český',
  si: 'Slovenský',
  ch: 'Deutsch',
  rs: 'Srpski',
  gr: 'Elliniká',
  dk: 'Dansk',
  mx: 'Español',
};

export { LANGUAGE };
