import { getCountryName, type CountryCode } from '@carvertical/utils/country';
import { markets } from 'config-ts';
import type { MarketId } from '../../../types';
import { useMarketSwitcher } from '../../MarketSwitcher/hooks';
import type { SuggestedLocalMarket } from '../types';
import { useSuggestedMarket } from './useSuggestCorrectMarketStore';

type UseSuggestLocalMarketProps = {
  geoDataCountryCode: string;
};

const LOCAL_COUNTRY_CODES = ['ba', 'mc', 'md', 'me', 'mk', 'sm'];
const LOCAL_COUNTRY_CODE_OVERRIDES: Record<string, MarketId[]> = {
  ba: ['serbia', 'croatia', 'europe'],
  mc: ['france', 'italy', 'europe'],
  md: ['romania', 'europe'],
  me: ['serbia', 'croatia', 'europe'],
  mk: ['serbia', 'europe'],
  sm: ['italy', 'europe'],
};
const LOCALE_TO_LANGUAGE_MAP: Record<string, string> = {
  cz: 'cs',
  rs: 'sr-Latn',
  be: 'nl',
  dk: 'da',
  ee: 'et',
  eu: 'en',
  se: 'sv',
  si: 'sl',
  ua: 'uk',
  za: 'en-ZA',
  gr: 'en',
  us: 'en',
  gb: 'en-GB',
  ch: 'de',
  ae: 'en',
  mx: 'es',
  au: 'en-AU',
};

const useSuggestLocalMarkets = ({ geoDataCountryCode }: UseSuggestLocalMarketProps) => {
  const { recommendedMarkets, recommendedCountryCode, localCountryCode, localLanguageFound } =
    useRecommendedMarkets({ geoDataCountryCode });
  const { otherMarkets } = useOtherMarkets(recommendedMarkets);

  return {
    otherMarkets,
    recommendedMarkets,
    recommendedCountryCode,
    localCountryCode,
    localLanguageFound,
  };
};

function useRecommendedMarkets({ geoDataCountryCode }: UseSuggestLocalMarketProps) {
  const suggestedMarket = useSuggestedMarket();

  const localLanguageFound = LOCAL_COUNTRY_CODES.includes(geoDataCountryCode);
  const localCountryCode = localLanguageFound ? geoDataCountryCode : 'eu';

  const recommendedMarketId = LOCAL_COUNTRY_CODE_OVERRIDES[geoDataCountryCode]?.[0];
  const recommendedCountryCode = markets[recommendedMarketId]?.countryCode || 'eu';

  const recommendedMarketIds = localLanguageFound
    ? LOCAL_COUNTRY_CODE_OVERRIDES[geoDataCountryCode]
    : [suggestedMarket?.id || 'europe'];

  const recommendedMarkets = recommendedMarketIds.map((marketId) => {
    const { countryCode } = markets[marketId];
    return {
      countryCode,
      language: getLocalCountryCode(countryCode),
      name: getCountryName(
        countryCode.toUpperCase() as CountryCode,
        getLocalCountryCode(countryCode),
      ),
    };
  });

  return {
    recommendedMarkets,
    recommendedCountryCode,
    localCountryCode,
    localLanguageFound,
  };
}

function getLocalCountryCode(countryCode: string) {
  return LOCALE_TO_LANGUAGE_MAP[countryCode] ?? countryCode;
}

function useOtherMarkets(recommendedMarkets: SuggestedLocalMarket[]) {
  const { switcherMarkets } = useMarketSwitcher();

  const otherMarkets = switcherMarkets
    .filter(
      (switcherMarket) =>
        !recommendedMarkets.find((market) => market.countryCode === switcherMarket.countryCode),
    )
    .map((switcherMarket) => ({
      ...switcherMarket,
      language: getLocalCountryCode(switcherMarket.countryCode),
      name: getCountryName(
        switcherMarket.countryCode.toUpperCase() as CountryCode,
        getLocalCountryCode(switcherMarket.countryCode),
      ),
    }));

  return {
    otherMarkets,
  };
}

export { useSuggestLocalMarkets, LOCAL_COUNTRY_CODE_OVERRIDES, LOCALE_TO_LANGUAGE_MAP };
export type { UseSuggestLocalMarketProps };
