import { $ as t } from "./utils.ec96ba96.js";
import { a as r } from "./Collection.2f43ceb9.js";
import c, { createContext as $ } from "react";
const o = /* @__PURE__ */ $({}), n = /* @__PURE__ */ r("header", function(e, a) {
  return [e, a] = t(e, a, o), /* @__PURE__ */ c.createElement("header", {
    className: "react-aria-Header",
    ...e,
    ref: a
  }, e.children);
});
export {
  n as $,
  o as a
};
