import { b as fe, c as ge, d as q, g as he, a as be } from "./Collection.2f43ceb9.js";
import { f as k, c as N, d as J, j as ve, $ as pe, i as X, k as $e, e as L, g as le, l as ye } from "./utils.ec96ba96.js";
import d, { useMemo as V, createContext as S, useCallback as me, forwardRef as Y, useContext as v, useRef as W, useEffect as ie } from "react";
import { a as De } from "./Header.8e15f16d.js";
import { d as T, j as xe, k as Pe, l as Ke, h as oe, m as Q, f as we, i as Se } from "./useFocusRing.ff9f944e.js";
import { d as ae, f as Be, c as Z, g as Ie, h as Fe, e as Ce, b as Ne, i as Ve } from "./useListState.accd1c78.js";
let j = /* @__PURE__ */ new Map();
function re(o) {
  let { locale: e } = ae(), t = e + (o ? Object.entries(o).sort((i, a) => i[0] < a[0] ? -1 : 1).join() : "");
  if (j.has(t))
    return j.get(t);
  let l = new Intl.Collator(e, o);
  return j.set(t, l), l;
}
function Me(o) {
  let { id: e, label: t, "aria-labelledby": l, "aria-label": i, labelElementType: a = "label" } = o;
  e = k(e);
  let r = k(), n = {};
  t ? (l = l ? `${r} ${l}` : r, n = {
    id: r,
    htmlFor: a === "label" ? e : void 0
  }) : !l && !i && console.warn("If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility");
  let s = Be({
    id: e,
    "aria-label": i,
    "aria-labelledby": l
  });
  return {
    labelProps: n,
    fieldProps: s
  };
}
const G = /* @__PURE__ */ new WeakMap();
function _e(o) {
  return typeof o == "string" ? o.replace(/\s*/g, "") : "" + o;
}
function Ee(o, e) {
  let t = G.get(o);
  if (!t)
    throw new Error("Unknown list");
  return `${t.id}-option-${_e(e)}`;
}
class ee {
  getItemRect(e) {
    let t = this.ref.current;
    if (!t)
      return null;
    let l = e != null ? t.querySelector(`[data-key="${CSS.escape(e.toString())}"]`) : null;
    if (!l)
      return null;
    let i = t.getBoundingClientRect(), a = l.getBoundingClientRect();
    return {
      x: a.left - i.left + t.scrollLeft,
      y: a.top - i.top + t.scrollTop,
      width: a.width,
      height: a.height
    };
  }
  getContentSize() {
    let e = this.ref.current;
    var t, l;
    return {
      width: (t = e == null ? void 0 : e.scrollWidth) !== null && t !== void 0 ? t : 0,
      height: (l = e == null ? void 0 : e.scrollHeight) !== null && l !== void 0 ? l : 0
    };
  }
  getVisibleRect() {
    let e = this.ref.current;
    var t, l, i, a;
    return {
      x: (t = e == null ? void 0 : e.scrollLeft) !== null && t !== void 0 ? t : 0,
      y: (l = e == null ? void 0 : e.scrollTop) !== null && l !== void 0 ? l : 0,
      width: (i = e == null ? void 0 : e.offsetWidth) !== null && i !== void 0 ? i : 0,
      height: (a = e == null ? void 0 : e.offsetHeight) !== null && a !== void 0 ? a : 0
    };
  }
  constructor(e) {
    this.ref = e;
  }
}
class ne {
  isDisabled(e) {
    var t;
    return this.disabledBehavior === "all" && (((t = e.props) === null || t === void 0 ? void 0 : t.isDisabled) || this.disabledKeys.has(e.key));
  }
  findNextNonDisabled(e, t) {
    let l = e;
    for (; l != null; ) {
      let i = this.collection.getItem(l);
      if ((i == null ? void 0 : i.type) === "item" && !this.isDisabled(i))
        return l;
      l = t(l);
    }
    return null;
  }
  getNextKey(e) {
    let t = e;
    return t = this.collection.getKeyAfter(t), this.findNextNonDisabled(t, (l) => this.collection.getKeyAfter(l));
  }
  getPreviousKey(e) {
    let t = e;
    return t = this.collection.getKeyBefore(t), this.findNextNonDisabled(t, (l) => this.collection.getKeyBefore(l));
  }
  findKey(e, t, l) {
    let i = e, a = this.layoutDelegate.getItemRect(i);
    if (!a || i == null)
      return null;
    let r = a;
    do {
      if (i = t(i), i == null)
        break;
      a = this.layoutDelegate.getItemRect(i);
    } while (a && l(r, a) && i != null);
    return i;
  }
  isSameRow(e, t) {
    return e.y === t.y || e.x !== t.x;
  }
  isSameColumn(e, t) {
    return e.x === t.x || e.y !== t.y;
  }
  getKeyBelow(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (t) => this.getNextKey(t), this.isSameRow) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (t) => this.getPreviousKey(t), this.isSameRow) : this.getPreviousKey(e);
  }
  getNextColumn(e, t) {
    return t ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyRightOf(e) {
    let t = this.direction === "ltr" ? "getKeyRightOf" : "getKeyLeftOf";
    return this.layoutDelegate[t] ? (e = this.layoutDelegate[t](e), this.findNextNonDisabled(e, (l) => this.layoutDelegate[t](l))) : this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "rtl") : this.findKey(e, (l) => this.getNextColumn(l, this.direction === "rtl"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "rtl") : null;
  }
  getKeyLeftOf(e) {
    let t = this.direction === "ltr" ? "getKeyLeftOf" : "getKeyRightOf";
    return this.layoutDelegate[t] ? (e = this.layoutDelegate[t](e), this.findNextNonDisabled(e, (l) => this.layoutDelegate[t](l))) : this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "ltr") : this.findKey(e, (l) => this.getNextColumn(l, this.direction === "ltr"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "ltr") : null;
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    return this.findNextNonDisabled(e, (t) => this.collection.getKeyAfter(t));
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    return this.findNextNonDisabled(e, (t) => this.collection.getKeyBefore(t));
  }
  getKeyPageAbove(e) {
    let t = this.ref.current, l = this.layoutDelegate.getItemRect(e);
    if (!l)
      return null;
    if (t && !Z(t))
      return this.getFirstKey();
    let i = e;
    if (this.orientation === "horizontal") {
      let a = Math.max(0, l.x + l.width - this.layoutDelegate.getVisibleRect().width);
      for (; l && l.x > a && i != null; )
        i = this.getKeyAbove(i), l = i == null ? null : this.layoutDelegate.getItemRect(i);
    } else {
      let a = Math.max(0, l.y + l.height - this.layoutDelegate.getVisibleRect().height);
      for (; l && l.y > a && i != null; )
        i = this.getKeyAbove(i), l = i == null ? null : this.layoutDelegate.getItemRect(i);
    }
    return i ?? this.getFirstKey();
  }
  getKeyPageBelow(e) {
    let t = this.ref.current, l = this.layoutDelegate.getItemRect(e);
    if (!l)
      return null;
    if (t && !Z(t))
      return this.getLastKey();
    let i = e;
    if (this.orientation === "horizontal") {
      let a = Math.min(this.layoutDelegate.getContentSize().width, l.y - l.width + this.layoutDelegate.getVisibleRect().width);
      for (; l && l.x < a && i != null; )
        i = this.getKeyBelow(i), l = i == null ? null : this.layoutDelegate.getItemRect(i);
    } else {
      let a = Math.min(this.layoutDelegate.getContentSize().height, l.y - l.height + this.layoutDelegate.getVisibleRect().height);
      for (; l && l.y < a && i != null; )
        i = this.getKeyBelow(i), l = i == null ? null : this.layoutDelegate.getItemRect(i);
    }
    return i ?? this.getLastKey();
  }
  getKeyForSearch(e, t) {
    if (!this.collator)
      return null;
    let l = this.collection, i = t || this.getFirstKey();
    for (; i != null; ) {
      let a = l.getItem(i);
      if (!a)
        return null;
      let r = a.textValue.slice(0, e.length);
      if (a.textValue && this.collator.compare(r, e) === 0)
        return i;
      i = this.getNextKey(i);
    }
    return null;
  }
  constructor(...e) {
    if (e.length === 1) {
      let t = e[0];
      this.collection = t.collection, this.ref = t.ref, this.collator = t.collator, this.disabledKeys = t.disabledKeys || /* @__PURE__ */ new Set(), this.disabledBehavior = t.disabledBehavior || "all", this.orientation = t.orientation || "vertical", this.direction = t.direction, this.layout = t.layout || "stack", this.layoutDelegate = t.layoutDelegate || new ee(t.ref);
    } else
      this.collection = e[0], this.disabledKeys = e[1], this.ref = e[2], this.collator = e[3], this.layout = "stack", this.orientation = "vertical", this.disabledBehavior = "all", this.layoutDelegate = new ee(this.ref);
    this.layout === "stack" && this.orientation === "vertical" && (this.getKeyLeftOf = void 0, this.getKeyRightOf = void 0);
  }
}
function Re(o) {
  let { selectionManager: e, collection: t, disabledKeys: l, ref: i, keyboardDelegate: a, layoutDelegate: r } = o, n = re({
    usage: "search",
    sensitivity: "base"
  }), s = e.disabledBehavior, u = V(() => a || new ne({
    collection: t,
    disabledKeys: l,
    disabledBehavior: s,
    ref: i,
    collator: n,
    layoutDelegate: r
  }), [
    a,
    r,
    t,
    l,
    i,
    n,
    s
  ]), { collectionProps: f } = Ie({
    ...o,
    ref: i,
    selectionManager: e,
    keyboardDelegate: u
  });
  return {
    listProps: f
  };
}
function ke(o, e, t) {
  let l = T(o, {
    labelable: !0
  }), i = o.selectionBehavior || "toggle", a = o.linkBehavior || (i === "replace" ? "action" : "override");
  i === "toggle" && a === "action" && (a = "override");
  let { listProps: r } = Re({
    ...o,
    ref: t,
    selectionManager: e.selectionManager,
    collection: e.collection,
    disabledKeys: e.disabledKeys,
    linkBehavior: a
  }), { focusWithinProps: n } = xe({
    onFocusWithin: o.onFocus,
    onBlurWithin: o.onBlur,
    onFocusWithinChange: o.onFocusChange
  }), s = k(o.id);
  G.set(e, {
    id: s,
    shouldUseVirtualFocus: o.shouldUseVirtualFocus,
    shouldSelectOnPressUp: o.shouldSelectOnPressUp,
    shouldFocusOnHover: o.shouldFocusOnHover,
    isVirtualized: o.isVirtualized,
    onAction: o.onAction,
    linkBehavior: a
  });
  let { labelProps: u, fieldProps: f } = Me({
    ...o,
    id: s,
    // listbox is not an HTML input element so it
    // shouldn't be labeled by a <label> element.
    labelElementType: "span"
  });
  return {
    labelProps: u,
    listBoxProps: N(l, n, e.selectionManager.selectionMode === "multiple" ? {
      "aria-multiselectable": "true"
    } : {}, {
      role: "listbox",
      ...N(f, r)
    })
  };
}
const te = /* @__PURE__ */ new WeakMap();
function Le(o) {
  let e = te.get(o);
  if (e != null)
    return e;
  let t = 0, l = (i) => {
    for (let a of i)
      a.type === "section" ? l(Fe(a, o)) : t++;
  };
  return l(o), te.set(o, t), t;
}
function Te(o, e, t) {
  var l, i;
  let { key: a } = o, r = G.get(e);
  var n;
  let s = (n = o.isDisabled) !== null && n !== void 0 ? n : e.selectionManager.isDisabled(a);
  var u;
  let f = (u = o.isSelected) !== null && u !== void 0 ? u : e.selectionManager.isSelected(a);
  var p;
  let g = (p = o.shouldSelectOnPressUp) !== null && p !== void 0 ? p : r == null ? void 0 : r.shouldSelectOnPressUp;
  var $;
  let K = ($ = o.shouldFocusOnHover) !== null && $ !== void 0 ? $ : r == null ? void 0 : r.shouldFocusOnHover;
  var b;
  let h = (b = o.shouldUseVirtualFocus) !== null && b !== void 0 ? b : r == null ? void 0 : r.shouldUseVirtualFocus;
  var D;
  let x = (D = o.isVirtualized) !== null && D !== void 0 ? D : r == null ? void 0 : r.isVirtualized, B = J(), M = J(), P = {
    role: "option",
    "aria-disabled": s || void 0,
    "aria-selected": e.selectionManager.selectionMode !== "none" ? f : void 0
  };
  Pe() && Ke() || (P["aria-label"] = o["aria-label"], P["aria-labelledby"] = B, P["aria-describedby"] = M);
  let c = e.collection.getItem(a);
  if (x) {
    let m = Number(c == null ? void 0 : c.index);
    P["aria-posinset"] = Number.isNaN(m) ? void 0 : m + 1, P["aria-setsize"] = Le(e.collection);
  }
  let y = r != null && r.onAction ? () => {
    var m;
    return r == null || (m = r.onAction) === null || m === void 0 ? void 0 : m.call(r, a);
  } : void 0, w = Ee(e, a), { itemProps: I, isPressed: _, isFocused: F, hasAction: z, allowsSelection: E } = Ce({
    selectionManager: e.selectionManager,
    key: a,
    ref: t,
    shouldSelectOnPressUp: g,
    allowsDifferentPressOrigin: g && K,
    isVirtualized: x,
    shouldUseVirtualFocus: h,
    isDisabled: s,
    onAction: y || !(c == null || (l = c.props) === null || l === void 0) && l.onAction ? ve(c == null || (i = c.props) === null || i === void 0 ? void 0 : i.onAction, y) : void 0,
    linkBehavior: r == null ? void 0 : r.linkBehavior,
    id: w
  }), { hoverProps: R } = oe({
    isDisabled: s || !K,
    onHoverStart() {
      Q() || (e.selectionManager.setFocused(!0), e.selectionManager.setFocusedKey(a));
    }
  }), C = T(c == null ? void 0 : c.props);
  delete C.id;
  let A = we(c == null ? void 0 : c.props);
  return {
    optionProps: {
      ...P,
      ...N(C, I, R, A),
      id: w
    },
    labelProps: {
      id: B
    },
    descriptionProps: {
      id: M
    },
    isFocused: F,
    isFocusVisible: F && Q(),
    isSelected: f,
    isDisabled: s,
    isPressed: _,
    allowsSelection: E,
    hasAction: z
  };
}
function Oe(o) {
  let { heading: e, "aria-label": t } = o, l = k();
  return {
    itemProps: {
      role: "presentation"
    },
    headingProps: e ? {
      // Techincally, listbox cannot contain headings according to ARIA.
      // We hide the heading from assistive technology, using role="presentation",
      // and only use it as a visual label for the nested group.
      id: l,
      role: "presentation"
    } : {},
    groupProps: {
      role: "group",
      "aria-label": t,
      "aria-labelledby": e ? l : void 0
    }
  };
}
const He = /* @__PURE__ */ S({}), ze = /* @__PURE__ */ S(null), O = /* @__PURE__ */ S({}), se = /* @__PURE__ */ S(null), Ae = /* @__PURE__ */ Y(function(e, t) {
  let { render: l } = v(se);
  return /* @__PURE__ */ d.createElement(d.Fragment, null, l(e, t));
});
function de(o, e) {
  var t;
  let l = o == null ? void 0 : o.renderDropIndicator, i = o == null || (t = o.isVirtualDragging) === null || t === void 0 ? void 0 : t.call(o), a = me((r) => {
    if (i || e != null && e.isDropTarget(r))
      return l ? l(r) : /* @__PURE__ */ d.createElement(Ae, {
        target: r
      });
  }, [
    e == null ? void 0 : e.target,
    i,
    l
  ]);
  return o != null && o.useDropIndicator ? a : void 0;
}
function Ue(o, e, t) {
  var l, i;
  let a = o.focusedKey, r = null;
  if (!(e == null || (l = e.isVirtualDragging) === null || l === void 0) && l.call(e) && (t == null || (i = t.target) === null || i === void 0 ? void 0 : i.type) === "item") {
    r = t.target.key;
    var n;
    t.target.dropPosition === "after" && (r = (n = t.collection.getKeyAfter(r)) !== null && n !== void 0 ? n : r);
  }
  return V(() => new Set([
    a,
    r
  ].filter((s) => s != null)), [
    a,
    r
  ]);
}
const We = /* @__PURE__ */ S({}), ce = /* @__PURE__ */ S(null), H = /* @__PURE__ */ S(null), it = /* @__PURE__ */ Y(function(e, t) {
  [e, t] = pe(e, t, ce);
  let l = v(H);
  return l ? /* @__PURE__ */ d.createElement(ue, {
    state: l,
    props: e,
    listBoxRef: t
  }) : /* @__PURE__ */ d.createElement(fe, {
    content: /* @__PURE__ */ d.createElement(ge, e)
  }, (i) => /* @__PURE__ */ d.createElement(je, {
    props: e,
    listBoxRef: t,
    collection: i
  }));
});
function je({ props: o, listBoxRef: e, collection: t }) {
  o = {
    ...o,
    collection: t,
    children: null,
    items: null
  };
  let { layoutDelegate: l } = v(q), { filterFn: i, collectionProps: a, collectionRef: r } = v(ze) || {};
  e = X(V(() => $e(e, r !== void 0 ? r : null), [
    r,
    e
  ]));
  let n = V(() => i ? t.filter(i) : t, [
    t,
    i
  ]), s = Ne({
    ...o,
    collection: n,
    layoutDelegate: l
  });
  return /* @__PURE__ */ d.createElement(ue, {
    state: s,
    props: {
      ...o,
      ...a
    },
    listBoxRef: e
  });
}
function ue({ state: o, props: e, listBoxRef: t }) {
  let { dragAndDropHooks: l, layout: i = "stack", orientation: a = "vertical" } = e, { collection: r, selectionManager: n } = o, s = !!(l != null && l.useDraggableCollectionState), u = !!(l != null && l.useDroppableCollectionState), { direction: f } = ae(), { disabledBehavior: p, disabledKeys: g } = n, $ = re({
    usage: "search",
    sensitivity: "base"
  }), { isVirtualized: K, layoutDelegate: b, dropTargetDelegate: h, CollectionRoot: D } = v(q), x = V(() => e.keyboardDelegate || new ne({
    collection: r,
    collator: $,
    ref: t,
    disabledKeys: g,
    disabledBehavior: p,
    layout: i,
    orientation: a,
    direction: f,
    layoutDelegate: b
  }), [
    r,
    $,
    t,
    p,
    g,
    a,
    f,
    e.keyboardDelegate,
    i,
    b
  ]), { listBoxProps: B } = ke({
    ...e,
    shouldSelectOnPressUp: s || e.shouldSelectOnPressUp,
    keyboardDelegate: x,
    isVirtualized: K
  }, o, t), M = W(s), P = W(u);
  ie(() => {
    M.current !== s && console.warn("Drag hooks were provided during one render, but not another. This should be avoided as it may produce unexpected behavior."), P.current !== u && console.warn("Drop hooks were provided during one render, but not another. This should be avoided as it may produce unexpected behavior.");
  }, [
    s,
    u
  ]);
  let c, y, w, I = !1, _ = null, F = W(null);
  if (s && l) {
    c = l.useDraggableCollectionState({
      collection: r,
      selectionManager: n,
      preview: l.renderDragPreview ? F : void 0
    }), l.useDraggableCollection({}, c, t);
    let U = l.DragPreview;
    _ = l.renderDragPreview ? /* @__PURE__ */ d.createElement(U, {
      ref: F
    }, l.renderDragPreview) : null;
  }
  if (u && l) {
    y = l.useDroppableCollectionState({
      collection: r,
      selectionManager: n
    });
    let U = l.dropTargetDelegate || h || new l.ListDropTargetDelegate(r, t, {
      orientation: a,
      layout: i,
      direction: f
    });
    w = l.useDroppableCollection({
      keyboardDelegate: x,
      dropTargetDelegate: U
    }, y, t), I = y.isDropTarget({
      type: "root"
    });
  }
  let { focusProps: z, isFocused: E, isFocusVisible: R } = Se(), C = {
    isDropTarget: I,
    isEmpty: o.collection.size === 0,
    isFocused: E,
    isFocusVisible: R,
    layout: e.layout || "stack",
    state: o
  }, A = L({
    className: e.className,
    style: e.style,
    defaultClassName: "react-aria-ListBox",
    values: C
  }), m = null;
  return o.collection.size === 0 && e.renderEmptyState && (m = /* @__PURE__ */ d.createElement("div", {
    // eslint-disable-next-line
    role: "option",
    style: {
      display: "contents"
    }
  }, e.renderEmptyState(C))), /* @__PURE__ */ d.createElement(Ve, null, /* @__PURE__ */ d.createElement("div", {
    ...T(e),
    ...N(B, z, w == null ? void 0 : w.collectionProps),
    ...A,
    ref: t,
    slot: e.slot || void 0,
    onScroll: e.onScroll,
    "data-drop-target": I || void 0,
    "data-empty": o.collection.size === 0 || void 0,
    "data-focused": E || void 0,
    "data-focus-visible": R || void 0,
    "data-layout": e.layout || "stack",
    "data-orientation": e.orientation || "vertical"
  }, /* @__PURE__ */ d.createElement(le, {
    values: [
      [
        ce,
        e
      ],
      [
        H,
        o
      ],
      [
        O,
        {
          dragAndDropHooks: l,
          dragState: c,
          dropState: y
        }
      ],
      [
        We,
        {
          elementType: "div"
        }
      ],
      [
        se,
        {
          render: Xe
        }
      ],
      [
        he,
        {
          name: "ListBoxSection",
          render: qe
        }
      ]
    ]
  }, /* @__PURE__ */ d.createElement(D, {
    collection: r,
    scrollRef: t,
    persistedKeys: Ue(n, l, y),
    renderDropIndicator: de(l, y)
  })), m, _));
}
function qe(o, e, t, l = "react-aria-ListBoxSection") {
  let i = v(H), { dragAndDropHooks: a, dropState: r } = v(O), { CollectionBranch: n } = v(q), [s, u] = ye();
  var f;
  let { headingProps: p, groupProps: g } = Oe({
    heading: u,
    "aria-label": (f = o["aria-label"]) !== null && f !== void 0 ? f : void 0
  }), $ = L({
    defaultClassName: l,
    className: o.className,
    style: o.style,
    values: {}
  });
  return /* @__PURE__ */ d.createElement("section", {
    ...T(o),
    ...g,
    ...$,
    ref: e
  }, /* @__PURE__ */ d.createElement(De.Provider, {
    value: {
      ...p,
      ref: s
    }
  }, /* @__PURE__ */ d.createElement(n, {
    collection: i.collection,
    parent: t,
    renderDropIndicator: de(a, r)
  })));
}
const ot = /* @__PURE__ */ be("item", function(e, t, l) {
  let i = X(t), a = v(H), { dragAndDropHooks: r, dragState: n, dropState: s } = v(O), { optionProps: u, labelProps: f, descriptionProps: p, ...g } = Te({
    key: l.key,
    "aria-label": e == null ? void 0 : e["aria-label"]
  }, a, i), { hoverProps: $, isHovered: K } = oe({
    isDisabled: !g.allowsSelection && !g.hasAction,
    onHoverStart: l.props.onHoverStart,
    onHoverChange: l.props.onHoverChange,
    onHoverEnd: l.props.onHoverEnd
  }), b = null;
  n && r && (b = r.useDraggableItem({
    key: l.key
  }, n));
  let h = null;
  s && r && (h = r.useDroppableItem({
    target: {
      type: "item",
      key: l.key,
      dropPosition: "on"
    }
  }, s, i));
  let D = n && n.isDragging(l.key), x = L({
    ...e,
    id: void 0,
    children: e.children,
    defaultClassName: "react-aria-ListBoxItem",
    values: {
      ...g,
      isHovered: K,
      selectionMode: a.selectionManager.selectionMode,
      selectionBehavior: a.selectionManager.selectionBehavior,
      allowsDragging: !!n,
      isDragging: D,
      isDropTarget: h == null ? void 0 : h.isDropTarget
    }
  });
  ie(() => {
    l.textValue || console.warn("A `textValue` prop is required for <ListBoxItem> elements with non-plain text children in order to support accessibility features such as type to select.");
  }, [
    l.textValue
  ]);
  let B = e.href ? "a" : "div";
  return /* @__PURE__ */ d.createElement(B, {
    ...N(u, $, b == null ? void 0 : b.dragProps, h == null ? void 0 : h.dropProps),
    ...x,
    ref: i,
    "data-allows-dragging": !!n || void 0,
    "data-selected": g.isSelected || void 0,
    "data-disabled": g.isDisabled || void 0,
    "data-hovered": K || void 0,
    "data-focused": g.isFocused || void 0,
    "data-focus-visible": g.isFocusVisible || void 0,
    "data-pressed": g.isPressed || void 0,
    "data-dragging": D || void 0,
    "data-drop-target": (h == null ? void 0 : h.isDropTarget) || void 0,
    "data-selection-mode": a.selectionManager.selectionMode === "none" ? void 0 : a.selectionManager.selectionMode
  }, /* @__PURE__ */ d.createElement(le, {
    values: [
      [
        He,
        {
          slots: {
            label: f,
            description: p
          }
        }
      ]
    ]
  }, x.children));
});
function Xe(o, e) {
  e = X(e);
  let { dragAndDropHooks: t, dropState: l } = v(O), { dropIndicatorProps: i, isHidden: a, isDropTarget: r } = t.useDropIndicator(o, l, e);
  return a ? null : /* @__PURE__ */ d.createElement(Ge, {
    ...o,
    dropIndicatorProps: i,
    isDropTarget: r,
    ref: e
  });
}
function Ye(o, e) {
  let { dropIndicatorProps: t, isDropTarget: l, ...i } = o, a = L({
    ...i,
    defaultClassName: "react-aria-DropIndicator",
    values: {
      isDropTarget: l
    }
  });
  return /* @__PURE__ */ d.createElement("div", {
    ...t,
    ...a,
    // eslint-disable-next-line
    role: "option",
    ref: e,
    "data-drop-target": l || void 0
  });
}
const Ge = /* @__PURE__ */ Y(Ye);
export {
  ot as $,
  Me as a,
  Re as b,
  ne as c,
  H as d,
  He as e,
  re as f,
  G as g,
  Le as h,
  Ee as i,
  ce as j,
  it as k
};
