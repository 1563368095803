var i = /* @__PURE__ */ ((A) => (A.Hidden = "hidden", A.Visible = "visible", A))(i || {});
const s = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}, e = {
  hidden: { scale: 0 },
  visible: { scale: 1 }
}, a = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1 }
}, c = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1 }
};
export {
  i as A,
  s as F,
  a as M,
  e as S,
  c as a
};
