import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as h } from "react";
import { c as p } from "./chunks/cn.fbf70112.js";
import { c as B, a as S } from "./chunks/cva.09768d84.js";
import { Icon as A } from "./icon.mjs";
import { v as T } from "./chunks/warnings.8f6035a3.js";
import { b as z, u as O, g as q, I as R, S as u } from "./chunks/animations.c777cd9c.js";
import { m as r } from "./chunks/motion.bdfaf94a.js";
import { A as d } from "./chunks/index.1b20cf8f.js";
import { AnchorOrButton as g } from "./anchorOrButton.mjs";
import { Spinner as E } from "./spinner.mjs";
import "./chunks/clsx.0d472180.js";
import "./chunks/styles.077839a8.js";
import "./chunks/useTranslatableUILabel.30fa9f56.js";
import "./chunks/TranslatableUIProvider.d50c2f21.js";
const f = {
  xs: "xs",
  s: "m",
  m: "m",
  l: "m"
}, C = {
  xs: "s",
  s: "m",
  m: "m",
  l: "m"
}, w = B(
  z,
  S({
    base: 'relative before:absolute before:content-[""] before:min-w-[44px] before:min-h-[44px] overflow-hidden',
    variants: {
      size: {
        xs: "size-3",
        s: "size-4",
        m: "size-5",
        l: "size-6"
      }
    }
  })
), s = h(
  ({
    icon: n,
    label: o,
    "aria-label": y = o,
    "aria-labelledby": l,
    variant: x = "yellow",
    size: e = "m",
    iconClassName: t,
    className: N,
    title: I = o,
    disabled: m,
    loading: i = !1,
    ...b
  }, v) => {
    const c = O(), _ = q(!0), j = () => {
      if (typeof n == "string")
        return /* @__PURE__ */ a.jsx(
          A,
          {
            name: n,
            size: f[e],
            className: t
          }
        );
      const V = n;
      return T({
        componentName: s.displayName || "IconButton",
        iconName: n.displayName || "",
        componentSize: e,
        sizeMap: f
      }), /* @__PURE__ */ a.jsx(
        V,
        {
          className: p(
            "inline-flex",
            {
              "size-2": e === "xs",
              "size-3": e !== "xs"
            },
            t
          )
        }
      );
    };
    return /* @__PURE__ */ a.jsx(
      g,
      {
        unstyled: !0,
        className: w({ variant: x, size: e, disabled: m, loading: i, className: N }),
        ref: v,
        "aria-label": l ? void 0 : y,
        "aria-labelledby": l,
        title: I,
        disabled: m || i,
        "aria-busy": i,
        "aria-live": i ? "polite" : "off",
        ...b,
        children: /* @__PURE__ */ a.jsxs(r.div, { className: "flex items-center justify-center", children: [
          /* @__PURE__ */ a.jsx(d, { mode: "popLayout", initial: !1, children: !i && /* @__PURE__ */ a.jsx(
            r.span,
            {
              className: "inline-flex",
              variants: R,
              transition: u,
              initial: "initial",
              animate: i ? "initial" : "visible",
              exit: "initial",
              children: j()
            },
            "icon"
          ) }),
          /* @__PURE__ */ a.jsx(d, { mode: "popLayout", initial: !1, children: i && /* @__PURE__ */ a.jsx(
            r.span,
            {
              variants: _,
              initial: c ? "loading" : "initial",
              animate: "loading",
              exit: c ? void 0 : "initial",
              transition: u,
              "aria-label": "Loading",
              role: "status",
              className: "inline-flex items-center justify-center",
              children: /* @__PURE__ */ a.jsx(
                E,
                {
                  variant: "currentColor",
                  size: C[e],
                  className: p(
                    "shrink-0",
                    t,
                    (e === "m" || e === "s") && "!size-2.5",
                    e === "xs" && "!size-2",
                    e === "l" && "!size-3"
                  )
                }
              )
            },
            "spinner"
          ) })
        ] })
      }
    );
  }
);
try {
  s.displayName = "IconButton", s.__docgenInfo = { description: "", displayName: "IconButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconTypeWithLegacyFallback" } }, label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, loading: { defaultValue: { value: !1 }, description: "", name: "loading", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "IconButtonSize" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  s as IconButton
};
