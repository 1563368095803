import { mapValues } from 'lodash';
import type { Logo } from '../types';
import tiriacImage from '../assets/images/tiriac-auto.png';
import brcImage from '../assets/images/brc.png';
import polizinginiaiImage from '../assets/images/polizinginiai-auto.png';
import dealsOnWheelsImage from '../assets/images/deals-on-wheels.png';
import autojutaImage from '../assets/images/autojuta.png';
import haznaltImage from '../assets/images/haznalt-auto.png';
import vehoImage from '../assets/images/veho.png';
import spotAWheelImage from '../assets/images/spot-a-wheel.png';
import proCarImage from '../assets/images/procar.png';
import autoklassImage from '../assets/images/autoklass.png';
import driveramaImage from '../assets/images/driverama.png';
import rmbImage from '../assets/images/rmb.png';
import apanMotorsImage from '../assets/images/apan-motors.png';
import agrAutoImage from '../assets/images/agr-auto.png';
import carTecumImage from '../assets/images/car-tecum.png';
import kupAutoImage from '../assets/images/kup-auto.png';
import davoCarsImage from '../assets/images/davo-cars.png';
import autoSpotImage from '../assets/images/auto-spot.png';
import carTraxImage from '../assets/images/cartrax.png';
import buyByeCarImage from '../assets/images/buy-bye-car.png';
import billar99Image from '../assets/images/billar99.png';
import madisonOperationalImage from '../assets/images/madison-operational.png';
import sccImage from '../assets/images/scc-logo.png';
import hdiImage from '../assets/images/hdi.png';
import tomasiImage from '../assets/images/tomasi-auto.png';
import lautoImage from '../assets/images/lauto.png';
import rsRivieraImage from '../assets/images/rs-riviera.png';
import jeanLainHeritageImage from '../assets/images/jean-lain-heritage.png';
import bigBlockImportImage from '../assets/images/big-block-import.png';
import simplicicarImage from '../assets/images/simplicicar.png';
import jbmImage from '../assets/images/jbm.png';
import aaaAutoImage from '../assets/images/aaa-auto.png';
import importrustImage from '../assets/images/importrust.png';
import citadeleImage from '../assets/images/citadele.png';
import balciaImage from '../assets/images/balcia.png';
import automotoreImage from '../assets/images/automotore.png';
import autoZiebaImage from '../assets/images/auto-zieba.png';
import motoInwestImage from '../assets/images/moto-inwest.png';
import samochodyPlImage from '../assets/images/samochody-pl.png';
import rCarsImage from '../assets/images/r-cars.png';
import code911Image from '../assets/images/code-911.png';
import quattroruoteProfessionalImage from '../assets/images/quattroruote-professional.png';
import automontrealGroupImage from '../assets/images/automontreal-group.png';
import lomeCollectionImage from '../assets/images/lome-collection.png';
import excelCarImage from '../assets/images/excel-car.png';
import concessionariaFioriImage from '../assets/images/concessionaria-fiori.png';
import gruppoPiccirilloImage from '../assets/images/gruppo-piccirillo.png';
import transakautoImage from '../assets/images/transakauto.png';
import deliciaeImage from '../assets/images/deliciae.png';
import superAutoImage from '../assets/images/super-auto.png';
import autoHrvatskaImage from '../assets/images/auto-hrvatska.png';
import gasparicGrupaImage from '../assets/images/gasparic-grupa.png';
import autoCentarPorecImage from '../assets/images/auto-centar-porec.png';
import liMotorsImage from '../assets/images/li-motors.png';
import espritMoteurImage from '../assets/images/esprit-moteur.png';
import volvoFirmaKarlikImage from '../assets/images/volvo-firma-karlik.png';
import bravoAutoImage from '../assets/images/bravo-auto.png';
import raudasAutoImage from '../assets/images/raudas-auto.png';
import autoNationImage from '../assets/images/auto-nation.png';
import kamuxImage from '../assets/images/kamux.png';
import plusAutoImage from '../assets/images/plus-auto.png';
import deltaMotorsImage from '../assets/images/delta-motors.png';

const LOGOS: Record<string, Logo> = {
  veho: {
    id: 'veho',
    src: vehoImage,
    alt: 'Veho logo',
    width: 92,
    height: 26,
  },
  polizinginiai: {
    id: 'polizinginiai',
    src: polizinginiaiImage,
    alt: 'Polizinginiai automobiliai logo',
    width: 88,
    height: 58,
  },
  brc: {
    id: 'brc',
    src: brcImage,
    alt: 'BRC logo',
    width: 76,
    height: 38,
  },
  proCar: {
    id: 'proCar',
    src: proCarImage,
    alt: 'Pro Car logo',
    width: 160,
    height: 19,
  },
  autojuta: {
    id: 'autojuta',
    src: autojutaImage,
    alt: 'Autojuta logo',
    width: 121,
    height: 30,
  },
  dealsOnWheels: {
    id: 'dealsOnWheels',
    src: dealsOnWheelsImage,
    alt: 'Deals on wheels logo',
    width: 103,
    height: 30,
  },
  driverama: {
    id: 'driverama',
    src: driveramaImage,
    alt: 'Driverama logo',
    width: 138,
    height: 32,
  },
  autoklass: {
    id: 'autoklass',
    src: autoklassImage,
    alt: 'Autoklass logo',
    width: 130,
    height: 46,
  },
  apanMotors: {
    id: 'apanMotors',
    src: apanMotorsImage,
    alt: 'Apan Motors logo',
    width: 138,
    height: 17,
  },
  madisonOperational: {
    id: 'madisonOperational',
    src: madisonOperationalImage,
    alt: 'Madison Operational logo',
    width: 114,
    height: 41,
  },
  tiriac: {
    id: 'tiriac',
    src: tiriacImage,
    alt: 'Tiriac Auto logo',
    width: 122,
    height: 80,
  },
  spotAWheel: {
    id: 'spotAWheel',
    src: spotAWheelImage,
    alt: 'Spot a wheel logo',
    width: 141,
    height: 30,
  },
  haznalt: {
    id: 'haznalt',
    src: haznaltImage,
    alt: 'Haznalt Auto logo',
    width: 142,
    height: 20,
  },
  agrAuto: {
    id: 'agrAuto',
    src: agrAutoImage,
    alt: 'Agr Auto logo',
    width: 80,
    height: 32,
  },
  kupAuto: {
    id: 'kupAuto',
    src: kupAutoImage,
    alt: 'KupAuto24 logo',
    width: 71,
    height: 30,
  },
  carTecum: {
    id: 'carTecum',
    src: carTecumImage,
    alt: 'CarTecum logo',
    width: 210,
    height: 6,
  },
  davoCars: {
    id: 'davoCars',
    src: davoCarsImage,
    alt: 'Davo Cars logo',
    width: 113,
    height: 30,
  },
  autoSpot: {
    id: 'autoSpot',
    src: autoSpotImage,
    alt: 'Auto Spot logo',
    width: 120,
    height: 15,
  },
  buyByeCar: {
    id: 'buyByeCar',
    src: buyByeCarImage,
    alt: 'Buy Bye Car logo',
    width: 100,
    height: 65,
  },
  carTrax: {
    id: 'carTrax',
    src: carTraxImage,
    alt: 'CarTrax logo',
    width: 108,
    height: 22,
  },
  billar99: {
    id: 'billar99',
    src: billar99Image,
    alt: 'Billar99 logo',
    width: 70,
    height: 70,
  },
  hdi: {
    id: 'hdi',
    src: hdiImage,
    alt: 'HDI logo',
    width: 76,
    height: 45,
  },
  rmb: {
    id: 'rmb',
    src: rmbImage,
    alt: 'RMB logo',
    width: 150,
    height: 52,
  },
  scc: {
    id: 'scc',
    src: sccImage,
    alt: 'SCC logo',
    width: 160,
    height: 64,
  },
  tomasi: {
    id: 'tomasi',
    src: tomasiImage,
    alt: 'Tomasi Auto logo',
    width: 120,
    height: 30,
  },
  lauto: {
    id: 'lauto',
    src: lautoImage,
    alt: "L'Auto",
    width: 105,
    height: 106,
  },
  deliciae: {
    id: 'deliciae',
    src: deliciaeImage,
    alt: 'deliciae ludis motors logo',
    width: 123,
    height: 45,
  },
  rsRiviera: {
    id: 'rsRiviera',
    src: rsRivieraImage,
    alt: 'RS Riviera logo',
    width: 98,
    height: 74,
  },
  jeanLainHeritage: {
    id: 'jeanLainHeritage',
    src: jeanLainHeritageImage,
    alt: 'Jean Lain Heritage logo',
    width: 140,
    height: 31,
  },
  bigBlockImport: {
    id: 'bigBlockImport',
    src: bigBlockImportImage,
    alt: 'BigBlock Import logo',
    width: 134,
    height: 56,
  },
  simplicicar: {
    id: 'simplicicar',
    src: simplicicarImage,
    alt: 'Simplicicar logo',
    width: 81,
    height: 80,
  },
  jbm: {
    id: 'jbm',
    src: jbmImage,
    alt: 'JB Motors logo',
    width: 81,
    height: 72,
  },
  aaaAuto: {
    id: 'aaaAuto',
    src: aaaAutoImage,
    alt: ' AAA AUTO logo',
    width: 136,
    height: 28,
  },
  importrust: {
    id: 'importrust',
    src: importrustImage,
    alt: ' importrust logo',
    width: 136,
    height: 27,
  },
  citadele: {
    id: 'citadele',
    src: citadeleImage,
    alt: 'Citadele logo',
    width: 142,
    height: 71,
  },
  balcia: {
    id: 'balcia',
    src: balciaImage,
    alt: 'Balcia logo',
    width: 125,
    height: 57,
  },
  automotore: {
    id: 'automotore',
    src: automotoreImage,
    alt: ' Automotore logo',
    width: 124,
    height: 61,
  },
  autoZieba: {
    id: 'autoZieba',
    src: autoZiebaImage,
    alt: ' Auto Zieba logo',
    width: 80,
    height: 36,
  },
  motoInwest: {
    id: 'motoInwest',
    src: motoInwestImage,
    alt: ' Moto Inwest logo',
    width: 125,
    height: 31,
  },
  rCars: {
    id: 'rCars',
    src: rCarsImage,
    alt: ' R Cars logo',
    width: 91,
    height: 27,
  },
  samochodyPl: {
    id: 'samochodyPl',
    src: samochodyPlImage,
    alt: ' Samochody.pl logo',
    width: 139,
    height: 40,
  },
  code911: {
    id: 'code911',
    src: code911Image,
    alt: 'Code 911 - Sport & Prestige logo',
    width: 140,
    height: 57,
  },
  quattroruoteProfessional: {
    id: 'quattroruoteProfessional',
    src: quattroruoteProfessionalImage,
    alt: 'Quattroruote Professional logo',
    width: 136,
    height: 35,
  },
  automontrealGroup: {
    id: 'automontrealGroup',
    src: automontrealGroupImage,
    alt: 'Automontreal Group logo',
    width: 140,
    height: 46,
  },
  lomeCollection: {
    id: 'lomeCollection',
    src: lomeCollectionImage,
    alt: 'Lome Collection logo',
    width: 125,
    height: 30,
  },
  excelCar: {
    id: 'excelCar',
    src: excelCarImage,
    alt: 'Excel Car logo',
    width: 106,
    height: 27,
  },
  concessionariaFiori: {
    id: 'concessionariaFiori',
    src: concessionariaFioriImage,
    alt: 'Concessionaria Fiori logo',
    width: 165,
    height: 28,
  },
  gruppoPiccirillo: {
    id: 'gruppoPiccirillo',
    src: gruppoPiccirilloImage,
    alt: 'Gruppo Piccirillo logo',
    width: 83,
    height: 49,
  },
  transakauto: {
    id: 'transakauto',
    src: transakautoImage,
    alt: 'Transakauto logo',
    width: 135,
    height: 40,
  },
  superAuto: {
    id: 'superAuto',
    src: superAutoImage,
    alt: 'Super Auto logo',
    width: 115,
    height: 46,
  },
  autoHrvatska: {
    id: 'autoHrvatska',
    src: autoHrvatskaImage,
    alt: 'Auto Hrvatska logo',
    width: 148,
    height: 26,
  },
  gasparicGrupa: {
    id: 'gasparicGrupa',
    src: gasparicGrupaImage,
    alt: 'Gasparic Grupa logo',
    width: 60,
    height: 64,
  },
  autoCentarPorec: {
    id: 'autoCentarPorec',
    src: autoCentarPorecImage,
    alt: 'Auto Centar Porec logo',
    width: 138,
    height: 22,
  },
  liMotors: {
    id: 'liMotors',
    src: liMotorsImage,
    alt: 'Li Motors logo',
    width: 102,
    height: 21,
  },
  espritMoteur: {
    id: 'espritMoteur',
    src: espritMoteurImage,
    alt: 'Esprit Moteur logo',
    width: 120,
    height: 35,
  },
  volvoFirmaKarlik: {
    id: 'volvoFirmaKarlik',
    src: volvoFirmaKarlikImage,
    alt: 'Volvo Firma Karlik logo',
    width: 311,
    height: 21,
  },
  bravoAuto: {
    id: 'bravoAuto',
    src: bravoAutoImage,
    alt: 'Bravo Auto logo',
    width: 118,
    height: 20,
  },
  raudasAuto: {
    id: 'raudasAuto',
    src: raudasAutoImage,
    alt: 'Raudas Auto logo',
    width: 59,
    height: 52,
  },
  autoNation: {
    id: 'autoNation',
    src: autoNationImage,
    alt: 'Auto Nation logo',
    width: 128,
    height: 39,
  },
  kamux: {
    id: 'kamux',
    src: kamuxImage,
    alt: 'Kamux logo',
    width: 124,
    height: 31,
  },
  plusAuto: {
    id: 'plusAuto',
    src: plusAutoImage,
    alt: 'Plus Auto logo',
    width: 136,
    height: 22,
  },
  deltaMotors: {
    id: 'deltaMotors',
    src: deltaMotorsImage,
    alt: 'Delta Motors logo',
    width: 102,
    height: 45,
  },
};

const DEFAULT_LOGOS = [
  LOGOS.davoCars,
  LOGOS.veho,
  LOGOS.kupAuto,
  LOGOS.driverama,
  LOGOS.spotAWheel,
  LOGOS.tiriac,
];

const PARTNER_LOGOS: Record<string, Logo[]> = {
  default: DEFAULT_LOGOS,
  lithuania: [
    LOGOS.proCar,
    LOGOS.brc,
    LOGOS.dealsOnWheels,
    LOGOS.veho,
    LOGOS.autojuta,
    LOGOS.polizinginiai,
  ],
  romania: [
    LOGOS.plusAuto,
    LOGOS.spotAWheel,
    LOGOS.apanMotors,
    LOGOS.rmb,
    LOGOS.madisonOperational,
    LOGOS.autoklass,
    LOGOS.tiriac,
  ],
  hungary: [
    LOGOS.carTecum,
    LOGOS.driverama,
    LOGOS.agrAuto,
    LOGOS.haznalt,
    LOGOS.veho,
    LOGOS.tiriac,
  ],
  poland: [
    LOGOS.aaaAuto,
    LOGOS.volvoFirmaKarlik,
    LOGOS.automotore,
    LOGOS.autoZieba,
    LOGOS.rCars,
    LOGOS.samochodyPl,
    LOGOS.autoSpot,
    LOGOS.motoInwest,
  ],
  france: [
    LOGOS.simplicicar,
    LOGOS.excelCar,
    LOGOS.jeanLainHeritage,
    LOGOS.transakauto,
    LOGOS.rsRiviera,
    LOGOS.liMotors,
    LOGOS.espritMoteur,
    LOGOS.lomeCollection,
    LOGOS.deliciae,
    LOGOS.jbm,
    LOGOS.code911,
    LOGOS.bigBlockImport,
    LOGOS.buyByeCar,
    LOGOS.davoCars,
    LOGOS.veho,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  belgium: [
    LOGOS.simplicicar,
    LOGOS.excelCar,
    LOGOS.jeanLainHeritage,
    LOGOS.transakauto,
    LOGOS.rsRiviera,
    LOGOS.liMotors,
    LOGOS.espritMoteur,
    LOGOS.lomeCollection,
    LOGOS.deliciae,
    LOGOS.jbm,
    LOGOS.code911,
    LOGOS.bigBlockImport,
    LOGOS.buyByeCar,
    LOGOS.davoCars,
    LOGOS.veho,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  germany: [
    LOGOS.kupAuto,
    LOGOS.veho,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  czechia: [
    LOGOS.kupAuto,
    LOGOS.veho,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.carTrax,
    LOGOS.tiriac,
  ],
  latvia: [LOGOS.veho, LOGOS.bravoAuto, LOGOS.brc, LOGOS.raudasAuto, LOGOS.billar99, LOGOS.tiriac],
  estonia: [LOGOS.billar99, LOGOS.veho, LOGOS.brc, LOGOS.scc, LOGOS.davoCars, LOGOS.driverama],
  italy: [
    LOGOS.tomasi,
    LOGOS.lauto,
    LOGOS.automontrealGroup,
    LOGOS.concessionariaFiori,
    LOGOS.gruppoPiccirillo,
    LOGOS.quattroruoteProfessional,
    LOGOS.hdi,
  ],
  finland: [
    LOGOS.kamux,
    LOGOS.billar99,
    LOGOS.veho,
    LOGOS.scc,
    LOGOS.davoCars,
    LOGOS.driverama,
    LOGOS.spotAWheel,
  ],
  sweden: [LOGOS.scc, LOGOS.spotAWheel, LOGOS.driverama, LOGOS.kupAuto, LOGOS.veho, LOGOS.davoCars],
  portugal: [LOGOS.importrust, ...DEFAULT_LOGOS],
  croatia: [
    LOGOS.superAuto,
    LOGOS.autoHrvatska,
    LOGOS.gasparicGrupa,
    LOGOS.autoCentarPorec,
    LOGOS.spotAWheel,
    LOGOS.tiriac,
  ],
  ukraine: [LOGOS.autoNation, ...DEFAULT_LOGOS],
  serbia: [LOGOS.deltaMotors, LOGOS.autoNation, ...DEFAULT_LOGOS],
};

const INSURANCE_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  LOGOS.citadele,
  LOGOS.balcia,
  ...logos,
]);

const LEASING_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  LOGOS.citadele,
  LOGOS.balcia,
  ...logos,
]);

const CUSTOM_PARTNER_LOGOS: Record<string, Logo[]> = mapValues(PARTNER_LOGOS, (logos) => [
  ...logos.slice(0, 4),
  LOGOS.veho,
  ...logos.slice(4),
]);

export {
  LOGOS,
  PARTNER_LOGOS,
  INSURANCE_PARTNER_LOGOS,
  LEASING_PARTNER_LOGOS,
  CUSTOM_PARTNER_LOGOS,
};
