import { create } from 'zustand';
import type { Market } from 'modules/market';

type SuggestCorrectMarketType = 'checkout' | 'location';

type SuggestCorrectMarketStore = {
  forced: boolean;
  checkoutUrl?: string;
  selectedMarket?: Market;
  suggestedMarket?: Market;
  modalType: SuggestCorrectMarketType;
  actions: {
    initCheckoutMarketSuggestion: ({
      checkoutUrl,
      forced,
    }: {
      checkoutUrl: string;
      forced?: boolean;
    }) => void;
    selectMarket: (market: Market) => void;
    suggestMarket: (market: Market) => void;
  };
};

const useSuggestCorrectMarketStore = create<SuggestCorrectMarketStore>()((set) => ({
  forced: false,
  modalType: 'location',
  actions: {
    selectMarket: (market) => set({ selectedMarket: market }),
    suggestMarket: (market) => set({ suggestedMarket: market }),
    initCheckoutMarketSuggestion: ({ checkoutUrl, forced }) =>
      set({ modalType: 'checkout', checkoutUrl, forced }),
  },
}));

const useForced = () => useSuggestCorrectMarketStore((state) => state.forced);
const useModalType = () => useSuggestCorrectMarketStore((state) => state.modalType);
const useCheckoutUrl = () => useSuggestCorrectMarketStore((state) => state.checkoutUrl);
const useSelectedMarket = () => useSuggestCorrectMarketStore((state) => state.selectedMarket);
const useSuggestedMarket = () => useSuggestCorrectMarketStore((state) => state.suggestedMarket);
const useSuggestCorrectMarketActions = () => useSuggestCorrectMarketStore((state) => state.actions);

export {
  useForced,
  useModalType,
  useCheckoutUrl,
  useSelectedMarket,
  useSuggestedMarket,
  useSuggestCorrectMarketStore,
  useSuggestCorrectMarketActions,
};
