import { useEffect } from 'react';
import { find } from 'lodash';
import { useRouteData } from 'context/RouteDataProvider';
import { markets } from 'config-ts';
import type { Market, OptionalMarketIdRecord } from 'modules/market';
import { useGeoData, useModal } from 'modules/shared';
import { useMarketSwitcher } from '../../MarketSwitcher/hooks';
import { storeMarketPreference } from '../../../utils';
import { useSuggestCorrectMarketActions, useSuggestedMarket } from './useSuggestCorrectMarketStore';
import { useSuggestLocalMarkets, LOCAL_COUNTRY_CODE_OVERRIDES } from './useSuggestLocalMarkets';

const SUGGESTED_MARKET_OVERRIDES: OptionalMarketIdRecord<Market> = {
  germany: markets.switzerland,
};

const useSuggestCorrectMarket = () => {
  const { market } = useRouteData();
  const { setLanguageModalType } = useMarketSwitcher();
  const {
    suggestMarket,
    selectMarket: selectMarketAction,
    initCheckoutMarketSuggestion,
  } = useSuggestCorrectMarketActions();
  const { open: openSuggestCheckoutModal } = useModal('suggestCheckout');
  const {
    open: openSuggestLocationModal,
    close: closeSuggestLocationModal,
    isModalOpen: suggestLocationModalOpen,
  } = useModal('suggestLocation');
  const geoData = useGeoData();
  const suggestedMarket = useSuggestedMarket();
  const {
    otherMarkets,
    localCountryCode,
    localLanguageFound,
    recommendedMarkets,
    recommendedCountryCode,
  } = useSuggestLocalMarkets({ geoDataCountryCode: geoData?.countryCode });

  const suggestOnCheckout = (checkoutUrl: string, forced?: boolean) => {
    openSuggestCheckoutModal();
    setLanguageModalType('checkout');
    initCheckoutMarketSuggestion({ checkoutUrl, forced });
  };

  const selectMarket = (selectedMarket: Market) => selectMarketAction(selectedMarket);

  const confirmMarketSelection = () => {
    if (!suggestedMarket) {
      return;
    }

    selectMarket(suggestedMarket);
    storeMarketPreference();
    closeSuggestLocationModal();
  };

  useEffect(() => {
    if (!geoData) {
      return;
    }

    const { countryCode, timezone } = geoData;
    const homeMarket = countryCode === market.countryCode;

    if (homeMarket) {
      // This might happen when market changes after login
      if (suggestLocationModalOpen) {
        closeSuggestLocationModal();
      }
      return;
    }

    let marketSuggestion = find(
      markets,
      (singleMarket?: Market) =>
        !!singleMarket && !singleMarket.hidden && singleMarket.countryCode === countryCode,
    );

    if (!marketSuggestion) {
      const continent = (timezone || '').split('/')[0];

      if (
        continent === 'Europe' &&
        market.countryCode !== markets.europe.countryCode &&
        !LOCAL_COUNTRY_CODE_OVERRIDES[geoData?.countryCode]?.includes(market.id)
      ) {
        marketSuggestion =
          markets[LOCAL_COUNTRY_CODE_OVERRIDES[geoData?.countryCode]?.[0]] || markets.europe;
      } else if (continent === 'America' && market.countryCode !== markets.usa.countryCode) {
        marketSuggestion = markets.usa;
      } else {
        return;
      }
    }

    const overriddenMarket = SUGGESTED_MARKET_OVERRIDES[marketSuggestion?.id];
    if (overriddenMarket?.id === market.id) {
      return;
    }

    suggestMarket(marketSuggestion);
    openSuggestLocationModal();
  }, [
    geoData,
    market.countryCode,
    market.id,
    suggestMarket,
    openSuggestLocationModal,
    recommendedCountryCode,
    closeSuggestLocationModal,
    suggestLocationModalOpen,
  ]);

  return {
    market,
    otherMarkets,
    recommendedMarkets,
    recommendedCountryCode,
    selectMarket,
    suggestedMarket,
    localCountryCode,
    localLanguageFound,
    confirmMarketSelection,
    suggestOnCheckout,
  };
};

export { useSuggestCorrectMarket };
