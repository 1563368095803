import { app, studentBeansUrl } from 'config';
import { BLOG_ROOT_PATH } from 'modules/content/constants';
import { LOCALES } from 'config-ts';
import { FEATURE_ENABLED_LOCALES } from 'constants/features';
import type { Group, MainLinkGroup } from './types';

const DECODERS_GROUP: Group[] = [
  {
    id: 'decoders',
    labelKey: 'footer.decodersTitle',
    // Remove locale later, when SEO ready so all markets will show decoders
    locale: ['en', 'en-EU', 'en-GB', 'pl', 'ro', 'pt', 'hu'],
    links: [
      { pageId: 'audiDecoder' },
      { pageId: 'chevroletDecoder' },
      { pageId: 'dodgeDecoder' },
      { pageId: 'fordDecoder' },
      { pageId: 'jeepDecoder' },
      { pageId: 'lexusDecoder' },
      { pageId: 'mercedesDecoder' },
      { pageId: 'nissanDecoder' },
      { pageId: 'ramDecoder' },
      { pageId: 'toyotaDecoder' },
      { pageId: 'vwDecoder' },
    ],
  },
];

const MAIN_LINK_GROUPS: MainLinkGroup[] = [
  {
    id: 'reports',
    group: [
      {
        id: 'reports',
        labelKey: 'menu.reportsTitle',
        links: [
          { pageId: 'sampleReport' },
          { pageId: 'pricing' },
          {
            labelKey: 'menu.businessPricingTitle',
            pageId: 'businessPricing',
            page: { path: `/business#pricing` },
          },
          {
            labelKey: 'menu.advantagesTitle',
            pageId: 'advantages',
            page: {
              path: '/advantages',
            },
          },
          {
            labelKey: 'menu.featuresTitle',
            pageId: 'features',
            locale: FEATURE_ENABLED_LOCALES,
            page: {
              path: '/features',
            },
          },
          { pageId: 'vinDecoding' },
          {
            labelKey: 'menu.motorcycleDecoderTitle',
            pageId: 'motorcycleDecoder',
          },
        ],
      },
    ],
  },
  {
    id: 'business',
    group: [
      {
        id: 'business',
        labelKey: 'menu.businessTitle',
        links: [
          {
            labelKey: 'menu.dealershipsTitle',
            pageId: 'business',
          },
          {
            pageId: 'businessInsurance',
            labelKey: 'menu.businessInsuranceTitle',
            page: {
              path: '/business/insurance',
            },
          },
          {
            pageId: 'businessLeasing',
            labelKey: 'menu.businessLeasingTitle',
            page: {
              path: '/business/leasing',
            },
          },
          {
            pageId: 'businessApi',
            labelKey: 'menu.businessApiTitle',
            page: {
              href: '/en/business/api',
            },
          },
          {
            pageId: 'affiliate-program',
            labelKey: 'menu.affiliatesTitle',
          },
          {
            pageId: 'influencers',
            labelKey: 'menu.influencersTitle',
            page: {
              path: '/influencers',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'resourcesAndApps',
    group: [
      {
        id: 'resources',
        labelKey: 'menu.resourcesTitle',
        links: [
          { pageId: 'blog', labelKey: 'blogSection.title', page: { path: BLOG_ROOT_PATH } },
          { pageId: 'help' },
          {
            labelKey: 'menu.transparencyIndexTitle',
            locale: LOCALES.filter((locale) => !['es-MX', 'el-GR'].includes(locale)),
            pageId: 'transparencyIndex',
            page: {
              path: '/transparency-index',
            },
          },
          {
            pageId: 'student',
            locale: [
              'en',
              'en-EU',
              'en-GB',
              'en-AU',
              'it',
              'de',
              'fr',
              'fi',
              'pl',
              'ro',
              'pt',
              'es',
              'sv-FI',
              'fi/sv',
            ],
            labelKey: 'menu.studentDiscountLabel',
            hasEndLocale: true,
            url: studentBeansUrl,
          },
        ],
      },
      {
        id: 'apps',
        labelKey: 'menu.appsTitle',
        links: [
          {
            pageId: 'appsVinDecoderAndroid',
            locale: ['en', 'en-EU', 'en-GB', 'en-AU'],
            label: 'VIN Decoder for Android',
            url: app.googlePlay,
          },
          {
            pageId: 'appVinDecoderIos',
            locale: ['en', 'en-EU', 'en-GB', 'en-AU'],
            label: 'VIN Decoder for iOS',
            url: app.appStore,
          },
          {
            pageId: 'appVinCheckAndroid',
            locale: ['lt'],
            label: 'VIN patikra – Android',
            url: app.googlePlay,
          },
          {
            pageId: 'appVinCheckIos',
            locale: ['lt'],
            label: 'VIN patikra – iOS',
            url: app.appStore,
          },
          {
            pageId: 'appVinCheckAndroidPl',
            locale: ['pl'],
            label: 'Dekoder VIN dla Android',
            url: app.googlePlay,
          },
          {
            pageId: 'appVinCheckIosPl',
            locale: ['pl'],
            label: 'Dekoder VIN dla iOS',
            url: app.appStore,
          },
          {
            pageId: 'appVinCheckAndroidCs',
            locale: ['cs'],
            label: 'Dekodér VIN pro Android',
            url: app.googlePlay,
          },
          {
            pageId: 'appVinCheckIosCs',
            locale: ['cs'],
            label: 'Dekodér VIN pro iOS',
            url: app.appStore,
          },
          {
            pageId: 'carOneAndroid',
            locale: ['lt'],
            label: 'carOne – Android',
            url: app.carOneGooglePlay,
          },
          {
            pageId: 'carOneIos',
            locale: ['lt'],
            label: 'carOne – iOS',
            url: app.carOneAppStore,
          },
        ],
      },
    ],
  },
  {
    id: 'company',
    group: [
      {
        id: 'company',
        labelKey: 'menu.companyTitle',
        links: [
          { pageId: 'about' },
          { pageId: 'contacts' },
          {
            pageId: 'careers',
            locale: ['en', 'lt'],
            labelKey: 'menu.careerTitle',
            page: {
              path: '/career',
            },
          },
          { pageId: 'press' },
          {
            label: 'Impressum',
            pageId: 'impressum',
            locale: ['de'],
            page: {
              path: '/impressum',
            },
          },
        ],
      },
    ],
  },
  { id: 'decoders', group: DECODERS_GROUP },
];

const BOTTOM_LINKS = [
  {
    pageId: 'policies',
    labelKey: 'policies.privacy',
    page: { path: '/policies' },
  },
  { pageId: 'terms' },
  {
    pageId: 'informationSecurityPolicy',
    labelKey: 'policies.security',
    page: {
      path: '/information-security-management-system',
    },
  },
  { pageId: 'userDataRemoval' },
  { pageId: 'refundPolicy' },
];

const SECTION_GRID_PROPS = {
  as: 'nav',
  columnCount: { mobileUp: 1, tabletPortraitUp: 2, desktopUp: 4 },
  rowGap: { mobileUp: 3, tabletPortraitUp: 1.5 },
  'aria-label': 'Footer navigation', // TODO: Replace with translation, when it's ready
} as const;

export { MAIN_LINK_GROUPS, DECODERS_GROUP, BOTTOM_LINKS, SECTION_GRID_PROPS };
