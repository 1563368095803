import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addPaymentMethod } from '../api';
import type { AddPaymentMethodPayload, AddPaymentMethodResponse } from '../types';
import { CARD_PAYMENT_METHODS_QUERY_KEY } from './usePaymentMethodsQuery';

type UseAddPaymentMethodMutationProps = {
  onSuccess?: () => void;
};

const useAddPaymentMethodMutation = ({ onSuccess }: UseAddPaymentMethodMutationProps = {}) => {
  const queryClient = useQueryClient();

  const { isSuccess, isPending, mutate, isError } = useMutation<
    AddPaymentMethodResponse,
    unknown,
    AddPaymentMethodPayload
  >({
    mutationFn: addPaymentMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: CARD_PAYMENT_METHODS_QUERY_KEY.shared });
      onSuccess?.();
    },
  });

  return {
    addPaymentMethod: mutate,
    addingPaymentMethod: isPending,
    paymentMethodAdded: isSuccess,
    paymentMethodError: isError,
  };
};

export { useAddPaymentMethodMutation };
export type { UseAddPaymentMethodMutationProps };
