import type { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useRouteData } from 'context/RouteDataProvider';
import { signUp } from 'services/authApi';
import { getBasePath } from 'utils/locale';
import type { EmailPasswordFormFields } from '../schemas';
import { useRecaptcha } from './useRecaptcha';

const useSignUp = () => {
  const { locale } = useRouteData();
  const { getCaptchaResponse } = useRecaptcha();
  const router = useRouter();
  const basePath = getBasePath(locale);

  const { mutate, isPending, error } = useMutation<void, AxiosError, EmailPasswordFormFields>({
    mutationFn: async ({ email, password }: EmailPasswordFormFields) => {
      await signUp(email, password, {
        captchaResponse: await getCaptchaResponse(),
      });
    },
    onSuccess: () => {
      router.push({ pathname: `${basePath}auth/activate` });
    },
  });

  return {
    signUp: mutate,
    signUpError: error,
    signUpLoading: isPending,
  };
};

export { useSignUp };
