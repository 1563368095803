import { Stack } from '@carvertical/ui';
import cx from 'classnames';
// FIXME: Dependency cycle
import { MarketSwitcher } from 'modules/market/components/MarketSwitcher';
import { Menu } from '../Menu';
import { useHeaderMenu } from '../hooks';
import { MenuPopover } from './MenuPopover';
import { SingleMenuItem } from './SingleMenuItem';
import { UserActions } from './UserActions';
import styles from './HorizontalMenu.module.scss';

type HorizontalMenuProps = {
  marketSwitcherShown?: boolean;
};

const HorizontalMenu = ({ marketSwitcherShown }: HorizontalMenuProps) => {
  const { menuGroups } = useHeaderMenu();

  return (
    <>
      <nav className={cx(styles.navigation, 'hideUntilDesktop')}>
        <Stack
          gap={4}
          type="horizontal"
          crossAxisAlign="center"
          className={cx('unstyledList')}
          as="ul"
        >
          {menuGroups.map(({ item, group }) => (
            <li key={group?.id || item?.id}>
              {group && (
                <MenuPopover title={group.title}>
                  <Menu
                    items={group.items}
                    className="px-2"
                    footer={group.Footer ? <group.Footer /> : null}
                    variant="s"
                  />
                </MenuPopover>
              )}

              {item && <SingleMenuItem item={item} />}
            </li>
          ))}
        </Stack>
      </nav>

      <Stack gap={0} type="horizontal" crossAxisAlign="center" className="hideUntilDesktop">
        {marketSwitcherShown && <MarketSwitcher buttonClassName="mr-1" />}

        <UserActions />
      </Stack>
    </>
  );
};

export { HorizontalMenu };
export type { HorizontalMenuProps };
