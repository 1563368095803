import { ComboBox, ComboBoxItem, ComboBoxSection } from '@carvertical/ui';
import { MagnifierIconS } from '@carvertical/icons/react';
import { useTranslation } from 'next-i18next';
import { type ChangeEvent, useState } from 'react';
import { useMedia } from 'react-use';
import { createMediaQuery } from 'utils/styles';
import breakpoint from 'styles/variables.module.scss';
import Flag from 'components/common/Flag';
import { useMarketSwitcher } from '../MarketSwitcher/hooks';
import { SuggestCountryTag } from './SuggestCountryTag';
import { useSuggestCorrectMarket } from './hooks';
import type { SuggestedLocalMarket } from './types';

const SuggestMarketSelect = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { selectMarket, switcherMarkets } = useMarketSwitcher();
  const { suggestedMarket, recommendedMarkets, otherMarkets } = useSuggestCorrectMarket();
  const [selectedCountryCode, setSelectedCountryCode] = useState(suggestedMarket?.countryCode);

  const isTabletLandscape = useMedia(createMediaQuery(breakpoint.tabletLandscapeBreakpoint), false);

  const selectPreferredMarket = (countryCode: string) => {
    const selectedMarket = switcherMarkets.find(
      (switcherMarket) => switcherMarket.countryCode === countryCode,
    );

    if (selectedMarket) {
      selectMarket(selectedMarket);
    }

    setSelectedCountryCode(countryCode);
  };

  const preventMobileFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (isTabletLandscape) {
      return;
    }

    event?.target.blur();
  };

  return (
    <ComboBox
      selectedKey={selectedCountryCode}
      label={t('suggestMarketModal.switchToLabel')}
      placeholder=""
      shouldFocusWrap
      // @ts-expect-error TS(2322)
      onSelectionChange={selectPreferredMarket}
      trailingAddon={<MagnifierIconS />}
      leadingAddon={
        // FIXME: Use `CountryFlag` from UI-lib once it's ready
        // REASON: https://github.com/carVertical/frontend-monorepo/blob/master/packages/ui/src/components/widgets/index.ts#L4
        // @ts-expect-error TS(2741)
        selectedCountryCode ? <Flag countryCode={selectedCountryCode} rounded size={24} /> : null
      }
      // Prevent focus on mobile due to limited space for dropdown
      inputProps={{ onFocus: preventMobileFocus, inputMode: 'none' }}
      className="w-full"
    >
      {renderMarketSection(t('common:general.recommendedLabel'), recommendedMarkets)}

      {renderMarketSection(t('common:general.otherLabel'), otherMarkets)}
    </ComboBox>
  );
};

function renderMarketSection(title: string, markets: SuggestedLocalMarket[]) {
  return (
    <ComboBoxSection title={title}>
      {markets.map(({ countryCode, language, name }) => (
        <ComboBoxItem key={countryCode} id={countryCode} textValue={name}>
          <SuggestCountryTag countryCode={countryCode} language={language} />
        </ComboBoxItem>
      ))}
    </ComboBoxSection>
  );
}

export { SuggestMarketSelect };
