import { isValid, alpha3ToAlpha2, alpha2ToAlpha3 } from 'i18n-iso-countries';
import type {
  CountryCode,
  Alpha2CountryCode,
  Alpha3CountryCode,
  NormalizeFormat,
  NormalizedCountryCode,
} from './types';
import { createIntl } from './services';

const countryCodeToAlpha2 = (countryCode: CountryCode) =>
  (countryCode.length === 3 ? alpha3ToAlpha2(countryCode) : countryCode) as Alpha2CountryCode;

const countryCodeToAlpha3 = (countryCode: CountryCode) =>
  (countryCode.length === 2 ? alpha2ToAlpha3(countryCode) : countryCode) as Alpha3CountryCode;

const normalizeCountryCode = <TFormat extends NormalizeFormat>(
  countryCode: string,
  format: TFormat = 'both' as TFormat,
): NormalizedCountryCode<TFormat> | undefined => {
  if (!isValid(countryCode)) {
    return undefined;
  }

  const validCountryCode = countryCode.toUpperCase() as NormalizedCountryCode<TFormat>;

  if (format === 'alpha2') {
    return countryCodeToAlpha2(validCountryCode) as NormalizedCountryCode<TFormat>;
  }

  if (format === 'alpha3') {
    return countryCodeToAlpha3(validCountryCode) as NormalizedCountryCode<TFormat>;
  }

  return validCountryCode;
};

const getCountryName = (countryCode: CountryCode, language = 'en') =>
  createIntl(language).formatDisplayName(countryCodeToAlpha2(countryCode), {
    type: 'region',
  });

export { countryCodeToAlpha2, countryCodeToAlpha3, normalizeCountryCode, getCountryName };
