import { useCallback } from 'react';
import { useIdentifierPopupActions, useIdentifierPopupShown } from './useIdentifierPopupStore';

const useIdentifierPopup = () => {
  const shown = useIdentifierPopupShown();
  const { setShown } = useIdentifierPopupActions();

  const show = useCallback(() => setShown(true), [setShown]);

  const hide = useCallback(() => setShown(false), [setShown]);

  return {
    shown,
    show,
    hide,
  };
};

export { useIdentifierPopup };
