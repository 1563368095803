import { useQueryClient, useMutation } from '@tanstack/react-query';
import { deletePaymentMethod } from '../api';
import type {
  DeletePaymentMethodPayload,
  DeletePaymentMethodResponse,
  PaymentOption,
} from '../types';
import { CARD_PAYMENT_METHODS_QUERY_KEY } from './usePaymentMethodsQuery';

const useDeletePaymentMethodMutation = () => {
  const queryClient = useQueryClient();

  const { isPending, mutate, reset, variables } = useMutation<
    DeletePaymentMethodResponse,
    unknown,
    DeletePaymentMethodPayload
  >({
    mutationFn: deletePaymentMethod,
    onMutate: async ({ storedPaymentMethodId }) => {
      await queryClient.cancelQueries({ queryKey: CARD_PAYMENT_METHODS_QUERY_KEY.shared });

      const previousData = queryClient.getQueryData(CARD_PAYMENT_METHODS_QUERY_KEY.shared);

      queryClient.setQueryData(
        CARD_PAYMENT_METHODS_QUERY_KEY.shared,
        (paymentOption?: PaymentOption) => {
          if (!paymentOption) {
            return paymentOption;
          }

          return {
            ...paymentOption,
            storedPaymentMethods: paymentOption.storedPaymentMethods?.filter(
              ({ id }) => id !== storedPaymentMethodId,
            ),
          };
        },
      );

      return { previousData };
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: CARD_PAYMENT_METHODS_QUERY_KEY.shared }),
  });

  return {
    deletePaymentMethod: mutate,
    deletingPaymentMethod: isPending,
    resetPaymentDeletion: reset,
    deletedPaymentMethodId: variables?.storedPaymentMethodId,
  };
};

export { useDeletePaymentMethodMutation };
