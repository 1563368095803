import c, { useRef as $, useCallback as b, useState as x, useContext as m, useEffect as M, useMemo as S } from "react";
import { c as V } from "./clsx.0d472180.js";
const y = typeof document < "u" ? c.useLayoutEffect : () => {
};
function h(t) {
  const e = $(null);
  return y(() => {
    e.current = t;
  }, [
    t
  ]), b((...n) => {
    const r = e.current;
    return r == null ? void 0 : r(...n);
  }, []);
}
function k(t) {
  let [e, n] = x(t), r = $(null), l = h(() => {
    if (!r.current)
      return;
    let f = r.current.next();
    if (f.done) {
      r.current = null;
      return;
    }
    e === f.value ? l() : n(f.value);
  });
  y(() => {
    r.current && l();
  });
  let a = h((f) => {
    r.current = f(e), l();
  });
  return [
    e,
    a
  ];
}
const p = {
  prefix: String(Math.round(Math.random() * 1e10)),
  current: 0
}, g = /* @__PURE__ */ c.createContext(p), I = /* @__PURE__ */ c.createContext(!1);
let N = !!(typeof window < "u" && window.document && window.document.createElement), v = /* @__PURE__ */ new WeakMap();
function j(t = !1) {
  let e = m(g), n = $(null);
  if (n.current === null && !t) {
    var r, l;
    let a = (l = c.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED) === null || l === void 0 || (r = l.ReactCurrentOwner) === null || r === void 0 ? void 0 : r.current;
    if (a) {
      let f = v.get(a);
      f == null ? v.set(a, {
        id: e.current,
        state: a.memoizedState
      }) : a.memoizedState !== f.state && (e.current = f.id, v.delete(a));
    }
    n.current = ++e.current;
  }
  return n.current;
}
function P(t) {
  let e = m(g);
  e === p && !N && console.warn("When server rendering, you must wrap your application in an <SSRProvider> to ensure consistent ids are generated between the client and server.");
  let n = j(!!t), r = e === p && process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${e.prefix}`;
  return t || `${r}-${n}`;
}
function q(t) {
  let e = c.useId(), [n] = x(J()), r = n || process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${p.prefix}`;
  return t || `${r}-${e}`;
}
const z = typeof c.useId == "function" ? q : P;
function H() {
  return !1;
}
function K() {
  return !0;
}
function G(t) {
  return () => {
  };
}
function J() {
  return typeof c.useSyncExternalStore == "function" ? c.useSyncExternalStore(G, H, K) : m(I);
}
let Q = !!(typeof window < "u" && window.document && window.document.createElement), i = /* @__PURE__ */ new Map();
function X(t) {
  let [e, n] = x(t), r = $(null), l = z(e), a = b((f) => {
    r.current = f;
  }, []);
  return Q && (i.has(l) && !i.get(l).includes(a) ? i.set(l, [
    ...i.get(l),
    a
  ]) : i.set(l, [
    a
  ])), y(() => {
    let f = l;
    return () => {
      i.delete(f);
    };
  }, [
    l
  ]), M(() => {
    let f = r.current;
    f && (r.current = null, n(f));
  }), l;
}
function Z(t, e) {
  if (t === e)
    return t;
  let n = i.get(t);
  if (n)
    return n.forEach((l) => l(e)), e;
  let r = i.get(e);
  return r ? (r.forEach((l) => l(t)), t) : e;
}
function Y(t = []) {
  let e = X(), [n, r] = k(e), l = b(() => {
    r(function* () {
      yield e, yield document.getElementById(e) ? e : void 0;
    });
  }, [
    e,
    r
  ]);
  return y(l, [
    e,
    l,
    ...t
  ]), n;
}
function E(...t) {
  return (...e) => {
    for (let n of t)
      typeof n == "function" && n(...e);
  };
}
function F(...t) {
  let e = {
    ...t[0]
  };
  for (let n = 1; n < t.length; n++) {
    let r = t[n];
    for (let l in r) {
      let a = e[l], f = r[l];
      typeof a == "function" && typeof f == "function" && // This is a lot faster than a regex.
      l[0] === "o" && l[1] === "n" && l.charCodeAt(2) >= /* 'A' */
      65 && l.charCodeAt(2) <= /* 'Z' */
      90 ? e[l] = E(a, f) : (l === "className" || l === "UNSAFE_className") && typeof a == "string" && typeof f == "string" ? e[l] = V(a, f) : l === "id" && a && f ? e.id = Z(a, f) : e[l] = f !== void 0 ? f : a;
    }
  }
  return e;
}
function W(...t) {
  return t.length === 1 && t[0] ? t[0] : (e) => {
    for (let n of t)
      typeof n == "function" ? n(e) : n != null && (n.current = e);
  };
}
function A(t) {
  const e = $(null);
  return S(() => ({
    get current() {
      return e.current;
    },
    set current(n) {
      e.current = n, typeof t == "function" ? t(n) : t && (t.current = n);
    }
  }), [
    t
  ]);
}
const w = Symbol("default");
function L({ values: t, children: e }) {
  for (let [n, r] of t)
    e = /* @__PURE__ */ c.createElement(n.Provider, {
      value: r
    }, e);
  return e;
}
function O(t) {
  let { className: e, style: n, children: r, defaultClassName: l, defaultChildren: a, defaultStyle: f, values: s } = t;
  return S(() => {
    let u, d, o;
    return typeof e == "function" ? u = e({
      ...s,
      defaultClassName: l
    }) : u = e, typeof n == "function" ? d = n({
      ...s,
      defaultStyle: f || {}
    }) : d = n, typeof r == "function" ? o = r({
      ...s,
      defaultChildren: a
    }) : r == null ? o = a : o = r, {
      className: u ?? l,
      style: d || f ? {
        ...f,
        ...d
      } : void 0,
      children: o ?? a,
      "data-rac": ""
    };
  }, [
    e,
    n,
    r,
    l,
    a,
    f,
    s
  ]);
}
function D(t, e) {
  let n = m(t);
  if (e === null)
    return null;
  if (n && typeof n == "object" && "slots" in n && n.slots) {
    let r = new Intl.ListFormat().format(Object.keys(n.slots).map((a) => `"${a}"`));
    if (!e && !n.slots[w])
      throw new Error(`A slot prop is required. Valid slot names are ${r}.`);
    let l = e || w;
    if (!n.slots[l])
      throw new Error(`Invalid slot "${e}". Valid slot names are ${r}.`);
    return n.slots[l];
  }
  return n;
}
function R(t, e, n) {
  let r = D(n, t.slot) || {}, { ref: l, ...a } = r, f = A(S(() => W(e, l), [
    e,
    l
  ])), s = F(a, t);
  return "style" in a && a.style && "style" in t && t.style && (typeof a.style == "function" || typeof t.style == "function" ? s.style = (u) => {
    let d = typeof a.style == "function" ? a.style(u) : a.style, o = {
      ...u.defaultStyle,
      ...d
    }, C = typeof t.style == "function" ? t.style({
      ...u,
      defaultStyle: o
    }) : t.style;
    return {
      ...o,
      ...C
    };
  } : s.style = {
    ...a.style,
    ...t.style
  }), [
    s,
    f
  ];
}
function T() {
  let [t, e] = x(!0), n = $(!1), r = b((l) => {
    n.current = !0, e(!!l);
  }, []);
  return y(() => {
    n.current || e(!1);
  }, []), [
    r,
    t
  ];
}
function _(t) {
  const e = /^(data-.*)$/;
  let n = {};
  for (const r in t)
    e.test(r) || (n[r] = t[r]);
  return n;
}
export {
  R as $,
  y as a,
  J as b,
  F as c,
  Y as d,
  O as e,
  X as f,
  L as g,
  D as h,
  A as i,
  E as j,
  W as k,
  T as l,
  h as m,
  _ as n
};
