import { useMedia } from 'react-use';
import { theme } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { motion, type Variants } from 'framer-motion';
import { forwardRef } from 'react';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { createMediaQuery } from 'utils/styles';
import { useRouteData } from 'context/RouteDataProvider';
import { useIdentifierPopup } from '../hooks';

const CONTINUE_CTA_CLASSNAME = 'a-b-stickyvin-button-sticky';

const IdentifierPopup = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation();
  const { identifierPopupEnabled } = useRouteData();
  const { shown, show } = useIdentifierPopup();
  const isTabletPortrait = useMedia(createMediaQuery(theme.breakpointTabletPortrait), false);

  if (!identifierPopupEnabled) {
    return null;
  }

  return (
    <motion.div
      className="relative z-10 flex w-full flex-col items-center gap-1.5 bg-blue px-2 pb-3 pt-2 shadow-s shadow-black/20 sm:fixed sm:bottom-1.5 sm:left-1/2 sm:max-w-64 sm:-translate-x-1/2 sm:rounded-m sm:p-1"
      variants={getAnimationVariants(isTabletPortrait)}
      initial="hidden"
      animate={shown ? 'visible' : 'hidden'}
      exit="hidden"
      transition={{ ease: 'easeOut', duration: 0.3 }}
      onFocusCapture={show}
      ref={ref}
    >
      <p className="text-h-xs hidden text-center text-white sm:flex">{t('app.slogan')}</p>

      <IdentifierForm
        forcedMode="vin"
        continueWithoutVinCtaVisible={false}
        explanationShown={isTabletPortrait}
        size={isTabletPortrait ? 'l' : 'm'}
        continueCtaClassName={CONTINUE_CTA_CLASSNAME}
      />
    </motion.div>
  );
});

function getAnimationVariants(isTabletPortrait: boolean): Variants {
  const x = isTabletPortrait ? '-50%' : '0%';

  return {
    hidden: { opacity: 0.8, y: '120%', x },
    visible: { opacity: 1, y: 0, x },
  };
}

export { IdentifierPopup };
