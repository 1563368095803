import { motion } from 'framer-motion';
import { cn } from '@carvertical/utils/styling';
import { useMedia } from 'react-use';
import { theme } from '@carvertical/ui';
import { IdentifierPopup, useIdentifierPopup } from 'modules/identifier';
import { createMediaQuery } from 'utils/styles';
import { SupportPopup, SupportToggle } from 'modules/support';

const FloatingActions = () => {
  const { shown: identifierPopupShown } = useIdentifierPopup();
  const isTabletPortrait = useMedia(createMediaQuery(theme.breakpointTabletPortrait), false);

  return (
    <div className={cn('fixed bottom-0 right-0 z-20 flex w-full flex-col items-end sm:static')}>
      <SupportPopup />

      <motion.div
        layout
        style={{ position: identifierPopupShown && !isTabletPortrait ? 'relative' : 'fixed' }}
        transition={{ type: 'spring', bounce: 0.2, duration: 0.4 }}
        className={cn(
          'fixed bottom-2.5 right-2 z-40 sm:fixed',
          identifierPopupShown && !isTabletPortrait && 'right-3',
        )}
      >
        <SupportToggle />
      </motion.div>

      <IdentifierPopup />
    </div>
  );
};

export { FloatingActions };
