import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as y } from "react";
import { c as d } from "./chunks/cn.fbf70112.js";
import { Icon as g } from "./icon.mjs";
import { v as x } from "./chunks/warnings.8f6035a3.js";
import { s as a } from "./chunks/styles.077839a8.js";
import "./chunks/clsx.0d472180.js";
const l = {
  xs: "xs",
  s: "xs",
  m: "xs"
}, m = y(
  ({
    as: e = "div",
    className: i,
    variant: n = "outlined",
    size: s = "m",
    emphasized: r = !1,
    icon: o,
    children: p,
    iconClassName: c,
    ...u
  }, f) => /* @__PURE__ */ t.jsxs(
    e,
    {
      ref: f,
      className: d(
        a.root,
        a[n],
        a[s],
        r && a.emphasized,
        !!o && a.hasIcon,
        i
      ),
      ...u,
      children: [
        !!o && /* @__PURE__ */ t.jsx(N, { icon: o, className: c, size: s }),
        p
      ]
    }
  )
);
function N({ icon: e, className: i, size: n = "xs" }) {
  const s = d(a.icon, i);
  if (typeof e == "string")
    return /* @__PURE__ */ t.jsx(g, { name: e, size: l[n], className: s });
  const r = e;
  return x({
    iconName: e.displayName,
    componentSize: n,
    sizeMap: l,
    componentName: "Badge"
  }), /* @__PURE__ */ t.jsx(r, { className: s });
}
try {
  m.displayName = "Badge", m.__docgenInfo = { description: "", displayName: "Badge", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } }, variant: { defaultValue: { value: "outlined" }, description: "", name: "variant", required: !1, type: { name: "BadgeVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "BadgeSize" } }, emphasized: { defaultValue: { value: !1 }, description: "", name: "emphasized", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !1, type: { name: "IconTypeWithLegacyFallback" } } } };
} catch {
}
export {
  m as Badge
};
