import { $ as s } from "./useListState.accd1c78.js";
import { a as u } from "./utils.ec96ba96.js";
import { useState as c } from "react";
function f(e, t) {
  let a = t == null ? void 0 : t.isDisabled, [d, i] = c(!1);
  return u(() => {
    if (e != null && e.current && !a) {
      let r = () => {
        if (e.current) {
          let l = s(e.current, {
            tabbable: !0
          });
          i(!!l.nextNode());
        }
      };
      r();
      let b = new MutationObserver(r);
      return b.observe(e.current, {
        subtree: !0,
        childList: !0,
        attributes: !0,
        attributeFilter: [
          "tabIndex",
          "disabled"
        ]
      }), () => {
        b.disconnect();
      };
    }
  }), a ? !1 : d;
}
export {
  f as $
};
