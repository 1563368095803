import { LinkButton, Modal, ModalTitle, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
import { storeMarketPreference } from '../../utils';
import { useMarketSwitcher } from './hooks';

const LanguageSelectionModal = () => {
  const { t } = useTranslation();
  const { selectedMarket } = useMarketSwitcher();
  const { close: closeLanguageSelectionModal, isModalOpen: modalOpen } =
    useModal('languageSelection');

  return (
    <Modal
      open={modalOpen}
      onClose={closeLanguageSelectionModal}
      size="m"
      overlayClassName="bg-black/15"
      contentClassName="text-center pt-0 pb-6"
      boxClassName="max-w-80 max-h-32 sm:max-h-40 rounded-l mx-3"
      title={t('marketSwitcher.languageTitle')}
    >
      <ModalTitle className="mb-4 flex-grow text-center">
        {t('marketSwitcher.languageTitle')}
      </ModalTitle>

      {selectedMarket ? (
        <ul className="m-0 flex list-none flex-col items-center gap-3 p-0">
          {selectedMarket.languages.map(({ label, slug }) => (
            <li key={slug}>
              <LinkButton
                title={label}
                href={slug}
                size="m"
                onClick={storeMarketPreference}
                className="max-w-min transition hover:no-underline focus:outline-none focus-visible:ring focus-visible:ring-offset-2"
              >
                {label}
              </LinkButton>
            </li>
          ))}
        </ul>
      ) : (
        <Text>{t('general.noResultsLabel')}</Text>
      )}
    </Modal>
  );
};

export { LanguageSelectionModal };
