import { j as p } from "./jsx-runtime.82a9ddf6.js";
import { c as o } from "./cn.fbf70112.js";
const i = ({
  as: a = "p",
  align: t,
  textColor: e = "dark",
  italic: n,
  inline: r,
  children: l,
  className: s,
  ...d
}) => /* @__PURE__ */ p.jsx(
  a,
  {
    className: o(
      "m-0 block",
      e === "dark" && "text-grey-900",
      e === "light" && "text-white",
      e === "darkSecondary" && "text-grey-600",
      e === "highlighted" && "text-blue",
      e === "inherited" && "text-inherit",
      t === "center" && "text-center",
      t === "left" && "text-left",
      t === "right" && "text-right",
      t === "justify" && "text-justify",
      r && "inline",
      n && "italic",
      s
    ),
    ...d,
    children: l
  }
);
try {
  i.displayName = "TypographyElement", i.__docgenInfo = { description: "", displayName: "TypographyElement", props: { as: { defaultValue: null, description: 'This prop controls what HTML tag to render (**it does not change styling**).\nExample: set `variant="h2"` and `as="span"`, which will render `<span>` element with H2 styles.', name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "left" | "right" | "justify"' } }, textColor: { defaultValue: { value: "dark" }, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } }, italic: { defaultValue: null, description: "", name: "italic", required: !1, type: { name: "boolean" } }, inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  i as T
};
