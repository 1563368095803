import { isEmpty } from 'lodash';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { getUserId } from 'services/session';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { USER_QUOTAS_QUERY_KEY } from 'modules/user/hooks';
import {
  autoRefillSubscription,
  cancelSubscription,
  createSubscription,
  getSubscription,
  reactivateSubscription,
} from '../api';
import type { CreateSubscriptionPayload, CreateSubscriptionResponse } from '../types';

type UseSubscriptionProps = {
  enabled?: boolean;
  retry?: boolean;
  needsRefetching?: boolean;
};

const SUBSCRIPTION_QUERY_KEY = {
  shared: ['subscription'],
  personal: (userId?: string) => ['subscription', userId],
};

const SUBSCRIPTION_POLL_INTERVAL = 2_000;

const useSubscription = ({
  enabled = true,
  retry = false,
  needsRefetching = false,
}: UseSubscriptionProps = {}) => {
  const queryClient = useQueryClient();
  const { close: closeCancelSubscriptionModal } = useModal('cancelSubscription');
  const [refetching, setRefetching] = useState(false);
  const [reactivated, setReactivated] = useState(false);

  const queryKey = SUBSCRIPTION_QUERY_KEY.personal(getUserId());

  const { data: subscription, isPending } = useQuery({
    enabled,
    queryKey,
    queryFn: getSubscription,
    retry,
    refetchInterval: (query) => {
      const { data } = query.state;
      const noNextBillingDate = data && data?.status === 'active' && isEmpty(data?.nextBillingAt);
      const reactivateDone = reactivated && data?.status === 'active';
      const reactivatePending = reactivated && data?.status === 'cancelled';
      const shouldRefetch = needsRefetching && (noNextBillingDate || reactivatePending);

      setRefetching(shouldRefetch);

      if (reactivateDone) {
        setReactivated(false);
        return false;
      }

      return shouldRefetch ? SUBSCRIPTION_POLL_INTERVAL : false;
    },
  });

  const cancelMutation = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
      closeCancelSubscriptionModal();
    },
  });

  const createMutation = useMutation<
    CreateSubscriptionResponse,
    unknown,
    CreateSubscriptionPayload
  >({
    mutationFn: createSubscription,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const reactivateMutation = useMutation({
    mutationFn: reactivateSubscription,
    onSuccess: () => {
      setReactivated(true);
      queryClient.invalidateQueries({ queryKey: USER_QUOTAS_QUERY_KEY.shared });
    },
  });

  const autoRefillMutation = useMutation({
    mutationFn: autoRefillSubscription,
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return {
    subscription,
    subscriptionActive: subscription?.status === 'active',
    subscriptionCancelled: subscription?.status === 'cancelled',
    fetching: isPending || refetching,
    cancelSubscription: cancelMutation.mutate,
    subscriptionCancelling: cancelMutation.isPending,
    createSubscription: createMutation.mutateAsync,
    subscriptionCreating: createMutation.isPending,
    reactivateSubscription: reactivateMutation.mutate,
    subscriptionReactivating: reactivateMutation.isPending,
    autoRefillSubscription: autoRefillMutation.mutate,
    subscriptionAutoRefilling: autoRefillMutation.isPending,
  };
};

export { useSubscription, SUBSCRIPTION_QUERY_KEY };
