import { compact, map, orderBy } from 'lodash';
import { useMemo } from 'react';
import { getCountryName } from 'services/countries';
import { markets } from 'config-ts';
import { useRouteData } from 'context/RouteDataProvider';
import type { OptionalMarketIdRecord, SwitcherMarket } from '../../../types';
import { useMarket } from '../../../hooks';
import {
  useLanguageModalType,
  useMarketSwitcherActions,
  useSelectedMarket,
} from './useMarketSwitcherStore';

const ORDER: OptionalMarketIdRecord<number> = {
  europe: -1,
};
const IS_PRODUCTION = process.env.APP_ENV === 'production';

const useMarketSwitcher = () => {
  const { getMarketLanguages } = useMarket();
  const { market: currentMarket } = useRouteData();
  const { selectMarket: selectMarketAction, setLanguageModalType } = useMarketSwitcherActions();

  const selectedMarket = useSelectedMarket();
  const languageModalType = useLanguageModalType();

  const selectMarket = (market: SwitcherMarket) => selectMarketAction(market);

  const switcherMarkets = useMemo(() => {
    const availableMarkets = compact<SwitcherMarket>(
      map(
        markets,
        ({ countryCode, countryCodeLong, hidden, id }) =>
          (!IS_PRODUCTION || !hidden) && {
            id,
            countryCode,
            countryCodeLong,
            name: getCountryName(countryCode),
            languages: getMarketLanguages(countryCode),
            order: ORDER[id] || 0,
            active: currentMarket.countryCode === countryCode,
          },
      ),
    );

    return orderBy(availableMarkets, ['name']);
  }, [getMarketLanguages, currentMarket.countryCode]);

  return {
    selectMarket,
    selectedMarket,
    switcherMarkets,
    languageModalType,
    setLanguageModalType,
  };
};

export { useMarketSwitcher };
