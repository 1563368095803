import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as h } from "react";
import { c as a } from "./chunks/cn.fbf70112.js";
import { c as b, a as k } from "./chunks/cva.09768d84.js";
import { Icon as I } from "./icon.mjs";
import { v as N } from "./chunks/warnings.8f6035a3.js";
import { AnchorOrButton as g } from "./anchorOrButton.mjs";
import "./chunks/clsx.0d472180.js";
import "./chunks/styles.077839a8.js";
const u = {
  s: "xs",
  m: "m",
  l: "m"
}, _ = b(
  k({
    base: "inline-flex twp items-center transition-colors duration-150 rounded-xs outline-none focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-blue-300",
    variants: {
      variant: {
        blue: "text-blue hover:text-blue-800 active:hover:text-blue",
        black: "text-black hover:text-grey-700 active:hover:text-black",
        white: "text-white hover:text-grey-400 active:hover:text-white",
        red: "text-red hover:text-red-700 active:hover:text-red"
      },
      size: {
        s: "text-xs+",
        m: "text-s+",
        l: "text-m+"
      },
      underline: {
        true: "underline underline-offset-4"
      },
      disabled: {
        true: "text-grey-500 hover:text-grey-500 pointer-events-none"
      },
      loading: {
        true: "pointer-events-none"
      }
    }
  })
), o = h(
  ({
    disabled: r,
    variant: m = "black",
    size: e = "s",
    leadingIcon: i,
    trailingIcon: l,
    className: c,
    children: d,
    underline: p = !0,
    ...f
  }, x) => {
    const v = u[e], s = (n) => {
      if (typeof n == "string")
        return /* @__PURE__ */ t.jsx(I, { name: n, size: v });
      const y = n;
      return N({
        iconName: n.displayName,
        componentSize: e,
        sizeMap: u,
        componentName: o.displayName
      }), /* @__PURE__ */ t.jsx(
        y,
        {
          className: a({
            "size-2": e === "s",
            "size-3": e === "m" || e === "l"
          })
        }
      );
    };
    return /* @__PURE__ */ t.jsxs(
      g,
      {
        unstyled: !0,
        ...f,
        ref: x,
        disabled: r,
        className: a(_({ variant: m, size: e, underline: p, disabled: r }), c),
        children: [
          i && /* @__PURE__ */ t.jsx("span", { className: a("mr-0.5", e === "m" && "mr-0.75", e === "l" && "mr-1"), children: s(i) }),
          d,
          l && /* @__PURE__ */ t.jsx("span", { className: a("ml-0.5", e === "m" && "ml-0.75", e === "l" && "ml-1"), children: s(l) })
        ]
      }
    );
  }
);
try {
  o.displayName = "LinkButton", o.__docgenInfo = { description: "", displayName: "LinkButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } }, variant: { defaultValue: { value: "black" }, description: "", name: "variant", required: !1, type: { name: "LinkButtonVariant" } }, size: { defaultValue: { value: "s" }, description: "", name: "size", required: !1, type: { name: "LinkButtonSize" } }, underline: { defaultValue: { value: !0 }, description: "", name: "underline", required: !1, type: { name: "boolean" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconTypeWithLegacyFallback" } } } };
} catch {
}
export {
  o as LinkButton
};
