import { create } from 'zustand';

type StoredPaymentActionState = {
  lastFourDigits: string;
  actions: {
    setLastFourDigits: (lastFourDigits: string) => void;
  };
};

const useStoredPaymentActionsStore = create<StoredPaymentActionState>()((set) => ({
  lastFourDigits: '',
  actions: {
    setLastFourDigits: (lastFourDigits) => set({ lastFourDigits }),
  },
}));

const useLastFourDigits = () => useStoredPaymentActionsStore((store) => store.lastFourDigits);
const useStoredPaymentActions = () => useStoredPaymentActionsStore((store) => store.actions);

export { useStoredPaymentActionsStore, useLastFourDigits, useStoredPaymentActions };
export type { StoredPaymentActionState };
