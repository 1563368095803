import qs from 'querystringify';
import parseUrl from 'url-parse';
import { isEmpty, find, sortBy } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { getLanguage } from 'utils/locale';
import { basePathsByLocale, markets } from 'config-ts';
import { normalizeCountryCode } from 'services/countries';
import { useRouteData } from 'context/RouteDataProvider';
import { LANGUAGE } from '../constants';
import type { Language } from '../types';

const LANGUAGE_PARAM = 'l';

const getSearchParams = (pathname: string) => {
  const searchParams = parseUrl(pathname, true).query;

  delete searchParams[LANGUAGE_PARAM];

  if (isEmpty(searchParams)) {
    return '';
  }

  return `?${qs.stringify(searchParams)}`;
};

const getMarketLanguageFlavors = (countryCode: string) => {
  const normalizedCountryCode = normalizeCountryCode(countryCode);

  return find(markets, { countryCode: normalizedCountryCode })?.flavors || [];
};

const useMarket = () => {
  const { alternativePaths = {}, locale: currentMarketLocale } = useRouteData();
  const { asPath: pathname } = useRouter();

  const getMarketLanguages = useCallback(
    (countryCode: string, pathOverride?: string): Language[] => {
      const availableFlavors = getMarketLanguageFlavors(countryCode);

      const getSlug = (locale: string, basePath: string) => {
        if (!alternativePaths?.[locale]) {
          return basePathsByLocale[locale];
        }

        if (pathOverride) {
          return `${basePath}${pathOverride}`;
        }

        return (alternativePaths[locale] || basePath) + getSearchParams(pathname);
      };

      const languages = availableFlavors.map(({ basePath, locale }) => ({
        label: LANGUAGE[getLanguage(locale)],
        slug: getSlug(locale, basePath),
        active: currentMarketLocale === locale,
      }));

      return sortBy(languages, ({ active }) => !active);
    },
    [alternativePaths, currentMarketLocale, pathname],
  );

  return { getMarketLanguages };
};

export { useMarket };
