import { a as r, b as y } from "./useListState.accd1c78.js";
import { useMemo as K } from "react";
function $(l) {
  var t;
  let [e, c] = r(l.selectedKey, (t = l.defaultSelectedKey) !== null && t !== void 0 ? t : null, l.onSelectionChange), i = K(() => e != null ? [
    e
  ] : [], [
    e
  ]), { collection: o, disabledKeys: s, selectionManager: u } = y({
    ...l,
    selectionMode: "single",
    disallowEmptySelection: !0,
    allowDuplicateSelectionEvents: !0,
    selectedKeys: i,
    onSelectionChange: (d) => {
      if (d === "all")
        return;
      var n;
      let a = (n = d.values().next().value) !== null && n !== void 0 ? n : null;
      a === e && l.onSelectionChange && l.onSelectionChange(a), c(a);
    }
  }), f = e != null ? o.getItem(e) : null;
  return {
    collection: o,
    disabledKeys: s,
    selectionManager: u,
    selectedKey: e,
    setSelectedKey: c,
    selectedItem: f
  };
}
export {
  $
};
