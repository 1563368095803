import { memoize } from 'lodash';
import { adyenConfig } from 'config';
import { load } from 'utils/script';
import { getRequest } from './request';

const PAYMENT_OPTIONS_TIMEOUT = 5_000;

const getEnv = () => (process.env.APP_ENV === 'production' ? 'live' : 'test');

const fetchPaymentOptions = memoize(async () => {
  try {
    const { data } = await getRequest('/purchases/payment-options', {
      timeout: PAYMENT_OPTIONS_TIMEOUT,
    });
    return data;
  } catch (err) {
    return undefined;
  }
});

async function createCheckout(type, config) {
  await load(adyenConfig.sdk, 'adyen', { crossorigin: 'anonymous' });

  const checkout = new window.AdyenCheckout({
    clientKey: adyenConfig.clientKey,
    locale: 'en-US',
    environment: getEnv(),
    paymentMethodsResponse: await fetchPaymentOptions(),
  });

  return checkout.create(type, config);
}

async function mountSecuredFieldsOn(node, config) {
  const checkout = await createCheckout('securedfields', config);
  return checkout.mount(node);
}

async function createFingerprint(id) {
  await load(`https://${getEnv()}.adyen.com/hpp/js/df.js`, 'adyen-fingerprint');

  if (!window.dfDo) {
    return undefined;
  }

  window.dfDo(id);

  return new Promise((resolve) => {
    setTimeout(() => {
      window.dfSet(id);

      resolve(document.getElementById(id)?.value);
    }, 1_000);
  });
}

export { createCheckout, mountSecuredFieldsOn, createFingerprint };
