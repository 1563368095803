import { Container, IconButton, ModalTitle } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { AnimatePresence, motion, type AnimationProps } from 'framer-motion';
import { useKeyPressEvent } from 'react-use';
import { useEffect, useRef } from 'react';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import { XMarkIconM } from '@carvertical/icons/react';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
import { MarketSelection } from './MarketSelection';
import { LanguageSelectionModal } from './LanguageSelectionModal';

const TRANSITION = {
  y: { type: 'spring', damping: 26, stiffness: 170 },
};

const DIALOG_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { y: '-100%' },
    visible: ({ height }) => ({ y: 0, height }),
  },
  transition: TRANSITION,
};

const CONTENT_ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ...TRANSITION,
        delay: 0.1,
      },
    },
  },
  transition: TRANSITION,
};

const MARKET_SWITCHER_DIALOG_ID = 'market-switcher-dialog';

const MarketSwitcherModal = () => {
  const { t } = useTranslation();
  const { isModalOpen: marketSelectionModalOpen, close } = useModal('marketSelection');
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialogRef.current && marketSelectionModalOpen) {
      lock(dialogRef.current);
    }

    return () => {
      clearBodyLocks();
    };
  }, [marketSelectionModalOpen]);

  useKeyPressEvent('Escape', close);

  return (
    <AnimatePresence mode="wait">
      {marketSelectionModalOpen && (
        <motion.dialog
          key={MARKET_SWITCHER_DIALOG_ID}
          id={MARKET_SWITCHER_DIALOG_ID}
          aria-modal="true"
          open
          ref={dialogRef}
          custom={{ height: '100%' }}
          title={t('marketSwitcher.countryTitle')}
          className="fixed inset-[0_0_0_auto] z-10 w-full overflow-y-auto border-none bg-white p-0 lg:inset-[64px_0_0_auto] lg:z-[-1] lg:max-h-[calc(100dvh-64px)]"
          {...DIALOG_ANIMATION_PROPS}
        >
          <motion.div {...CONTENT_ANIMATION_PROPS}>
            <Container>
              <div className="flex justify-end">
                <IconButton
                  label={t('general.closeAction')}
                  variant="white"
                  icon={XMarkIconM}
                  size="s"
                  onClick={close}
                  className="mt-3 sm:mt-5"
                />
              </div>

              <ModalTitle className="mb-2 text-center">
                {t('marketSwitcher.countryTitle')}
              </ModalTitle>

              <MarketSelection />
            </Container>

            <LanguageSelectionModal />
          </motion.div>
        </motion.dialog>
      )}
    </AnimatePresence>
  );
};

export { MarketSwitcherModal, MARKET_SWITCHER_DIALOG_ID };
