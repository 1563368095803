// Internalization services eventually will be moved to @carvertical/services
// once we verify the proper structure of shared packages (like @carvertical/utils)
const catchErrors =
  <TValue extends string | number, TArgs, TReturn>(
    intlFunction: (value: TValue, ...args: TArgs[]) => TReturn,
    formatRawValueOnError: (value: TValue) => TReturn = (value) => value.toString() as TReturn,
  ) =>
  (value: TValue, ...args: TArgs[]) => {
    try {
      return intlFunction(value, ...args);
    } catch (error) {
      return formatRawValueOnError(value);
    }
  };

const createIntl = (language: string) => ({
  formatNumber: catchErrors((value: number, options?: Intl.NumberFormatOptions) =>
    new Intl.NumberFormat(language, options).format(value),
  ),
  formatNumberToParts: catchErrors(
    (value: number, options?: Intl.NumberFormatOptions) =>
      new Intl.NumberFormat(language, options).formatToParts(value),
    (value) => [{ type: 'integer', value: value.toString() }],
  ),
  formatDisplayName: catchErrors((value: string, options: Intl.DisplayNamesOptions) =>
    new Intl.DisplayNames([language], options).of(value),
  ),
});

export { createIntl };
