import { create } from 'zustand';
import type { SwitcherMarket } from 'modules/market';

type LanguageModalType = 'checkout' | 'suggestion' | 'switcher';

type MarketSwitcherStore = {
  languageModalType: LanguageModalType;
  selectedMarket?: SwitcherMarket;
  actions: {
    selectMarket: (market: SwitcherMarket) => void;
    setLanguageModalType: (languageModalType: LanguageModalType) => void;
  };
};

const useMarketSwitcherStore = create<MarketSwitcherStore>()((set) => ({
  languageModalType: 'switcher',
  actions: {
    selectMarket: (selectedMarket) => set({ selectedMarket }),
    setLanguageModalType: (languageModalType) => set({ languageModalType }),
  },
}));

const useSelectedMarket = () => useMarketSwitcherStore((store) => store.selectedMarket);
const useLanguageModalType = () => useMarketSwitcherStore((store) => store.languageModalType);
const useMarketSwitcherActions = () => useMarketSwitcherStore((store) => store.actions);

export { useSelectedMarket, useLanguageModalType, useMarketSwitcherActions };
