import paymentsApi from 'services/paymentsApi';
import type { Order } from 'types/order';
import { api } from '../shared/api';
import { getAuthorizationHeader } from '../auth/utils';
import type {
  PaymentOption,
  AddPaymentMethodPayload,
  DeletePaymentMethodPayload,
  AddPaymentMethodResponse,
  DeletePaymentMethodResponse,
  MakePrimaryPaymentMethodResponse,
  CancelSubscriptionResponse,
  SubscriptionResponse,
  CreateSubscriptionPayload,
  CreateSubscriptionResponse,
  ReactivateSubscriptionResponse,
  AutoRefillSubscriptionResponse,
  AutoRefillSubscriptionPayload,
} from './types';

const PAYMENT_METHODS_TIMEOUT = 5_000;

const getPaymentMethods = async () => {
  const authHeader = getAuthorizationHeader();
  const { data } = await api.get<PaymentOption>('/purchases/payment-options', {
    headers: authHeader,
    timeout: PAYMENT_METHODS_TIMEOUT,
  });

  return data;
};

const addPaymentMethod = async (payload: AddPaymentMethodPayload) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await api.post<AddPaymentMethodResponse>('/purchases/payment-options', payload, {
    headers: authHeader,
  });

  return data;
};

const deletePaymentMethod = async (payload: DeletePaymentMethodPayload) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await api.delete<DeletePaymentMethodResponse>('/purchases/payment-options', {
    data: payload,
    headers: authHeader,
  });

  return data;
};

const makePaymentMethodPrimary = async (paymentMethodId: string) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await api.put<MakePrimaryPaymentMethodResponse>(
    '/user',
    { primaryPaymentMethodId: paymentMethodId },
    {
      headers: authHeader,
    },
  );

  return data;
};

const getOrders = async (orderId: string) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await api.get<Order>(`/user/orders/${orderId}`, {
    headers: authHeader,
  });

  return data;
};

const getSubscription = async () => {
  const authHeader = getAuthorizationHeader();
  const { data } = await paymentsApi.get<SubscriptionResponse>('/subscription', {
    headers: authHeader,
  });

  return data;
};

const cancelSubscription = async () => {
  const authHeader = getAuthorizationHeader();
  const { data } = await paymentsApi.delete<CancelSubscriptionResponse>('/subscription', {
    headers: authHeader,
  });

  return data;
};

const createSubscription = async (payload: CreateSubscriptionPayload) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await paymentsApi.post<CreateSubscriptionResponse>('/subscription', payload, {
    headers: authHeader,
  });

  return data;
};

const reactivateSubscription = async () => {
  const authHeader = getAuthorizationHeader();
  const { data } = await paymentsApi.post<ReactivateSubscriptionResponse>(
    '/subscription/reinstate',
    undefined,
    {
      headers: authHeader,
    },
  );

  return data;
};

const autoRefillSubscription = async (payload: AutoRefillSubscriptionPayload) => {
  const authHeader = getAuthorizationHeader();
  const { data } = await paymentsApi.post<AutoRefillSubscriptionResponse>(
    '/subscription/type',
    payload,
    { headers: authHeader },
  );

  return data;
};

export {
  getPaymentMethods,
  addPaymentMethod,
  deletePaymentMethod,
  makePaymentMethodPrimary,
  getOrders,
  getSubscription,
  createSubscription,
  cancelSubscription,
  reactivateSubscription,
  autoRefillSubscription,
};
