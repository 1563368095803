import { Text } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { getCountryName, type CountryCode } from '@carvertical/utils/country';
import Flag from 'components/common/Flag';

type CountryTagProps = {
  countryCode: string;
  language?: string;
  className?: string;
  flagClassName?: string;
  textClassName?: string;
};

const SuggestCountryTag = ({
  countryCode,
  language = 'en',
  className,
  flagClassName,
  textClassName,
}: CountryTagProps) => (
  <span className={cn('relative inline-flex items-center gap-1', className)}>
    {/* FIXME: Use `CountryFlag` from UI-lib once it's ready  */}
    {/* REASON:https://github.com/carVertical/frontend-monorepo/blob/master/packages/ui/src/components/widgets/index.ts#L4 */}
    {/* @ts-expect-error TS(TS2741) */}
    <Flag countryCode={countryCode} rounded size={16} className={flagClassName} />

    <Text className={textClassName} variant="s+">
      {getCountryName(countryCode.toUpperCase() as CountryCode, language)}
    </Text>
  </span>
);

export { SuggestCountryTag };
export type { CountryTagProps };
