import { theme } from '@carvertical/ui';
import { map, pickBy, startsWith } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { mountSecuredFieldsOn } from 'services/adyen';
import type { CardBrand } from '../types';

const HOLDER_NAME_DEBOUNCE_TIME = 500;

type UseMountAdyenCardFieldsProps = {
  fieldsRef: React.MutableRefObject<HTMLDivElement | null>;
};

const FIELD_STYLES = {
  base: {
    fontFamily: 'Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace',
    color: theme.colorGrey900,
  },
  placeholder: {
    color: theme.colorGrey400,
  },
  error: {
    color: theme.colorRed,
  },
};

const useMountAdyenCardFields = ({ fieldsRef }: UseMountAdyenCardFieldsProps) => {
  const [ready, setReady] = useState(false);
  const [cardBrand, setCardBrand] = useState<CardBrand | undefined>(undefined);
  const [holderNameShown, setholderNameShown] = useState(false);
  const [focusedField, setFocusedField] = useState<Record<string, string>>({});
  const { unregister, setValue: setFieldValue } = useFormContext();

  useDebounce(
    () => {
      if (cardBrand === 'visa') {
        setholderNameShown(true);
        return;
      }

      setholderNameShown(false);
    },
    HOLDER_NAME_DEBOUNCE_TIME,
    [cardBrand],
  );

  const setValue = useCallback(
    (name: string, value: string) =>
      setFieldValue(name, value, {
        shouldValidate: true,
        shouldDirty: true,
      }),
    [setFieldValue],
  );

  useEffect(() => {
    mountSecuredFieldsOn(fieldsRef.current, {
      type: 'card',
      autoFocus: false,
      hasHolderName: true,
      styles: FIELD_STYLES,
      maskSecurityCode: true,
      onConfigSuccess: () => setReady(true),
      onBrand: ({ brand }: { brand: CardBrand }) => {
        setCardBrand(brand);
        setValue('brand', brand);
      },
      onFocus: ({ focus, fieldType }: { focus: string; fieldType: string }) =>
        setFocusedField((prevState) => ({ ...prevState, [fieldType]: focus })),
      onChange: ({ data: { paymentMethod } }: { data: { paymentMethod: string } }) =>
        map(
          pickBy(paymentMethod, (_, key) => startsWith(key, 'encrypted')),
          (value, name) => {
            setValue(name, value);
            // Adyen splits expiry date into two values – month and year. And since
            // we’re displaying expiry date as a single field in the UI, for form
            // validation to work, we need to update its value when month value
            // changes:
            if (name === 'encryptedExpiryMonth') {
              setValue('encryptedExpiryDate', value);
            }
          },
        ),
      onFieldValid: ({ fieldType, valid }: { fieldType: string; valid?: boolean }) => {
        if (!valid) {
          setValue(fieldType, '');
        }
      },
      onError: ({ fieldType, error }: { fieldType: string; error?: boolean }) => {
        if (error) {
          setValue(fieldType, '');
        }
      },
    });

    return () => unregister('method');
  }, [fieldsRef, setValue, unregister]);

  return {
    ready,
    focusedField,
    holderNameShown,
    cardBrand,
  };
};

export { useMountAdyenCardFields };
export type { UseMountAdyenCardFieldsProps };
