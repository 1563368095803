import posthog from 'posthog-js';
import guid from 'guid';
import { parse as parseQuery } from 'querystringify';
import store, { createInstance } from './store';
import { sendMessage } from './reactNative';

const ACCESS_TOKEN = 'accessToken';
const EXPIRES_AT = 'expiresAt';
const USER_ID = 'userId';
const GUEST_ID = 'guestId';
const IMPERSONATED_USER_ID = 'impersonatedUserId';
const SESSION_ID = 'sessionId';
const ONE_DAY = 24 * 60 * 60;
const SESSION_DURATION_DAYS = 42;

const sessionStore = createInstance('session');

function setSession({ accessToken, impersonatedUserId, expiresInDays = SESSION_DURATION_DAYS }) {
  store.set(ACCESS_TOKEN, accessToken);
  store.set(EXPIRES_AT, new Date().getTime() + expiresInDays * ONE_DAY * 1000);
  if (impersonatedUserId) {
    store.set(IMPERSONATED_USER_ID, impersonatedUserId);
  } else {
    store.remove(IMPERSONATED_USER_ID);
  }

  sendMessage('accessToken', accessToken);
}

function initSession() {
  let { accessToken } = window;

  if (!accessToken) {
    ({ accessToken } = parseQuery(window.location.search));
  }

  if (accessToken === undefined) {
    return;
  }

  if (accessToken) {
    setSession({ accessToken });
  } else {
    clearSession();
  }
}

function clearSession() {
  store.remove(ACCESS_TOKEN);
  store.remove(EXPIRES_AT);
  store.remove(USER_ID);
  store.remove(IMPERSONATED_USER_ID);

  sendMessage('accessToken', null);
}

function hasSessionExpired() {
  const expiresAt = store.get(EXPIRES_AT);
  return new Date().getTime() > expiresAt;
}

function getAccessToken() {
  return store.get(ACCESS_TOKEN);
}

function getUserId() {
  return store.get(USER_ID);
}

function setUserId(userId) {
  return store.set(USER_ID, userId);
}

function getGuestId() {
  let guestId = store.get(GUEST_ID);

  if (!guestId) {
    guestId = guid.raw();
    store.set(GUEST_ID, guestId);
  }

  return guestId;
}

function getSessionId() {
  try {
    return posthog.get_session_id() || undefined;
  } catch (e) {
    return undefined;
  }
}

function getBrowsingSessionId() {
  let sessionId = sessionStore.get(SESSION_ID);

  if (!sessionId) {
    sessionId = guid.raw();
    sessionStore.set(SESSION_ID, sessionId);
  }

  return sessionId;
}

function isTemporaryUser() {
  const accessToken = getAccessToken();
  return guid.isGuid(accessToken);
}

function getImpersonatedUserId() {
  return store.get(IMPERSONATED_USER_ID);
}

export {
  setSession,
  initSession,
  clearSession,
  hasSessionExpired,
  getAccessToken,
  getUserId,
  setUserId,
  getGuestId,
  getSessionId,
  getBrowsingSessionId,
  isTemporaryUser,
  getImpersonatedUserId,
};
