import { Button, LinkButton, Modal, ModalTitle, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import { useEventTracking } from 'modules/analytics';
// FIXME: Dependency cycle https://carvertical.atlassian.net/browse/LAUNCH-2469
import { useModal } from 'modules/shared/hooks';
import { useRouteData } from 'context/RouteDataProvider';
import { useMarketSwitcher } from '../MarketSwitcher';
import { useMarket } from '../../hooks';
import { PreferredLanguageTags } from './PreferredLanguageTags';
import { SuggestLocationDescription } from './SuggestLocationDescription';
import { SuggestMarketSelect } from './SuggestMarketSelect';
import { useSuggestCorrectMarket } from './hooks';

const SuggestLocationModal = () => {
  const { t } = useTranslation(['geo', 'common']);
  const { track } = useEventTracking();
  const { market } = useRouteData();
  const { selectedMarket } = useMarketSwitcher();
  const { isModalOpen } = useModal('suggestLocation');
  const { confirmMarketSelection, suggestedMarket } = useSuggestCorrectMarket();
  const { languages, selectedSlug, setSelectedSlug } = usePreferredLanguage(
    selectedMarket?.countryCode || suggestedMarket?.countryCode,
  );

  const switchToMarket = () => {
    const suggestedCountryCode = suggestedMarket?.countryCode || 'eu';

    confirmMarketSelection();
    track({
      event: 'MarketChanged',
      currentMarket: market?.countryCode,
      suggestedMarket: suggestedCountryCode,
      confirmedMarket: selectedMarket?.countryCode || suggestedCountryCode,
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      track({ event: 'ModalOpened', modalName: 'suggestLocation' });
    }
  }, [isModalOpen, track]);

  return (
    <Modal
      initialFocus={-1}
      open={isModalOpen}
      closeButtonShown={false}
      overlayClassName="bg-black/20"
      boxClassName="h-auto rounded-l m-2 max-w-[592px]"
      contentClassName="px-3"
      footerClassName="px-3"
      footer={
        <div className="flex justify-end">
          <Button
            as={LinkButton}
            variant="black"
            title={t('common:general.confirmAction')}
            href={selectedSlug}
            size="m"
            onClick={switchToMarket}
            className="w-full no-underline transition hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-offset-2 sm:max-w-min"
          >
            {t('common:general.confirmAction')}
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-2">
        <ModalTitle className="text-l+">{t('suggestMarketModal.title')}</ModalTitle>

        <SuggestLocationDescription />

        <SuggestMarketSelect />

        <Text variant="s">{t('suggestMarketModal.chooseLanguage')}</Text>

        <PreferredLanguageTags
          languages={languages}
          selectedSlug={selectedSlug}
          onChange={setSelectedSlug}
        />
      </div>
    </Modal>
  );
};

function usePreferredLanguage(countryCode = 'eu') {
  const { getMarketLanguages } = useMarket();
  const languages = getMarketLanguages(countryCode);
  const memoizedSlug = useMemo(() => languages[0]?.slug, [languages]);
  const [selectedSlug, setSelectedSlug] = useState(languages[0]?.slug);

  useEffect(() => {
    if (memoizedSlug) {
      setSelectedSlug(memoizedSlug);
    }
  }, [memoizedSlug]);

  return { languages, selectedSlug, setSelectedSlug };
}

export { SuggestLocationModal };
