import { b as H, c as N, d as L, e as A, a as O, f as z } from "./Collection.2f43ceb9.js";
import { c as p, f as B, $ as U, e as x, g as I, h as W, i as D } from "./utils.ec96ba96.js";
import { $ as j } from "./Hidden.dae830d2.js";
import { d as K, f as k, i as F, h as q } from "./useFocusRing.ff9f944e.js";
import c, { useMemo as S, useRef as G, useEffect as J, forwardRef as M, useContext as h, createContext as R } from "react";
import { $ as Q } from "./useSingleSelectListState.a749d1f9.js";
import { e as X, f as C, d as Y, g as Z } from "./useListState.accd1c78.js";
import { $ as _ } from "./useHasTabbableChild.9dcc9a74.js";
const T = /* @__PURE__ */ new WeakMap();
function P(i, e, t) {
  return i ? (typeof e == "string" && (e = e.replace(/\s+/g, "")), `${T.get(i)}-${t}-${e}`) : "";
}
function ee(i, e, t) {
  let { key: a, isDisabled: s, shouldSelectOnPressUp: o } = i, { selectionManager: l, selectedKey: r } = e, d = a === r, n = s || e.isDisabled || e.selectionManager.isDisabled(a), { itemProps: f, isPressed: u } = X({
    selectionManager: l,
    key: a,
    ref: t,
    isDisabled: n,
    shouldSelectOnPressUp: o,
    linkBehavior: "selection"
  }), $ = P(e, a, "tab"), y = P(e, a, "tabpanel"), { tabIndex: m } = f, b = e.collection.getItem(a), E = K(b == null ? void 0 : b.props, {
    labelable: !0
  });
  delete E.id;
  let V = k(b == null ? void 0 : b.props);
  return {
    tabProps: p(E, V, f, {
      id: $,
      "aria-selected": d,
      "aria-disabled": n || void 0,
      "aria-controls": d ? y : void 0,
      tabIndex: n ? void 0 : m,
      role: "tab"
    }),
    isSelected: d,
    isDisabled: n,
    isPressed: u
  };
}
function te(i, e, t) {
  let a = _(t) ? void 0 : 0;
  var s;
  const o = P(e, (s = i.id) !== null && s !== void 0 ? s : e == null ? void 0 : e.selectedKey, "tabpanel"), l = C({
    ...i,
    id: o,
    "aria-labelledby": P(e, e == null ? void 0 : e.selectedKey, "tab")
  });
  return {
    tabPanelProps: p(l, {
      tabIndex: a,
      role: "tabpanel",
      "aria-describedby": i["aria-describedby"],
      "aria-details": i["aria-details"]
    })
  };
}
class ie {
  getKeyLeftOf(e) {
    return this.flipDirection ? this.getNextKey(e) : this.getPreviousKey(e);
  }
  getKeyRightOf(e) {
    return this.flipDirection ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  isDisabled(e) {
    var t, a;
    return this.disabledKeys.has(e) || !!(!((a = this.collection.getItem(e)) === null || a === void 0 || (t = a.props) === null || t === void 0) && t.isDisabled);
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    return e != null && this.isDisabled(e) && (e = this.getNextKey(e)), e;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    return e != null && this.isDisabled(e) && (e = this.getPreviousKey(e)), e;
  }
  getKeyAbove(e) {
    return this.tabDirection ? null : this.getPreviousKey(e);
  }
  getKeyBelow(e) {
    return this.tabDirection ? null : this.getNextKey(e);
  }
  getNextKey(e) {
    do
      e = this.collection.getKeyAfter(e), e == null && (e = this.collection.getFirstKey());
    while (this.isDisabled(e));
    return e;
  }
  getPreviousKey(e) {
    do
      e = this.collection.getKeyBefore(e), e == null && (e = this.collection.getLastKey());
    while (this.isDisabled(e));
    return e;
  }
  constructor(e, t, a, s = /* @__PURE__ */ new Set()) {
    this.collection = e, this.flipDirection = t === "rtl" && a === "horizontal", this.disabledKeys = s, this.tabDirection = a === "horizontal";
  }
}
function ae(i, e, t) {
  let { orientation: a = "horizontal", keyboardActivation: s = "automatic" } = i, { collection: o, selectionManager: l, disabledKeys: r } = e, { direction: d } = Y(), n = S(() => new ie(o, d, a, r), [
    o,
    r,
    a,
    d
  ]), { collectionProps: f } = Z({
    ref: t,
    selectionManager: l,
    keyboardDelegate: n,
    selectOnFocus: s === "automatic",
    disallowEmptySelection: !0,
    scrollRef: t,
    linkBehavior: "selection"
  }), u = B();
  T.set(e, u);
  let $ = C({
    ...i,
    id: u
  });
  return {
    tabListProps: {
      ...p(f, $),
      role: "tablist",
      "aria-orientation": a,
      tabIndex: void 0
    }
  };
}
function se(i) {
  var e, t;
  let a = Q({
    ...i,
    suppressTextValueWarning: !0,
    defaultSelectedKey: (t = (e = i.defaultSelectedKey) !== null && e !== void 0 ? e : w(i.collection, i.disabledKeys ? new Set(i.disabledKeys) : /* @__PURE__ */ new Set())) !== null && t !== void 0 ? t : void 0
  }), { selectionManager: s, collection: o, selectedKey: l } = a, r = G(l);
  return J(() => {
    let d = l;
    (s.isEmpty || d == null || !o.getItem(d)) && (d = w(o, a.disabledKeys), d != null && s.setSelectedKeys([
      d
    ])), (d != null && s.focusedKey == null || !s.isFocused && d !== r.current) && s.setFocusedKey(d), r.current = d;
  }), {
    ...a,
    isDisabled: i.isDisabled || !1
  };
}
function w(i, e) {
  let t = null;
  if (i) {
    var a, s, o, l;
    for (t = i.getFirstKey(); t != null && (e.has(t) || !((s = i.getItem(t)) === null || s === void 0 || (a = s.props) === null || a === void 0) && a.isDisabled) && t !== i.getLastKey(); )
      t = i.getKeyAfter(t);
    t != null && (e.has(t) || !((l = i.getItem(t)) === null || l === void 0 || (o = l.props) === null || o === void 0) && o.isDisabled) && t === i.getLastKey() && (t = i.getFirstKey());
  }
  return t;
}
const g = /* @__PURE__ */ R(null), v = /* @__PURE__ */ R(null), ve = /* @__PURE__ */ M(function(e, t) {
  [e, t] = U(e, t, g);
  let { children: a, orientation: s = "horizontal" } = e;
  return a = S(() => typeof a == "function" ? a({
    orientation: s,
    defaultChildren: null
  }) : a, [
    a,
    s
  ]), /* @__PURE__ */ c.createElement(H, {
    content: a
  }, (o) => /* @__PURE__ */ c.createElement(de, {
    props: e,
    collection: o,
    tabsRef: t
  }));
});
function de({ props: i, tabsRef: e, collection: t }) {
  let { orientation: a = "horizontal" } = i, s = se({
    ...i,
    collection: t,
    children: void 0
  }), { focusProps: o, isFocused: l, isFocusVisible: r } = F({
    within: !0
  }), d = S(() => ({
    orientation: a,
    isFocusWithin: l,
    isFocusVisible: r
  }), [
    a,
    l,
    r
  ]), n = x({
    ...i,
    defaultClassName: "react-aria-Tabs",
    values: d
  });
  return /* @__PURE__ */ c.createElement("div", {
    ...K(i),
    ...o,
    ...n,
    ref: e,
    slot: i.slot || void 0,
    "data-focused": l || void 0,
    "data-orientation": a,
    "data-focus-visible": r || void 0,
    "data-disabled": s.isDisabled || void 0
  }, /* @__PURE__ */ c.createElement(I, {
    values: [
      [
        g,
        i
      ],
      [
        v,
        s
      ]
    ]
  }, n.children));
}
const he = /* @__PURE__ */ M(function(e, t) {
  return h(v) ? /* @__PURE__ */ c.createElement(le, {
    props: e,
    forwardedRef: t
  }) : /* @__PURE__ */ c.createElement(N, e);
});
function le({ props: i, forwardedRef: e }) {
  let t = h(v), { CollectionRoot: a } = h(L), { orientation: s = "horizontal", keyboardActivation: o = "automatic" } = W(g), l = D(e), { tabListProps: r } = ae({
    ...i,
    orientation: s,
    keyboardActivation: o
  }, t, l), d = x({
    ...i,
    children: null,
    defaultClassName: "react-aria-TabList",
    values: {
      orientation: s,
      state: t
    }
  }), n = K(i);
  return delete n.id, /* @__PURE__ */ c.createElement("div", {
    ...n,
    ...r,
    ref: l,
    ...d,
    "data-orientation": s || void 0
  }, /* @__PURE__ */ c.createElement(a, {
    collection: t.collection,
    persistedKeys: A(t.selectionManager.focusedKey)
  }));
}
const pe = /* @__PURE__ */ O("item", (i, e, t) => {
  let a = h(v), s = D(e), { tabProps: o, isSelected: l, isDisabled: r, isPressed: d } = ee({
    key: t.key,
    ...i
  }, a, s), { focusProps: n, isFocused: f, isFocusVisible: u } = F(), { hoverProps: $, isHovered: y } = q({
    isDisabled: r,
    onHoverStart: i.onHoverStart,
    onHoverEnd: i.onHoverEnd,
    onHoverChange: i.onHoverChange
  }), m = x({
    ...i,
    id: void 0,
    defaultClassName: "react-aria-Tab",
    values: {
      isSelected: l,
      isDisabled: r,
      isFocused: f,
      isFocusVisible: u,
      isPressed: d,
      isHovered: y
    }
  }), b = t.props.href ? "a" : "div";
  return /* @__PURE__ */ c.createElement(b, {
    ...p(o, n, $, m),
    ref: s,
    "data-selected": l || void 0,
    "data-disabled": r || void 0,
    "data-focused": f || void 0,
    "data-focus-visible": u || void 0,
    "data-pressed": d || void 0,
    "data-hovered": y || void 0
  });
}), ye = /* @__PURE__ */ j(function(e, t) {
  const a = h(v);
  let s = D(t), { tabPanelProps: o } = te(e, a, s), { focusProps: l, isFocused: r, isFocusVisible: d } = F(), n = a.selectedKey === e.id, f = x({
    ...e,
    defaultClassName: "react-aria-TabPanel",
    values: {
      isFocused: r,
      isFocusVisible: d,
      isInert: !n,
      state: a
    }
  });
  if (!n && !e.shouldForceMount)
    return null;
  let u = K(e);
  delete u.id;
  let $ = n ? p(u, o, l, f) : f;
  return /* @__PURE__ */ c.createElement("div", {
    ...$,
    ref: s,
    "data-focused": r || void 0,
    "data-focus-visible": d || void 0,
    // @ts-ignore
    inert: n ? void 0 : "true",
    "data-inert": n ? void 0 : "true"
  }, /* @__PURE__ */ c.createElement(I, {
    values: [
      [
        g,
        null
      ],
      [
        v,
        null
      ]
    ]
  }, /* @__PURE__ */ c.createElement(L.Provider, {
    value: z
  }, f.children)));
});
export {
  ve as $,
  pe as a,
  he as b,
  ye as c
};
