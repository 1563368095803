import { a as Iu, b as zu } from "./index.72a67dcc.js";
import { b as Su, c as wu, d as J, e as Tu, a as Mu, f as Lu } from "./Collection.2f43ceb9.js";
import { f as Q, c as x, d as ju, j as Nu, $ as X, l as Ku, g as Y, e as _, i as Ru } from "./utils.ec96ba96.js";
import { $ as N, a as A, b as Vu, c as Ou } from "./useField.5a868857.js";
import { b as Uu, c as Wu, d as L, e as Hu } from "./ListBox.18f7c588.js";
import { w as ee, d as S, x as ue, a as g, m as Gu, j as qu, i as te, h as Zu } from "./useFocusRing.ff9f944e.js";
import D, { useRef as h, useEffect as T, useMemo as Ju, useState as Qu, forwardRef as le, useContext as M, createContext as oe } from "react";
import { j as ne, d as re, k as Xu, e as Yu, $ as W, l as y, b as _u } from "./useListState.accd1c78.js";
import { $ as et } from "./useHasTabbableChild.9dcc9a74.js";
function ut(e, u) {
  const t = h(!0), o = h(null);
  T(() => (t.current = !0, () => {
    t.current = !1;
  }), []), T(() => {
    t.current ? t.current = !1 : (!o.current || u.some((r, l) => !Object.is(r, o[l]))) && e(), o.current = u;
  }, u);
}
const K = /* @__PURE__ */ new WeakMap();
function H(e, u) {
  var t;
  let { id: o } = (t = K.get(e)) !== null && t !== void 0 ? t : {};
  if (!o)
    throw new Error("Unknown list");
  return `${o}-${tt(u)}`;
}
function tt(e) {
  return typeof e == "string" ? e.replace(/\s*/g, "") : "" + e;
}
var ie = {};
ie = {
  deselectedItem: (e) => `${e.item} غير المحدد`,
  longPressToSelect: "اضغط مطولًا للدخول إلى وضع التحديد.",
  select: "تحديد",
  selectedAll: "جميع العناصر المحددة.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "لم يتم تحديد عناصر",
    one: () => `${u.number(e.count)} عنصر محدد`,
    other: () => `${u.number(e.count)} عنصر محدد`
  })}.`,
  selectedItem: (e) => `${e.item} المحدد`
};
var ae = {};
ae = {
  deselectedItem: (e) => `${e.item} не е избран.`,
  longPressToSelect: "Натиснете и задръжте за да влезете в избирателен режим.",
  select: "Изберете",
  selectedAll: "Всички елементи са избрани.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Няма избрани елементи",
    one: () => `${u.number(e.count)} избран елемент`,
    other: () => `${u.number(e.count)} избрани елементи`
  })}.`,
  selectedItem: (e) => `${e.item} избран.`
};
var se = {};
se = {
  deselectedItem: (e) => `Položka ${e.item} není vybrána.`,
  longPressToSelect: "Dlouhým stisknutím přejdete do režimu výběru.",
  select: "Vybrat",
  selectedAll: "Vybrány všechny položky.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nevybrány žádné položky",
    one: () => `Vybrána ${u.number(e.count)} položka`,
    other: () => `Vybráno ${u.number(e.count)} položek`
  })}.`,
  selectedItem: (e) => `Vybrána položka ${e.item}.`
};
var ce = {};
ce = {
  deselectedItem: (e) => `${e.item} ikke valgt.`,
  longPressToSelect: "Lav et langt tryk for at aktivere valgtilstand.",
  select: "Vælg",
  selectedAll: "Alle elementer valgt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ingen elementer valgt",
    one: () => `${u.number(e.count)} element valgt`,
    other: () => `${u.number(e.count)} elementer valgt`
  })}.`,
  selectedItem: (e) => `${e.item} valgt.`
};
var de = {};
de = {
  deselectedItem: (e) => `${e.item} nicht ausgewählt.`,
  longPressToSelect: "Gedrückt halten, um Auswahlmodus zu öffnen.",
  select: "Auswählen",
  selectedAll: "Alle Elemente ausgewählt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Keine Elemente ausgewählt",
    one: () => `${u.number(e.count)} Element ausgewählt`,
    other: () => `${u.number(e.count)} Elemente ausgewählt`
  })}.`,
  selectedItem: (e) => `${e.item} ausgewählt.`
};
var $e = {};
$e = {
  deselectedItem: (e) => `Δεν επιλέχθηκε το στοιχείο ${e.item}.`,
  longPressToSelect: "Πατήστε παρατεταμένα για να μπείτε σε λειτουργία επιλογής.",
  select: "Επιλογή",
  selectedAll: "Επιλέχθηκαν όλα τα στοιχεία.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Δεν επιλέχθηκαν στοιχεία",
    one: () => `Επιλέχθηκε ${u.number(e.count)} στοιχείο`,
    other: () => `Επιλέχθηκαν ${u.number(e.count)} στοιχεία`
  })}.`,
  selectedItem: (e) => `Επιλέχθηκε το στοιχείο ${e.item}.`
};
var be = {};
be = {
  deselectedItem: (e) => `${e.item} not selected.`,
  select: "Select",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "No items selected",
    one: () => `${u.number(e.count)} item selected`,
    other: () => `${u.number(e.count)} items selected`
  })}.`,
  selectedAll: "All items selected.",
  selectedItem: (e) => `${e.item} selected.`,
  longPressToSelect: "Long press to enter selection mode."
};
var me = {};
me = {
  deselectedItem: (e) => `${e.item} no seleccionado.`,
  longPressToSelect: "Mantenga pulsado para abrir el modo de selección.",
  select: "Seleccionar",
  selectedAll: "Todos los elementos seleccionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ningún elemento seleccionado",
    one: () => `${u.number(e.count)} elemento seleccionado`,
    other: () => `${u.number(e.count)} elementos seleccionados`
  })}.`,
  selectedItem: (e) => `${e.item} seleccionado.`
};
var pe = {};
pe = {
  deselectedItem: (e) => `${e.item} pole valitud.`,
  longPressToSelect: "Valikurežiimi sisenemiseks vajutage pikalt.",
  select: "Vali",
  selectedAll: "Kõik üksused valitud.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Üksusi pole valitud",
    one: () => `${u.number(e.count)} üksus valitud`,
    other: () => `${u.number(e.count)} üksust valitud`
  })}.`,
  selectedItem: (e) => `${e.item} valitud.`
};
var ve = {};
ve = {
  deselectedItem: (e) => `Kohdetta ${e.item} ei valittu.`,
  longPressToSelect: "Siirry valintatilaan painamalla pitkään.",
  select: "Valitse",
  selectedAll: "Kaikki kohteet valittu.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ei yhtään kohdetta valittu",
    one: () => `${u.number(e.count)} kohde valittu`,
    other: () => `${u.number(e.count)} kohdetta valittu`
  })}.`,
  selectedItem: (e) => `${e.item} valittu.`
};
var fe = {};
fe = {
  deselectedItem: (e) => `${e.item} non sélectionné.`,
  longPressToSelect: "Appuyez de manière prolongée pour passer en mode de sélection.",
  select: "Sélectionner",
  selectedAll: "Tous les éléments sélectionnés.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Aucun élément sélectionné",
    one: () => `${u.number(e.count)} élément sélectionné`,
    other: () => `${u.number(e.count)} éléments sélectionnés`
  })}.`,
  selectedItem: (e) => `${e.item} sélectionné.`
};
var De = {};
De = {
  deselectedItem: (e) => `${e.item} לא נבחר.`,
  longPressToSelect: "הקשה ארוכה לכניסה למצב בחירה.",
  select: "בחר",
  selectedAll: "כל הפריטים נבחרו.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "לא נבחרו פריטים",
    one: () => `פריט ${u.number(e.count)} נבחר`,
    other: () => `${u.number(e.count)} פריטים נבחרו`
  })}.`,
  selectedItem: (e) => `${e.item} נבחר.`
};
var Be = {};
Be = {
  deselectedItem: (e) => `Stavka ${e.item} nije odabrana.`,
  longPressToSelect: "Dugo pritisnite za ulazak u način odabira.",
  select: "Odaberite",
  selectedAll: "Odabrane su sve stavke.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nije odabrana nijedna stavka",
    one: () => `Odabrana je ${u.number(e.count)} stavka`,
    other: () => `Odabrano je ${u.number(e.count)} stavki`
  })}.`,
  selectedItem: (e) => `Stavka ${e.item} je odabrana.`
};
var xe = {};
xe = {
  deselectedItem: (e) => `${e.item} nincs kijelölve.`,
  longPressToSelect: "Nyomja hosszan a kijelöléshez.",
  select: "Kijelölés",
  selectedAll: "Az összes elem kijelölve.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Egy elem sincs kijelölve",
    one: () => `${u.number(e.count)} elem kijelölve`,
    other: () => `${u.number(e.count)} elem kijelölve`
  })}.`,
  selectedItem: (e) => `${e.item} kijelölve.`
};
var Ce = {};
Ce = {
  deselectedItem: (e) => `${e.item} non selezionato.`,
  longPressToSelect: "Premi a lungo per passare alla modalità di selezione.",
  select: "Seleziona",
  selectedAll: "Tutti gli elementi selezionati.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nessun elemento selezionato",
    one: () => `${u.number(e.count)} elemento selezionato`,
    other: () => `${u.number(e.count)} elementi selezionati`
  })}.`,
  selectedItem: (e) => `${e.item} selezionato.`
};
var Ee = {};
Ee = {
  deselectedItem: (e) => `${e.item} が選択されていません。`,
  longPressToSelect: "長押しして選択モードを開きます。",
  select: "選択",
  selectedAll: "すべての項目を選択しました。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "項目が選択されていません",
    one: () => `${u.number(e.count)} 項目を選択しました`,
    other: () => `${u.number(e.count)} 項目を選択しました`
  })}。`,
  selectedItem: (e) => `${e.item} を選択しました。`
};
var ke = {};
ke = {
  deselectedItem: (e) => `${e.item}이(가) 선택되지 않았습니다.`,
  longPressToSelect: "선택 모드로 들어가려면 길게 누르십시오.",
  select: "선택",
  selectedAll: "모든 항목이 선택되었습니다.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "선택된 항목이 없습니다",
    one: () => `${u.number(e.count)}개 항목이 선택되었습니다`,
    other: () => `${u.number(e.count)}개 항목이 선택되었습니다`
  })}.`,
  selectedItem: (e) => `${e.item}이(가) 선택되었습니다.`
};
var ge = {};
ge = {
  deselectedItem: (e) => `${e.item} nepasirinkta.`,
  longPressToSelect: "Norėdami įjungti pasirinkimo režimą, paspauskite ir palaikykite.",
  select: "Pasirinkti",
  selectedAll: "Pasirinkti visi elementai.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nepasirinktas nė vienas elementas",
    one: () => `Pasirinktas ${u.number(e.count)} elementas`,
    other: () => `Pasirinkta elementų: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Pasirinkta: ${e.item}.`
};
var Ae = {};
Ae = {
  deselectedItem: (e) => `Vienums ${e.item} nav atlasīts.`,
  longPressToSelect: "Ilgi turiet nospiestu. lai ieslēgtu atlases režīmu.",
  select: "Atlasīt",
  selectedAll: "Atlasīti visi vienumi.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nav atlasīts neviens vienums",
    one: () => `Atlasīto vienumu skaits: ${u.number(e.count)}`,
    other: () => `Atlasīto vienumu skaits: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Atlasīts vienums ${e.item}.`
};
var ye = {};
ye = {
  deselectedItem: (e) => `${e.item} er ikke valgt.`,
  longPressToSelect: "Bruk et langt trykk for å gå inn i valgmodus.",
  select: "Velg",
  selectedAll: "Alle elementer er valgt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Ingen elementer er valgt",
    one: () => `${u.number(e.count)} element er valgt`,
    other: () => `${u.number(e.count)} elementer er valgt`
  })}.`,
  selectedItem: (e) => `${e.item} er valgt.`
};
var he = {};
he = {
  deselectedItem: (e) => `${e.item} niet geselecteerd.`,
  longPressToSelect: "Druk lang om de selectiemodus te openen.",
  select: "Selecteren",
  selectedAll: "Alle items geselecteerd.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Geen items geselecteerd",
    one: () => `${u.number(e.count)} item geselecteerd`,
    other: () => `${u.number(e.count)} items geselecteerd`
  })}.`,
  selectedItem: (e) => `${e.item} geselecteerd.`
};
var Pe = {};
Pe = {
  deselectedItem: (e) => `Nie zaznaczono ${e.item}.`,
  longPressToSelect: "Naciśnij i przytrzymaj, aby wejść do trybu wyboru.",
  select: "Zaznacz",
  selectedAll: "Wszystkie zaznaczone elementy.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nie zaznaczono żadnych elementów",
    one: () => `${u.number(e.count)} zaznaczony element`,
    other: () => `${u.number(e.count)} zaznaczonych elementów`
  })}.`,
  selectedItem: (e) => `Zaznaczono ${e.item}.`
};
var Fe = {};
Fe = {
  deselectedItem: (e) => `${e.item} não selecionado.`,
  longPressToSelect: "Mantenha pressionado para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${u.number(e.count)} item selecionado`,
    other: () => `${u.number(e.count)} itens selecionados`
  })}.`,
  selectedItem: (e) => `${e.item} selecionado.`
};
var Ie = {};
Ie = {
  deselectedItem: (e) => `${e.item} não selecionado.`,
  longPressToSelect: "Prima continuamente para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${u.number(e.count)} item selecionado`,
    other: () => `${u.number(e.count)} itens selecionados`
  })}.`,
  selectedItem: (e) => `${e.item} selecionado.`
};
var ze = {};
ze = {
  deselectedItem: (e) => `${e.item} neselectat.`,
  longPressToSelect: "Apăsați lung pentru a intra în modul de selectare.",
  select: "Selectare",
  selectedAll: "Toate elementele selectate.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Niciun element selectat",
    one: () => `${u.number(e.count)} element selectat`,
    other: () => `${u.number(e.count)} elemente selectate`
  })}.`,
  selectedItem: (e) => `${e.item} selectat.`
};
var Se = {};
Se = {
  deselectedItem: (e) => `${e.item} не выбрано.`,
  longPressToSelect: "Нажмите и удерживайте для входа в режим выбора.",
  select: "Выбрать",
  selectedAll: "Выбраны все элементы.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Нет выбранных элементов",
    one: () => `${u.number(e.count)} элемент выбран`,
    other: () => `${u.number(e.count)} элементов выбрано`
  })}.`,
  selectedItem: (e) => `${e.item} выбрано.`
};
var we = {};
we = {
  deselectedItem: (e) => `Nevybraté položky: ${e.item}.`,
  longPressToSelect: "Dlhším stlačením prejdite do režimu výberu.",
  select: "Vybrať",
  selectedAll: "Všetky vybraté položky.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Žiadne vybraté položky",
    one: () => `${u.number(e.count)} vybratá položka`,
    other: () => `Počet vybratých položiek:${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `Vybraté položky: ${e.item}.`
};
var Te = {};
Te = {
  deselectedItem: (e) => `Element ${e.item} ni izbran.`,
  longPressToSelect: "Za izbirni način pritisnite in dlje časa držite.",
  select: "Izberite",
  selectedAll: "Vsi elementi so izbrani.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Noben element ni izbran",
    one: () => `${u.number(e.count)} element je izbran`,
    other: () => `${u.number(e.count)} elementov je izbranih`
  })}.`,
  selectedItem: (e) => `Element ${e.item} je izbran.`
};
var Me = {};
Me = {
  deselectedItem: (e) => `${e.item} nije izabrano.`,
  longPressToSelect: "Dugo pritisnite za ulazak u režim biranja.",
  select: "Izaberite",
  selectedAll: "Izabrane su sve stavke.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Nije izabrana nijedna stavka",
    one: () => `Izabrana je ${u.number(e.count)} stavka`,
    other: () => `Izabrano je ${u.number(e.count)} stavki`
  })}.`,
  selectedItem: (e) => `${e.item} je izabrano.`
};
var Le = {};
Le = {
  deselectedItem: (e) => `${e.item} ej markerat.`,
  longPressToSelect: "Tryck länge när du vill öppna väljarläge.",
  select: "Markera",
  selectedAll: "Alla markerade objekt.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Inga markerade objekt",
    one: () => `${u.number(e.count)} markerat objekt`,
    other: () => `${u.number(e.count)} markerade objekt`
  })}.`,
  selectedItem: (e) => `${e.item} markerat.`
};
var je = {};
je = {
  deselectedItem: (e) => `${e.item} seçilmedi.`,
  longPressToSelect: "Seçim moduna girmek için uzun basın.",
  select: "Seç",
  selectedAll: "Tüm ögeler seçildi.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Hiçbir öge seçilmedi",
    one: () => `${u.number(e.count)} öge seçildi`,
    other: () => `${u.number(e.count)} öge seçildi`
  })}.`,
  selectedItem: (e) => `${e.item} seçildi.`
};
var Ne = {};
Ne = {
  deselectedItem: (e) => `${e.item} не вибрано.`,
  longPressToSelect: "Виконайте довге натиснення, щоб перейти в режим вибору.",
  select: "Вибрати",
  selectedAll: "Усі елементи вибрано.",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "Жодних елементів не вибрано",
    one: () => `${u.number(e.count)} елемент вибрано`,
    other: () => `Вибрано елементів: ${u.number(e.count)}`
  })}.`,
  selectedItem: (e) => `${e.item} вибрано.`
};
var Ke = {};
Ke = {
  deselectedItem: (e) => `未选择 ${e.item}。`,
  longPressToSelect: "长按以进入选择模式。",
  select: "选择",
  selectedAll: "已选择所有项目。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "未选择项目",
    one: () => `已选择 ${u.number(e.count)} 个项目`,
    other: () => `已选择 ${u.number(e.count)} 个项目`
  })}。`,
  selectedItem: (e) => `已选择 ${e.item}。`
};
var Re = {};
Re = {
  deselectedItem: (e) => `未選取「${e.item}」。`,
  longPressToSelect: "長按以進入選擇模式。",
  select: "選取",
  selectedAll: "已選取所有項目。",
  selectedCount: (e, u) => `${u.plural(e.count, {
    "=0": "未選取任何項目",
    one: () => `已選取 ${u.number(e.count)} 個項目`,
    other: () => `已選取 ${u.number(e.count)} 個項目`
  })}。`,
  selectedItem: (e) => `已選取「${e.item}」。`
};
var R = {};
R = {
  "ar-AE": ie,
  "bg-BG": ae,
  "cs-CZ": se,
  "da-DK": ce,
  "de-DE": de,
  "el-GR": $e,
  "en-US": be,
  "es-ES": me,
  "et-EE": pe,
  "fi-FI": ve,
  "fr-FR": fe,
  "he-IL": De,
  "hr-HR": Be,
  "hu-HU": xe,
  "it-IT": Ce,
  "ja-JP": Ee,
  "ko-KR": ke,
  "lt-LT": ge,
  "lv-LV": Ae,
  "nb-NO": ye,
  "nl-NL": he,
  "pl-PL": Pe,
  "pt-BR": Fe,
  "pt-PT": Ie,
  "ro-RO": ze,
  "ru-RU": Se,
  "sk-SK": we,
  "sl-SI": Te,
  "sr-SP": Me,
  "sv-SE": Le,
  "tr-TR": je,
  "uk-UA": Ne,
  "zh-CN": Ke,
  "zh-TW": Re
};
function lt(e) {
  return e && e.__esModule ? e.default : e;
}
function ot(e, u) {
  let { getRowText: t = (n) => {
    var c, s, d, i;
    return (i = (c = (s = u.collection).getTextValue) === null || c === void 0 ? void 0 : c.call(s, n)) !== null && i !== void 0 ? i : (d = u.collection.getItem(n)) === null || d === void 0 ? void 0 : d.textValue;
  } } = e, o = N(lt(R), "@react-aria/grid"), r = u.selectionManager.rawSelection, l = h(r);
  ut(() => {
    var n;
    if (!u.selectionManager.isFocused) {
      l.current = r;
      return;
    }
    let c = G(r, l.current), s = G(l.current, r), d = u.selectionManager.selectionBehavior === "replace", i = [];
    if (u.selectionManager.selectedKeys.size === 1 && d) {
      if (u.collection.getItem(u.selectionManager.selectedKeys.keys().next().value)) {
        let $ = t(u.selectionManager.selectedKeys.keys().next().value);
        $ && i.push(o.format("selectedItem", {
          item: $
        }));
      }
    } else if (c.size === 1 && s.size === 0) {
      let $ = t(c.keys().next().value);
      $ && i.push(o.format("selectedItem", {
        item: $
      }));
    } else if (s.size === 1 && c.size === 0 && u.collection.getItem(s.keys().next().value)) {
      let $ = t(s.keys().next().value);
      $ && i.push(o.format("deselectedItem", {
        item: $
      }));
    }
    u.selectionManager.selectionMode === "multiple" && (i.length === 0 || r === "all" || r.size > 1 || l.current === "all" || ((n = l.current) === null || n === void 0 ? void 0 : n.size) > 1) && i.push(r === "all" ? o.format("selectedAll") : o.format("selectedCount", {
      count: r.size
    })), i.length > 0 && Iu(i.join(" ")), l.current = r;
  }, [
    r
  ]);
}
function G(e, u) {
  let t = /* @__PURE__ */ new Set();
  if (e === "all" || u === "all")
    return t;
  for (let o of e.keys())
    u.has(o) || t.add(o);
  return t;
}
function nt(e) {
  return e && e.__esModule ? e.default : e;
}
function rt(e) {
  let u = N(nt(R), "@react-aria/grid"), t = ee(), o = (t === "pointer" || t === "virtual" || t == null) && typeof window < "u" && "ontouchstart" in window, r = Ju(() => {
    let n = e.selectionManager.selectionMode, c = e.selectionManager.selectionBehavior, s;
    return o && (s = u.format("longPressToSelect")), c === "replace" && n !== "none" && e.hasItemActions ? s : void 0;
  }, [
    e.selectionManager.selectionMode,
    e.selectionManager.selectionBehavior,
    e.hasItemActions,
    u,
    o
  ]);
  return ne(r);
}
function it(e, u, t) {
  let { isVirtualized: o, keyboardDelegate: r, layoutDelegate: l, onAction: n, linkBehavior: c = "action", keyboardNavigationBehavior: s = "arrow" } = e;
  !e["aria-label"] && !e["aria-labelledby"] && console.warn("An aria-label or aria-labelledby prop is required for accessibility.");
  let { listProps: d } = Uu({
    selectionManager: u.selectionManager,
    collection: u.collection,
    disabledKeys: u.disabledKeys,
    ref: t,
    keyboardDelegate: r,
    layoutDelegate: l,
    isVirtualized: o,
    selectOnFocus: u.selectionManager.selectionBehavior === "replace",
    shouldFocusWrap: e.shouldFocusWrap,
    linkBehavior: c
  }), i = Q(e.id);
  K.set(u, {
    id: i,
    onAction: n,
    linkBehavior: c,
    keyboardNavigationBehavior: s
  });
  let $ = rt({
    selectionManager: u.selectionManager,
    hasItemActions: !!n
  }), b = et(t, {
    isDisabled: u.collection.size !== 0
  }), m = S(e, {
    labelable: !0
  }), v = x(
    m,
    {
      role: "grid",
      id: i,
      "aria-multiselectable": u.selectionManager.selectionMode === "multiple" ? "true" : void 0
    },
    // If collection is empty, make sure the grid is tabbable unless there is a child tabbable element.
    u.collection.size === 0 ? {
      tabIndex: b ? -1 : 0
    } : d,
    $
  );
  return o && (v["aria-rowcount"] = u.collection.size, v["aria-colcount"] = 1), ot({}, u), {
    gridProps: v
  };
}
const q = {
  expand: {
    ltr: "ArrowRight",
    rtl: "ArrowLeft"
  },
  collapse: {
    ltr: "ArrowLeft",
    rtl: "ArrowRight"
  }
};
function at(e, u, t) {
  var o, r;
  let { node: l, isVirtualized: n, shouldSelectOnPressUp: c } = e, { direction: s } = re(), { onAction: d, linkBehavior: i, keyboardNavigationBehavior: $ } = K.get(u), b = ju(), m = h(null), v = () => {
    var a;
    t.current !== null && (m.current != null && l.key !== m.current || !(!((a = t.current) === null || a === void 0) && a.contains(document.activeElement))) && g(t.current);
  }, w = {}, C, j = u.selectionManager.isLink(l.key);
  if (l != null && "expandedKeys" in u) {
    var P, F, I;
    let a = (P = (F = u.collection).getChildren) === null || P === void 0 ? void 0 : P.call(F, l.key);
    C = [
      ...a ?? []
    ].length > 1, d == null && !j && u.selectionManager.selectionMode === "none" && C && (d = () => u.toggleKey(l.key));
    let f = C ? u.expandedKeys.has(l.key) : void 0, k = 1;
    var z;
    if (l.level > 0 && (l == null ? void 0 : l.parentKey) != null) {
      let p = u.collection.getItem(l.parentKey);
      if (p) {
        var E, V;
        let B = (E = (V = u.collection).getChildren) === null || E === void 0 ? void 0 : E.call(V, p.key);
        k = Xu(B).index + 1;
      }
    } else
      k = ((z = (I = [
        ...u.collection
      ].filter((p) => p.level === 0).at(-1)) === null || I === void 0 ? void 0 : I.index) !== null && z !== void 0 ? z : 0) + 1;
    w = {
      "aria-expanded": f,
      "aria-level": l.level + 1,
      "aria-posinset": (l == null ? void 0 : l.index) + 1,
      "aria-setsize": k
    };
  }
  let { itemProps: gu, ...O } = Yu({
    selectionManager: u.selectionManager,
    key: l.key,
    ref: t,
    isVirtualized: n,
    shouldSelectOnPressUp: c,
    onAction: d || !((o = l.props) === null || o === void 0) && o.onAction ? Nu((r = l.props) === null || r === void 0 ? void 0 : r.onAction, d ? () => d(l.key) : void 0) : void 0,
    focus: v,
    linkBehavior: i
  }), Au = (a) => {
    if (!a.currentTarget.contains(a.target) || !t.current || !document.activeElement)
      return;
    let f = W(t.current);
    if (f.currentNode = document.activeElement, "expandedKeys" in u && document.activeElement === t.current) {
      if (a.key === q.expand[s] && u.selectionManager.focusedKey === l.key && C && !u.expandedKeys.has(l.key)) {
        u.toggleKey(l.key), a.stopPropagation();
        return;
      } else if (a.key === q.collapse[s] && u.selectionManager.focusedKey === l.key && C && u.expandedKeys.has(l.key)) {
        u.toggleKey(l.key), a.stopPropagation();
        return;
      }
    }
    switch (a.key) {
      case "ArrowLeft":
        if ($ === "arrow") {
          let p = s === "rtl" ? f.nextNode() : f.previousNode();
          if (p)
            a.preventDefault(), a.stopPropagation(), g(p), y(p, {
              containingElement: A(t.current)
            });
          else if (a.preventDefault(), a.stopPropagation(), s === "rtl")
            g(t.current), y(t.current, {
              containingElement: A(t.current)
            });
          else {
            f.currentNode = t.current;
            let B = Z(f);
            B && (g(B), y(B, {
              containingElement: A(t.current)
            }));
          }
        }
        break;
      case "ArrowRight":
        if ($ === "arrow") {
          let p = s === "rtl" ? f.previousNode() : f.nextNode();
          if (p)
            a.preventDefault(), a.stopPropagation(), g(p), y(p, {
              containingElement: A(t.current)
            });
          else if (a.preventDefault(), a.stopPropagation(), s === "ltr")
            g(t.current), y(t.current, {
              containingElement: A(t.current)
            });
          else {
            f.currentNode = t.current;
            let B = Z(f);
            B && (g(B), y(B, {
              containingElement: A(t.current)
            }));
          }
        }
        break;
      case "ArrowUp":
      case "ArrowDown":
        if (!a.altKey && t.current.contains(a.target)) {
          var k;
          a.stopPropagation(), a.preventDefault(), (k = t.current.parentElement) === null || k === void 0 || k.dispatchEvent(new KeyboardEvent(a.nativeEvent.type, a.nativeEvent));
        }
        break;
      case "Tab":
        if ($ === "tab") {
          let p = W(t.current, {
            tabbable: !0
          });
          p.currentNode = document.activeElement, (a.shiftKey ? p.previousNode() : p.nextNode()) && a.stopPropagation();
        }
    }
  }, yu = (a) => {
    if (m.current = l.key, a.target !== t.current) {
      Gu() || u.selectionManager.setFocusedKey(l.key);
      return;
    }
  }, hu = ue(l.props), Pu = O.hasAction ? hu : {}, U = x(gu, Pu, {
    role: "row",
    onKeyDownCapture: Au,
    onFocus: yu,
    // 'aria-label': [(node.textValue || undefined), rowAnnouncement].filter(Boolean).join(', '),
    "aria-label": l.textValue || void 0,
    "aria-selected": u.selectionManager.canSelectItem(l.key) ? u.selectionManager.isSelected(l.key) : void 0,
    "aria-disabled": u.selectionManager.isDisabled(l.key) || void 0,
    "aria-labelledby": b && l.textValue ? `${H(u, l.key)} ${b}` : void 0,
    id: H(u, l.key)
  });
  n && (U["aria-rowindex"] = l.index + 1);
  let Fu = {
    role: "gridcell",
    "aria-colindex": 1
  };
  return {
    rowProps: {
      ...x(U, w)
    },
    gridCellProps: Fu,
    descriptionProps: {
      id: b
    },
    ...O
  };
}
function Z(e) {
  let u = null, t = null;
  do
    t = e.lastChild(), t && (u = t);
  while (t);
  return u;
}
const Ve = /* @__PURE__ */ new WeakMap();
function st(e, u, t) {
  let { direction: o } = re(), r = e.keyboardDelegate || new Wu({
    collection: u.collection,
    ref: t,
    orientation: "horizontal",
    direction: o,
    disabledKeys: u.disabledKeys,
    disabledBehavior: u.selectionManager.disabledBehavior
  }), { labelProps: l, fieldProps: n, descriptionProps: c, errorMessageProps: s } = Vu({
    ...e,
    labelElementType: "span"
  }), { gridProps: d } = it({
    ...e,
    ...n,
    keyboardDelegate: r,
    shouldFocusWrap: !0,
    linkBehavior: "override"
  }, u, t), [i, $] = Qu(!1), { focusWithinProps: b } = qu({
    onFocusWithinChange: $
  }), m = S(e), v = h(u.collection.size);
  return T(() => {
    t.current && v.current > 0 && u.collection.size === 0 && i && t.current.focus(), v.current = u.collection.size;
  }, [
    u.collection.size,
    i,
    t
  ]), Ve.set(u, {
    onRemove: e.onRemove
  }), {
    gridProps: x(d, m, {
      role: u.collection.size ? "grid" : null,
      "aria-atomic": !1,
      "aria-relevant": "additions",
      "aria-live": i ? "polite" : "off",
      ...b,
      ...n
    }),
    labelProps: l,
    descriptionProps: c,
    errorMessageProps: s
  };
}
var Oe = {};
Oe = {
  removeButtonLabel: "إزالة",
  removeDescription: "اضغط على مفتاح DELETE لإزالة علامة."
};
var Ue = {};
Ue = {
  removeButtonLabel: "Премахване",
  removeDescription: "Натиснете Delete, за да премахнете маркера."
};
var We = {};
We = {
  removeButtonLabel: "Odebrat",
  removeDescription: "Stisknutím klávesy Delete odeberete značku."
};
var He = {};
He = {
  removeButtonLabel: "Fjern",
  removeDescription: "Tryk på Slet for at fjerne tag."
};
var Ge = {};
Ge = {
  removeButtonLabel: "Entfernen",
  removeDescription: "Auf „Löschen“ drücken, um das Tag zu entfernen."
};
var qe = {};
qe = {
  removeButtonLabel: "Κατάργηση",
  removeDescription: "Πατήστε Διαγραφή για να καταργήσετε την ετικέτα."
};
var Ze = {};
Ze = {
  removeDescription: "Press Delete to remove tag.",
  removeButtonLabel: "Remove"
};
var Je = {};
Je = {
  removeButtonLabel: "Quitar",
  removeDescription: "Pulse Eliminar para quitar la etiqueta."
};
var Qe = {};
Qe = {
  removeButtonLabel: "Eemalda",
  removeDescription: "Sildi eemaldamiseks vajutage kustutusklahvi Delete."
};
var Xe = {};
Xe = {
  removeButtonLabel: "Poista",
  removeDescription: "Poista tunniste painamalla Poista-painiketta."
};
var Ye = {};
Ye = {
  removeButtonLabel: "Supprimer",
  removeDescription: "Appuyez sur Supprimer pour supprimer l’étiquette."
};
var _e = {};
_e = {
  removeButtonLabel: "הסר",
  removeDescription: "לחץ על מחק כדי להסיר תג."
};
var eu = {};
eu = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Delete za uklanjanje oznake."
};
var uu = {};
uu = {
  removeButtonLabel: "Eltávolítás",
  removeDescription: "Nyomja meg a Delete billentyűt a címke eltávolításához."
};
var tu = {};
tu = {
  removeButtonLabel: "Rimuovi",
  removeDescription: "Premi Elimina per rimuovere il tag."
};
var lu = {};
lu = {
  removeButtonLabel: "削除",
  removeDescription: "タグを削除するには、Delete キーを押します。"
};
var ou = {};
ou = {
  removeButtonLabel: "제거",
  removeDescription: "태그를 제거하려면 Delete 키를 누르십시오."
};
var nu = {};
nu = {
  removeButtonLabel: "Pašalinti",
  removeDescription: "Norėdami pašalinti žymą, paspauskite „Delete“ klavišą."
};
var ru = {};
ru = {
  removeButtonLabel: "Noņemt",
  removeDescription: "Nospiediet Delete [Dzēst], lai noņemtu tagu."
};
var iu = {};
iu = {
  removeButtonLabel: "Fjern",
  removeDescription: "Trykk på Slett for å fjerne taggen."
};
var au = {};
au = {
  removeButtonLabel: "Verwijderen",
  removeDescription: "Druk op Verwijderen om de tag te verwijderen."
};
var su = {};
su = {
  removeButtonLabel: "Usuń",
  removeDescription: "Naciśnij Usuń, aby usunąć znacznik."
};
var cu = {};
cu = {
  removeButtonLabel: "Remover",
  removeDescription: "Pressione Delete para remover a tag."
};
var du = {};
du = {
  removeButtonLabel: "Eliminar",
  removeDescription: "Prima Delete para eliminar a tag."
};
var $u = {};
$u = {
  removeButtonLabel: "Îndepărtaţi",
  removeDescription: "Apăsați pe Delete (Ștergere) pentru a elimina eticheta."
};
var bu = {};
bu = {
  removeButtonLabel: "Удалить",
  removeDescription: "Нажмите DELETE, чтобы удалить тег."
};
var mu = {};
mu = {
  removeButtonLabel: "Odstrániť",
  removeDescription: "Ak chcete odstrániť značku, stlačte kláves Delete."
};
var pu = {};
pu = {
  removeButtonLabel: "Odstrani",
  removeDescription: "Pritisnite Delete, da odstranite oznako."
};
var vu = {};
vu = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Obriši da biste uklonili oznaku."
};
var fu = {};
fu = {
  removeButtonLabel: "Ta bort",
  removeDescription: "Tryck på Radera för att ta bort taggen."
};
var Du = {};
Du = {
  removeButtonLabel: "Kaldır",
  removeDescription: "Etiketi kaldırmak için Sil tuşuna basın."
};
var Bu = {};
Bu = {
  removeButtonLabel: "Вилучити",
  removeDescription: "Натисніть Delete, щоб вилучити тег."
};
var xu = {};
xu = {
  removeButtonLabel: "删除",
  removeDescription: "按下“删除”以删除标记。"
};
var Cu = {};
Cu = {
  removeButtonLabel: "移除",
  removeDescription: "按 Delete 鍵以移除標記。"
};
var Eu = {};
Eu = {
  "ar-AE": Oe,
  "bg-BG": Ue,
  "cs-CZ": We,
  "da-DK": He,
  "de-DE": Ge,
  "el-GR": qe,
  "en-US": Ze,
  "es-ES": Je,
  "et-EE": Qe,
  "fi-FI": Xe,
  "fr-FR": Ye,
  "he-IL": _e,
  "hr-HR": eu,
  "hu-HU": uu,
  "it-IT": tu,
  "ja-JP": lu,
  "ko-KR": ou,
  "lt-LT": nu,
  "lv-LV": ru,
  "nb-NO": iu,
  "nl-NL": au,
  "pl-PL": su,
  "pt-BR": cu,
  "pt-PT": du,
  "ro-RO": $u,
  "ru-RU": bu,
  "sk-SK": mu,
  "sl-SI": pu,
  "sr-SP": vu,
  "sv-SE": fu,
  "tr-TR": Du,
  "uk-UA": Bu,
  "zh-CN": xu,
  "zh-TW": Cu
};
function ct(e) {
  return e && e.__esModule ? e.default : e;
}
function dt(e, u, t) {
  let { item: o } = e, r = N(ct(Eu), "@react-aria/tag"), l = Q(), { onRemove: n } = Ve.get(u) || {}, { rowProps: c, gridCellProps: s, ...d } = at({
    node: o
  }, u, t);
  delete c.onKeyDownCapture;
  let { descriptionProps: i, ...$ } = d, b = u.disabledKeys.has(o.key) || o.props.isDisabled, m = (E) => {
    if (E.key === "Delete" || E.key === "Backspace") {
      if (b)
        return;
      E.preventDefault(), u.selectionManager.isSelected(o.key) ? n == null || n(new Set(u.selectionManager.selectedKeys)) : n == null || n(/* @__PURE__ */ new Set([
        o.key
      ]));
    }
  }, v = ee();
  v === "virtual" && typeof window < "u" && "ontouchstart" in window && (v = "pointer");
  let w = n && (v === "keyboard" || v === "virtual") ? r.format("removeDescription") : "", C = ne(w), j = o.key === u.selectionManager.focusedKey, P = u.selectionManager.focusedKey != null, F = -1;
  !b && (j || !P) && (F = 0);
  let I = S(o.props), z = ue(o.props);
  return {
    removeButtonProps: {
      "aria-label": r.format("removeButtonLabel"),
      "aria-labelledby": `${l} ${c.id}`,
      isDisabled: b,
      id: l,
      onPress: () => n ? n(/* @__PURE__ */ new Set([
        o.key
      ])) : null,
      excludeFromTabOrder: !0
    },
    rowProps: x(c, I, z, {
      tabIndex: F,
      onKeyDown: n ? m : void 0,
      "aria-describedby": C["aria-describedby"]
    }),
    gridCellProps: x(s, {
      "aria-errormessage": e["aria-errormessage"],
      "aria-label": e["aria-label"]
    }),
    ...$,
    allowsRemoving: !!n
  };
}
const $t = /* @__PURE__ */ oe(null), ku = /* @__PURE__ */ oe(null), gt = /* @__PURE__ */ le(function(u, t) {
  return [u, t] = X(u, t, $t), /* @__PURE__ */ D.createElement(Su, {
    content: u.children
  }, (o) => /* @__PURE__ */ D.createElement(bt, {
    props: u,
    forwardedRef: t,
    collection: o
  }));
});
function bt({ props: e, forwardedRef: u, collection: t }) {
  let o = h(null), [r, l] = Ku(), n = _u({
    ...e,
    children: void 0,
    collection: t
  }), c = S(e), s = Object.fromEntries(Object.entries(c).map(([v]) => [
    v,
    void 0
  ])), { gridProps: d, labelProps: i, descriptionProps: $, errorMessageProps: b } = st({
    ...e,
    ...s,
    label: l
  }, n, o);
  var m;
  return /* @__PURE__ */ D.createElement("div", {
    ...c,
    ref: u,
    slot: e.slot || void 0,
    className: (m = e.className) !== null && m !== void 0 ? m : "react-aria-TagGroup",
    style: e.style
  }, /* @__PURE__ */ D.createElement(Y, {
    values: [
      [
        Ou,
        {
          ...i,
          elementType: "span",
          ref: r
        }
      ],
      [
        ku,
        {
          ...d,
          ref: o
        }
      ],
      [
        L,
        n
      ],
      [
        Hu,
        {
          slots: {
            description: $,
            errorMessage: b
          }
        }
      ]
    ]
  }, e.children));
}
const At = /* @__PURE__ */ le(function(u, t) {
  return M(L) ? /* @__PURE__ */ D.createElement(mt, {
    props: u,
    forwardedRef: t
  }) : /* @__PURE__ */ D.createElement(wu, u);
});
function mt({ props: e, forwardedRef: u }) {
  let t = M(L), { CollectionRoot: o } = M(J), [r, l] = X(e, u, ku);
  delete r.items, delete r.renderEmptyState;
  let { focusProps: n, isFocused: c, isFocusVisible: s } = te(), d = {
    isEmpty: t.collection.size === 0,
    isFocused: c,
    isFocusVisible: s,
    state: t
  }, i = _({
    className: e.className,
    style: e.style,
    defaultClassName: "react-aria-TagList",
    values: d
  }), $ = Tu(t.selectionManager.focusedKey);
  return /* @__PURE__ */ D.createElement("div", {
    ...x(r, n),
    ...i,
    ref: l,
    "data-empty": t.collection.size === 0 || void 0,
    "data-focused": c || void 0,
    "data-focus-visible": s || void 0
  }, t.collection.size === 0 && e.renderEmptyState ? e.renderEmptyState(d) : /* @__PURE__ */ D.createElement(o, {
    collection: t.collection,
    persistedKeys: $
  }));
}
const yt = /* @__PURE__ */ Mu("item", (e, u, t) => {
  let o = M(L), r = Ru(u), { focusProps: l, isFocusVisible: n } = te({
    within: !0
  }), { rowProps: c, gridCellProps: s, removeButtonProps: d, ...i } = dt({
    item: t
  }, o, r), { hoverProps: $, isHovered: b } = Zu({
    isDisabled: !i.allowsSelection,
    onHoverStart: t.props.onHoverStart,
    onHoverChange: t.props.onHoverChange,
    onHoverEnd: t.props.onHoverEnd
  }), m = _({
    ...e,
    id: void 0,
    children: t.rendered,
    defaultClassName: "react-aria-Tag",
    values: {
      ...i,
      isFocusVisible: n,
      isHovered: b,
      selectionMode: o.selectionManager.selectionMode,
      selectionBehavior: o.selectionManager.selectionBehavior
    }
  });
  return T(() => {
    t.textValue || console.warn("A `textValue` prop is required for <Tag> elements with non-plain text children for accessibility.");
  }, [
    t.textValue
  ]), /* @__PURE__ */ D.createElement("div", {
    ref: r,
    ...m,
    ...x(S(e), c, l, $),
    "data-selected": i.isSelected || void 0,
    "data-disabled": i.isDisabled || void 0,
    "data-hovered": b || void 0,
    "data-focused": i.isFocused || void 0,
    "data-focus-visible": n || void 0,
    "data-pressed": i.isPressed || void 0,
    "data-allows-removing": i.allowsRemoving || void 0,
    "data-selection-mode": o.selectionManager.selectionMode === "none" ? void 0 : o.selectionManager.selectionMode
  }, /* @__PURE__ */ D.createElement("div", {
    ...s,
    style: {
      display: "contents"
    }
  }, /* @__PURE__ */ D.createElement(Y, {
    values: [
      [
        zu,
        {
          slots: {
            remove: d
          }
        }
      ],
      [
        J,
        Lu
      ]
    ]
  }, m.children)));
});
export {
  gt as $,
  At as a,
  yt as b
};
