import { f as Ve, a as R, m as be, c as xe, b as et } from "./utils.ec96ba96.js";
import N, { useRef as F, useState as ee, useEffect as j, useCallback as je, useContext as He, useMemo as W } from "react";
import { k as tt, n as lt, c as qe, o as nt, p as U, a as ie, q as rt, r as it, s as $e, t as ot, e as Ge, u as Pe, v as st } from "./useFocusRing.ff9f944e.js";
import { flushSync as ct } from "react-dom";
function Jt(n, e) {
  let { id: t, "aria-label": l, "aria-labelledby": r } = n;
  return t = Ve(t), r && l ? r = [
    .../* @__PURE__ */ new Set([
      t,
      ...r.trim().split(/\s+/)
    ])
  ].join(" ") : r && (r = r.trim().split(/\s+/).join(" ")), !l && !r && e && (l = e), {
    id: t,
    "aria-label": l,
    "aria-labelledby": r
  };
}
function De(n, e) {
  const t = F(!0), l = F(null);
  R(() => (t.current = !0, () => {
    t.current = !1;
  }), []), R(() => {
    t.current ? t.current = !1 : (!l.current || e.some((r, o) => !Object.is(r, l[o]))) && n(), l.current = e;
  }, e);
}
function at(n, e) {
  if (!n)
    return !1;
  let t = window.getComputedStyle(n), l = /(auto|scroll)/.test(t.overflow + t.overflowX + t.overflowY);
  return l && e && (l = n.scrollHeight !== n.clientHeight || n.scrollWidth !== n.clientWidth), l;
}
function ut(n, e) {
  const t = [];
  for (; n && n !== document.documentElement; )
    at(n, e) && t.push(n), n = n.parentElement;
  return t;
}
let dt = 0;
const pe = /* @__PURE__ */ new Map();
function ft(n) {
  let [e, t] = ee();
  return R(() => {
    if (!n)
      return;
    let l = pe.get(n);
    if (l)
      t(l.element.id);
    else {
      let r = `react-aria-description-${dt++}`;
      t(r);
      let o = document.createElement("div");
      o.id = r, o.style.display = "none", o.textContent = n, document.body.appendChild(o), l = {
        refCount: 0,
        element: o
      }, pe.set(n, l);
    }
    return l.refCount++, () => {
      l && --l.refCount === 0 && (l.element.remove(), pe.delete(n));
    };
  }, [
    n
  ]), {
    "aria-describedby": n ? e : void 0
  };
}
function re(n, e, t, l) {
  let r = be(t), o = t == null;
  j(() => {
    if (o || !n.current)
      return;
    let i = n.current;
    return i.addEventListener(e, r, l), () => {
      i.removeEventListener(e, r, l);
    };
  }, [
    n,
    e,
    l,
    o,
    r
  ]);
}
function Ye(n, e) {
  let t = Ce(n, e, "left"), l = Ce(n, e, "top"), r = e.offsetWidth, o = e.offsetHeight, i = n.scrollLeft, s = n.scrollTop, { borderTopWidth: d, borderLeftWidth: c, scrollPaddingTop: u, scrollPaddingRight: h, scrollPaddingBottom: p, scrollPaddingLeft: f } = getComputedStyle(n), $ = i + parseInt(c, 10), S = s + parseInt(d, 10), m = $ + n.clientWidth, b = S + n.clientHeight, w = parseInt(u, 10) || 0, g = parseInt(p, 10) || 0, P = parseInt(h, 10) || 0, x = parseInt(f, 10) || 0;
  t <= i + x ? i = t - parseInt(c, 10) - x : t + r > m - P && (i += t + r - m + P), l <= S + w ? s = l - parseInt(d, 10) - w : l + o > b - g && (s += l + o - b + g), n.scrollLeft = i, n.scrollTop = s;
}
function Ce(n, e, t) {
  const l = t === "left" ? "offsetLeft" : "offsetTop";
  let r = 0;
  for (; e.offsetParent && (r += e[l], e.offsetParent !== n); ) {
    if (e.offsetParent.contains(n)) {
      r -= n[l];
      break;
    }
    e = e.offsetParent;
  }
  return r;
}
function Le(n, e) {
  if (n && document.contains(n)) {
    let i = document.scrollingElement || document.documentElement;
    if (window.getComputedStyle(i).overflow === "hidden") {
      let d = ut(n);
      for (let c of d)
        Ye(c, n);
    } else {
      var t;
      let { left: d, top: c } = n.getBoundingClientRect();
      n == null || (t = n.scrollIntoView) === null || t === void 0 || t.call(n, {
        block: "nearest"
      });
      let { left: u, top: h } = n.getBoundingClientRect();
      if (Math.abs(d - u) > 1 || Math.abs(c - h) > 1) {
        var l, r, o;
        e == null || (r = e.containingElement) === null || r === void 0 || (l = r.scrollIntoView) === null || l === void 0 || l.call(r, {
          block: "center",
          inline: "center"
        }), (o = n.scrollIntoView) === null || o === void 0 || o.call(n, {
          block: "nearest"
        });
      }
    }
  }
}
const vt = "react-aria-clear-focus", pt = "react-aria-focus", Xe = "react-aria-update-activedescendant";
function _(n) {
  return tt() ? n.metaKey : n.ctrlKey;
}
function ht(n, e, t) {
  let [l, r] = ee(n || e), o = F(n !== void 0), i = n !== void 0;
  j(() => {
    let c = o.current;
    c !== i && console.warn(`WARN: A component changed from ${c ? "controlled" : "uncontrolled"} to ${i ? "controlled" : "uncontrolled"}.`), o.current = i;
  }, [
    i
  ]);
  let s = i ? n : l, d = je((c, ...u) => {
    let h = (p, ...f) => {
      t && (Object.is(s, p) || t(p, ...f)), i || (s = p);
    };
    typeof c == "function" ? (console.warn("We can not support a function callback. See Github Issues for details https://github.com/adobe/react-spectrum/issues/2320"), r((f, ...$) => {
      let S = c(i ? s : f, ...$);
      return h(S, ...u), i ? f : S;
    })) : (i || r(c), h(c, ...u));
  }, [
    i,
    s,
    t
  ]);
  return [
    s,
    d
  ];
}
const yt = 500;
function bt(n) {
  let { isDisabled: e, onLongPressStart: t, onLongPressEnd: l, onLongPress: r, threshold: o = yt, accessibilityDescription: i } = n;
  const s = F(void 0);
  let { addGlobalListener: d, removeGlobalListener: c } = lt(), { pressProps: u } = qe({
    isDisabled: e,
    onPressStart(p) {
      if (p.continuePropagation(), (p.pointerType === "mouse" || p.pointerType === "touch") && (t && t({
        ...p,
        type: "longpressstart"
      }), s.current = setTimeout(() => {
        p.target.dispatchEvent(new PointerEvent("pointercancel", {
          bubbles: !0
        })), r && r({
          ...p,
          type: "longpress"
        }), s.current = void 0;
      }, o), p.pointerType === "touch")) {
        let f = ($) => {
          $.preventDefault();
        };
        d(p.target, "contextmenu", f, {
          once: !0
        }), d(window, "pointerup", () => {
          setTimeout(() => {
            c(p.target, "contextmenu", f);
          }, 30);
        }, {
          once: !0
        });
      }
    },
    onPressEnd(p) {
      s.current && clearTimeout(s.current), l && (p.pointerType === "mouse" || p.pointerType === "touch") && l({
        ...p,
        type: "longpressend"
      });
    }
  }), h = ft(r && !e ? i : void 0);
  return {
    longPressProps: xe(u, h)
  };
}
function $t(n) {
  const e = nt(n);
  if (!(n instanceof e.HTMLElement) && !(n instanceof e.SVGElement))
    return !1;
  let { display: t, visibility: l } = n.style, r = t !== "none" && l !== "hidden" && l !== "collapse";
  if (r) {
    const { getComputedStyle: o } = n.ownerDocument.defaultView;
    let { display: i, visibility: s } = o(n);
    r = i !== "none" && s !== "hidden" && s !== "collapse";
  }
  return r;
}
function gt(n, e) {
  return !n.hasAttribute("hidden") && // Ignore HiddenSelect when tree walking.
  !n.hasAttribute("data-react-aria-prevent-focus") && (n.nodeName === "DETAILS" && e && e.nodeName !== "SUMMARY" ? n.hasAttribute("open") : !0);
}
function Je(n, e) {
  return n.nodeName !== "#comment" && $t(n) && gt(n, e) && (!n.parentElement || Je(n.parentElement, n));
}
const Ae = /* @__PURE__ */ N.createContext(null), ge = "react-aria-focus-scope-restore";
let T = null;
function Zt(n) {
  let { children: e, contain: t, restoreFocus: l, autoFocus: r } = n, o = F(null), i = F(null), s = F([]), { parentNode: d } = He(Ae) || {}, c = W(() => new Se({
    scopeRef: s
  }), [
    s
  ]);
  R(() => {
    let p = d || L.root;
    if (L.getTreeNode(p.scopeRef) && T && !oe(T, p.scopeRef)) {
      let f = L.getTreeNode(T);
      f && (p = f);
    }
    p.addChild(c), L.addNode(c);
  }, [
    c,
    d
  ]), R(() => {
    let p = L.getTreeNode(s);
    p && (p.contain = !!t);
  }, [
    t
  ]), R(() => {
    var p;
    let f = (p = o.current) === null || p === void 0 ? void 0 : p.nextSibling, $ = [], S = (m) => m.stopPropagation();
    for (; f && f !== i.current; )
      $.push(f), f.addEventListener(ge, S), f = f.nextSibling;
    return s.current = $, () => {
      for (let m of $)
        m.removeEventListener(ge, S);
    };
  }, [
    e
  ]), Tt(s, l, t), wt(s, t), Et(s, l, t), xt(s, r), j(() => {
    const p = U(s.current ? s.current[0] : void 0).activeElement;
    let f = null;
    if (O(p, s.current)) {
      for (let $ of L.traverse())
        $.scopeRef && O(p, $.scopeRef.current) && (f = $);
      f === L.getTreeNode(s) && (T = f.scopeRef);
    }
  }, [
    s
  ]), R(() => () => {
    var p, f, $;
    let S = ($ = (f = L.getTreeNode(s)) === null || f === void 0 || (p = f.parent) === null || p === void 0 ? void 0 : p.scopeRef) !== null && $ !== void 0 ? $ : null;
    (s === T || oe(s, T)) && (!S || L.getTreeNode(S)) && (T = S), L.removeTreeNode(s);
  }, [
    s
  ]);
  let u = W(() => mt(s), []), h = W(() => ({
    focusManager: u,
    parentNode: c
  }), [
    c,
    u
  ]);
  return /* @__PURE__ */ N.createElement(Ae.Provider, {
    value: h
  }, /* @__PURE__ */ N.createElement("span", {
    "data-focus-scope-start": !0,
    hidden: !0,
    ref: o
  }), e, /* @__PURE__ */ N.createElement("span", {
    "data-focus-scope-end": !0,
    hidden: !0,
    ref: i
  }));
}
function mt(n) {
  return {
    focusNext(e = {}) {
      let t = n.current, { from: l, tabbable: r, wrap: o, accept: i } = e, s = l || U(t[0]).activeElement, d = t[0].previousElementSibling, c = Q(t), u = G(c, {
        tabbable: r,
        accept: i
      }, t);
      u.currentNode = O(s, t) ? s : d;
      let h = u.nextNode();
      return !h && o && (u.currentNode = d, h = u.nextNode()), h && q(h, !0), h;
    },
    focusPrevious(e = {}) {
      let t = n.current, { from: l, tabbable: r, wrap: o, accept: i } = e, s = l || U(t[0]).activeElement, d = t[t.length - 1].nextElementSibling, c = Q(t), u = G(c, {
        tabbable: r,
        accept: i
      }, t);
      u.currentNode = O(s, t) ? s : d;
      let h = u.previousNode();
      return !h && o && (u.currentNode = d, h = u.previousNode()), h && q(h, !0), h;
    },
    focusFirst(e = {}) {
      let t = n.current, { tabbable: l, accept: r } = e, o = Q(t), i = G(o, {
        tabbable: l,
        accept: r
      }, t);
      i.currentNode = t[0].previousElementSibling;
      let s = i.nextNode();
      return s && q(s, !0), s;
    },
    focusLast(e = {}) {
      let t = n.current, { tabbable: l, accept: r } = e, o = Q(t), i = G(o, {
        tabbable: l,
        accept: r
      }, t);
      i.currentNode = t[t.length - 1].nextElementSibling;
      let s = i.previousNode();
      return s && q(s, !0), s;
    }
  };
}
const Te = [
  "input:not([disabled]):not([type=hidden])",
  "select:not([disabled])",
  "textarea:not([disabled])",
  "button:not([disabled])",
  "a[href]",
  "area[href]",
  "summary",
  "iframe",
  "object",
  "embed",
  "audio[controls]",
  "video[controls]",
  '[contenteditable]:not([contenteditable^="false"])'
], St = Te.join(":not([hidden]),") + ",[tabindex]:not([disabled]):not([hidden])";
Te.push('[tabindex]:not([tabindex="-1"]):not([disabled])');
const Kt = Te.join(':not([hidden]):not([tabindex="-1"]),');
function Q(n) {
  return n[0].parentElement;
}
function le(n) {
  let e = L.getTreeNode(T);
  for (; e && e.scopeRef !== n; ) {
    if (e.contain)
      return !1;
    e = e.parent;
  }
  return !0;
}
function wt(n, e) {
  let t = F(void 0), l = F(void 0);
  R(() => {
    let r = n.current;
    if (!e) {
      l.current && (cancelAnimationFrame(l.current), l.current = void 0);
      return;
    }
    const o = U(r ? r[0] : void 0);
    let i = (c) => {
      if (c.key !== "Tab" || c.altKey || c.ctrlKey || c.metaKey || !le(n) || c.isComposing)
        return;
      let u = o.activeElement, h = n.current;
      if (!h || !O(u, h))
        return;
      let p = Q(h), f = G(p, {
        tabbable: !0
      }, h);
      if (!u)
        return;
      f.currentNode = u;
      let $ = c.shiftKey ? f.previousNode() : f.nextNode();
      $ || (f.currentNode = c.shiftKey ? h[h.length - 1].nextElementSibling : h[0].previousElementSibling, $ = c.shiftKey ? f.previousNode() : f.nextNode()), c.preventDefault(), $ && q($, !0);
    }, s = (c) => {
      (!T || oe(T, n)) && O(c.target, n.current) ? (T = n, t.current = c.target) : le(n) && !J(c.target, n) ? t.current ? t.current.focus() : T && T.current && me(T.current) : le(n) && (t.current = c.target);
    }, d = (c) => {
      l.current && cancelAnimationFrame(l.current), l.current = requestAnimationFrame(() => {
        let u = $e();
        if (!((u === "virtual" || u === null) && rt() && it()) && o.activeElement && le(n) && !J(o.activeElement, n))
          if (T = n, o.body.contains(c.target)) {
            var p;
            t.current = c.target, (p = t.current) === null || p === void 0 || p.focus();
          } else
            T.current && me(T.current);
      });
    };
    return o.addEventListener("keydown", i, !1), o.addEventListener("focusin", s, !1), r == null || r.forEach((c) => c.addEventListener("focusin", s, !1)), r == null || r.forEach((c) => c.addEventListener("focusout", d, !1)), () => {
      o.removeEventListener("keydown", i, !1), o.removeEventListener("focusin", s, !1), r == null || r.forEach((c) => c.removeEventListener("focusin", s, !1)), r == null || r.forEach((c) => c.removeEventListener("focusout", d, !1));
    };
  }, [
    n,
    e
  ]), R(() => () => {
    l.current && cancelAnimationFrame(l.current);
  }, [
    l
  ]);
}
function Ze(n) {
  return J(n);
}
function O(n, e) {
  return !n || !e ? !1 : e.some((t) => t.contains(n));
}
function J(n, e = null) {
  if (n instanceof Element && n.closest("[data-react-aria-top-layer]"))
    return !0;
  for (let { scopeRef: t } of L.traverse(L.getTreeNode(e)))
    if (t && O(n, t.current))
      return !0;
  return !1;
}
function Qt(n) {
  return J(n, T);
}
function oe(n, e) {
  var t;
  let l = (t = L.getTreeNode(e)) === null || t === void 0 ? void 0 : t.parent;
  for (; l; ) {
    if (l.scopeRef === n)
      return !0;
    l = l.parent;
  }
  return !1;
}
function q(n, e = !1) {
  if (n != null && !e)
    try {
      ie(n);
    } catch {
    }
  else if (n != null)
    try {
      n.focus();
    } catch {
    }
}
function Qe(n, e = !0) {
  let t = n[0].previousElementSibling, l = Q(n), r = G(l, {
    tabbable: e
  }, n);
  r.currentNode = t;
  let o = r.nextNode();
  return e && !o && (l = Q(n), r = G(l, {
    tabbable: !1
  }, n), r.currentNode = t, o = r.nextNode()), o;
}
function me(n, e = !0) {
  q(Qe(n, e));
}
function xt(n, e) {
  const t = N.useRef(e);
  j(() => {
    if (t.current) {
      T = n;
      const l = U(n.current ? n.current[0] : void 0);
      !O(l.activeElement, T.current) && n.current && me(n.current);
    }
    t.current = !1;
  }, [
    n
  ]);
}
function Tt(n, e, t) {
  R(() => {
    if (e || t)
      return;
    let l = n.current;
    const r = U(l ? l[0] : void 0);
    let o = (i) => {
      let s = i.target;
      O(s, n.current) ? T = n : Ze(s) || (T = null);
    };
    return r.addEventListener("focusin", o, !1), l == null || l.forEach((i) => i.addEventListener("focusin", o, !1)), () => {
      r.removeEventListener("focusin", o, !1), l == null || l.forEach((i) => i.removeEventListener("focusin", o, !1));
    };
  }, [
    n,
    e,
    t
  ]);
}
function Me(n) {
  let e = L.getTreeNode(T);
  for (; e && e.scopeRef !== n; ) {
    if (e.nodeToRestore)
      return !1;
    e = e.parent;
  }
  return (e == null ? void 0 : e.scopeRef) === n;
}
function Et(n, e, t) {
  const l = F(typeof document < "u" ? U(n.current ? n.current[0] : void 0).activeElement : null);
  R(() => {
    let r = n.current;
    const o = U(r ? r[0] : void 0);
    if (!e || t)
      return;
    let i = () => {
      (!T || oe(T, n)) && O(o.activeElement, n.current) && (T = n);
    };
    return o.addEventListener("focusin", i, !1), r == null || r.forEach((s) => s.addEventListener("focusin", i, !1)), () => {
      o.removeEventListener("focusin", i, !1), r == null || r.forEach((s) => s.removeEventListener("focusin", i, !1));
    };
  }, [
    n,
    t
  ]), R(() => {
    const r = U(n.current ? n.current[0] : void 0);
    if (!e)
      return;
    let o = (i) => {
      if (i.key !== "Tab" || i.altKey || i.ctrlKey || i.metaKey || !le(n) || i.isComposing)
        return;
      let s = r.activeElement;
      if (!J(s, n) || !Me(n))
        return;
      let d = L.getTreeNode(n);
      if (!d)
        return;
      let c = d.nodeToRestore, u = G(r.body, {
        tabbable: !0
      });
      u.currentNode = s;
      let h = i.shiftKey ? u.previousNode() : u.nextNode();
      if ((!c || !r.body.contains(c) || c === r.body) && (c = void 0, d.nodeToRestore = void 0), (!h || !J(h, n)) && c) {
        u.currentNode = c;
        do
          h = i.shiftKey ? u.previousNode() : u.nextNode();
        while (J(h, n));
        i.preventDefault(), i.stopPropagation(), h ? q(h, !0) : Ze(c) ? q(c, !0) : s.blur();
      }
    };
    return t || r.addEventListener("keydown", o, !0), () => {
      t || r.removeEventListener("keydown", o, !0);
    };
  }, [
    n,
    e,
    t
  ]), R(() => {
    const r = U(n.current ? n.current[0] : void 0);
    if (!e)
      return;
    let o = L.getTreeNode(n);
    if (o) {
      var i;
      return o.nodeToRestore = (i = l.current) !== null && i !== void 0 ? i : void 0, () => {
        let s = L.getTreeNode(n);
        if (!s)
          return;
        let d = s.nodeToRestore;
        if (e && d && (r.activeElement && J(r.activeElement, n) || r.activeElement === r.body && Me(n))) {
          let c = L.clone();
          requestAnimationFrame(() => {
            if (r.activeElement === r.body) {
              let u = c.getTreeNode(n);
              for (; u; ) {
                if (u.nodeToRestore && u.nodeToRestore.isConnected) {
                  Ie(u.nodeToRestore);
                  return;
                }
                u = u.parent;
              }
              for (u = c.getTreeNode(n); u; ) {
                if (u.scopeRef && u.scopeRef.current && L.getTreeNode(u.scopeRef)) {
                  let h = Qe(u.scopeRef.current, !0);
                  Ie(h);
                  return;
                }
                u = u.parent;
              }
            }
          });
        }
      };
    }
  }, [
    n,
    e
  ]);
}
function Ie(n) {
  n.dispatchEvent(new CustomEvent(ge, {
    bubbles: !0,
    cancelable: !0
  })) && q(n);
}
function G(n, e, t) {
  let l = e != null && e.tabbable ? Kt : St, r = U(n).createTreeWalker(n, NodeFilter.SHOW_ELEMENT, {
    acceptNode(o) {
      var i;
      return !(e == null || (i = e.from) === null || i === void 0) && i.contains(o) ? NodeFilter.FILTER_REJECT : o.matches(l) && Je(o) && (!t || O(o, t)) && (!(e != null && e.accept) || e.accept(o)) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  return e != null && e.from && (r.currentNode = e.from), r;
}
class Ee {
  get size() {
    return this.fastMap.size;
  }
  getTreeNode(e) {
    return this.fastMap.get(e);
  }
  addTreeNode(e, t, l) {
    let r = this.fastMap.get(t ?? null);
    if (!r)
      return;
    let o = new Se({
      scopeRef: e
    });
    r.addChild(o), o.parent = r, this.fastMap.set(e, o), l && (o.nodeToRestore = l);
  }
  addNode(e) {
    this.fastMap.set(e.scopeRef, e);
  }
  removeTreeNode(e) {
    if (e === null)
      return;
    let t = this.fastMap.get(e);
    if (!t)
      return;
    let l = t.parent;
    for (let o of this.traverse())
      o !== t && t.nodeToRestore && o.nodeToRestore && t.scopeRef && t.scopeRef.current && O(o.nodeToRestore, t.scopeRef.current) && (o.nodeToRestore = t.nodeToRestore);
    let r = t.children;
    l && (l.removeChild(t), r.size > 0 && r.forEach((o) => l && l.addChild(o))), this.fastMap.delete(t.scopeRef);
  }
  // Pre Order Depth First
  *traverse(e = this.root) {
    if (e.scopeRef != null && (yield e), e.children.size > 0)
      for (let t of e.children)
        yield* this.traverse(t);
  }
  clone() {
    var e;
    let t = new Ee();
    var l;
    for (let r of this.traverse())
      t.addTreeNode(r.scopeRef, (l = (e = r.parent) === null || e === void 0 ? void 0 : e.scopeRef) !== null && l !== void 0 ? l : null, r.nodeToRestore);
    return t;
  }
  constructor() {
    this.fastMap = /* @__PURE__ */ new Map(), this.root = new Se({
      scopeRef: null
    }), this.fastMap.set(null, this.root);
  }
}
class Se {
  addChild(e) {
    this.children.add(e), e.parent = this;
  }
  removeChild(e) {
    this.children.delete(e), e.parent = void 0;
  }
  constructor(e) {
    this.children = /* @__PURE__ */ new Set(), this.contain = !1, this.scopeRef = e.scopeRef;
  }
}
let L = new Ee();
const kt = /* @__PURE__ */ new Set([
  "Arab",
  "Syrc",
  "Samr",
  "Mand",
  "Thaa",
  "Mend",
  "Nkoo",
  "Adlm",
  "Rohg",
  "Hebr"
]), Ft = /* @__PURE__ */ new Set([
  "ae",
  "ar",
  "arc",
  "bcc",
  "bqi",
  "ckb",
  "dv",
  "fa",
  "glk",
  "he",
  "ku",
  "mzn",
  "nqo",
  "pnb",
  "ps",
  "sd",
  "ug",
  "ur",
  "yi"
]);
function Pt(n) {
  if (Intl.Locale) {
    let t = new Intl.Locale(n).maximize(), l = typeof t.getTextInfo == "function" ? t.getTextInfo() : t.textInfo;
    if (l)
      return l.direction === "rtl";
    if (t.script)
      return kt.has(t.script);
  }
  let e = n.split("-")[0];
  return Ft.has(e);
}
const Dt = Symbol.for("react-aria.i18n.locale");
function _e() {
  let n = typeof window < "u" && window[Dt] || typeof navigator < "u" && (navigator.language || navigator.userLanguage) || "en-US";
  try {
    Intl.DateTimeFormat.supportedLocalesOf([
      n
    ]);
  } catch {
    n = "en-US";
  }
  return {
    locale: n,
    direction: Pt(n) ? "rtl" : "ltr"
  };
}
let Ke = _e(), ne = /* @__PURE__ */ new Set();
function Ne() {
  Ke = _e();
  for (let n of ne)
    n(Ke);
}
function Ct() {
  let n = et(), [e, t] = ee(Ke);
  return j(() => (ne.size === 0 && window.addEventListener("languagechange", Ne), ne.add(t), () => {
    ne.delete(t), ne.size === 0 && window.removeEventListener("languagechange", Ne);
  }), []), n ? {
    locale: "en-US",
    direction: "ltr"
  } : e;
}
const Lt = /* @__PURE__ */ N.createContext(null);
function At() {
  let n = Ct();
  return He(Lt) || n;
}
function we(n) {
  return ot() ? n.altKey : n.ctrlKey;
}
const Mt = 1e3;
function It(n) {
  let { keyboardDelegate: e, selectionManager: t, onTypeSelect: l } = n, r = F({
    search: "",
    timeout: void 0
  }).current, o = (i) => {
    let s = Nt(i.key);
    if (!(!s || i.ctrlKey || i.metaKey || !i.currentTarget.contains(i.target))) {
      if (s === " " && r.search.trim().length > 0 && (i.preventDefault(), "continuePropagation" in i || i.stopPropagation()), r.search += s, e.getKeyForSearch != null) {
        let d = e.getKeyForSearch(r.search, t.focusedKey);
        d == null && (d = e.getKeyForSearch(r.search)), d != null && (t.setFocusedKey(d), l && l(d));
      }
      clearTimeout(r.timeout), r.timeout = setTimeout(() => {
        r.search = "";
      }, Mt);
    }
  };
  return {
    typeSelectProps: {
      // Using a capturing listener to catch the keydown event before
      // other hooks in order to handle the Spacebar event.
      onKeyDownCapture: e.getKeyForSearch ? o : void 0
    }
  };
}
function Nt(n) {
  return n.length === 1 || !/^[A-Z]/i.test(n) ? n : "";
}
function _t(n) {
  let { selectionManager: e, keyboardDelegate: t, ref: l, autoFocus: r = !1, shouldFocusWrap: o = !1, disallowEmptySelection: i = !1, disallowSelectAll: s = !1, selectOnFocus: d = e.selectionBehavior === "replace", disallowTypeAhead: c = !1, shouldUseVirtualFocus: u, allowsTabNavigation: h = !1, isVirtualized: p, scrollRef: f = l, linkBehavior: $ = "action" } = n, { direction: S } = At(), m = Ge(), b = (v) => {
    var K;
    if (v.altKey && v.key === "Tab" && v.preventDefault(), !(!((K = l.current) === null || K === void 0) && K.contains(v.target)))
      return;
    const k = (y, Z) => {
      if (y != null) {
        if (e.isLink(y) && $ === "selection" && d && !we(v)) {
          var X;
          ct(() => {
            e.setFocusedKey(y, Z);
          });
          let ke = (X = f.current) === null || X === void 0 ? void 0 : X.querySelector(`[data-key="${CSS.escape(y.toString())}"]`), Fe = e.getItemProps(y);
          ke && m.open(ke, v, Fe.href, Fe.routerOptions);
          return;
        }
        if (e.setFocusedKey(y, Z), e.isLink(y) && $ === "override")
          return;
        v.shiftKey && e.selectionMode === "multiple" ? e.extendSelection(y) : d && !we(v) && e.replaceSelection(y);
      }
    };
    switch (v.key) {
      case "ArrowDown":
        if (t.getKeyBelow) {
          var D, M, a;
          let y = e.focusedKey != null ? (D = t.getKeyBelow) === null || D === void 0 ? void 0 : D.call(t, e.focusedKey) : (M = t.getFirstKey) === null || M === void 0 ? void 0 : M.call(t);
          y == null && o && (y = (a = t.getFirstKey) === null || a === void 0 ? void 0 : a.call(t, e.focusedKey)), y != null && (v.preventDefault(), k(y));
        }
        break;
      case "ArrowUp":
        if (t.getKeyAbove) {
          var C, H, se;
          let y = e.focusedKey != null ? (C = t.getKeyAbove) === null || C === void 0 ? void 0 : C.call(t, e.focusedKey) : (H = t.getLastKey) === null || H === void 0 ? void 0 : H.call(t);
          y == null && o && (y = (se = t.getLastKey) === null || se === void 0 ? void 0 : se.call(t, e.focusedKey)), y != null && (v.preventDefault(), k(y));
        }
        break;
      case "ArrowLeft":
        if (t.getKeyLeftOf) {
          var ce, ae, ue;
          let y = e.focusedKey != null ? (ce = t.getKeyLeftOf) === null || ce === void 0 ? void 0 : ce.call(t, e.focusedKey) : null;
          y == null && o && (y = S === "rtl" ? (ae = t.getFirstKey) === null || ae === void 0 ? void 0 : ae.call(t, e.focusedKey) : (ue = t.getLastKey) === null || ue === void 0 ? void 0 : ue.call(t, e.focusedKey)), y != null && (v.preventDefault(), k(y, S === "rtl" ? "first" : "last"));
        }
        break;
      case "ArrowRight":
        if (t.getKeyRightOf) {
          var de, fe, ve;
          let y = e.focusedKey != null ? (de = t.getKeyRightOf) === null || de === void 0 ? void 0 : de.call(t, e.focusedKey) : null;
          y == null && o && (y = S === "rtl" ? (fe = t.getLastKey) === null || fe === void 0 ? void 0 : fe.call(t, e.focusedKey) : (ve = t.getFirstKey) === null || ve === void 0 ? void 0 : ve.call(t, e.focusedKey)), y != null && (v.preventDefault(), k(y, S === "rtl" ? "last" : "first"));
        }
        break;
      case "Home":
        if (t.getFirstKey) {
          if (e.focusedKey === null && v.shiftKey)
            return;
          v.preventDefault();
          let y = t.getFirstKey(e.focusedKey, _(v));
          e.setFocusedKey(y), y != null && (_(v) && v.shiftKey && e.selectionMode === "multiple" ? e.extendSelection(y) : d && e.replaceSelection(y));
        }
        break;
      case "End":
        if (t.getLastKey) {
          if (e.focusedKey === null && v.shiftKey)
            return;
          v.preventDefault();
          let y = t.getLastKey(e.focusedKey, _(v));
          e.setFocusedKey(y), y != null && (_(v) && v.shiftKey && e.selectionMode === "multiple" ? e.extendSelection(y) : d && e.replaceSelection(y));
        }
        break;
      case "PageDown":
        if (t.getKeyPageBelow && e.focusedKey != null) {
          let y = t.getKeyPageBelow(e.focusedKey);
          y != null && (v.preventDefault(), k(y));
        }
        break;
      case "PageUp":
        if (t.getKeyPageAbove && e.focusedKey != null) {
          let y = t.getKeyPageAbove(e.focusedKey);
          y != null && (v.preventDefault(), k(y));
        }
        break;
      case "a":
        _(v) && e.selectionMode === "multiple" && s !== !0 && (v.preventDefault(), e.selectAll());
        break;
      case "Escape":
        !i && e.selectedKeys.size !== 0 && (v.stopPropagation(), v.preventDefault(), e.clearSelection());
        break;
      case "Tab":
        if (!h) {
          if (v.shiftKey)
            l.current.focus();
          else {
            let y = G(l.current, {
              tabbable: !0
            }), Z, X;
            do
              X = y.lastChild(), X && (Z = X);
            while (X);
            Z && !Z.contains(document.activeElement) && Pe(Z);
          }
          break;
        }
    }
  }, w = F({
    top: 0,
    left: 0
  });
  re(f, "scroll", p ? void 0 : () => {
    var v, K, k, D;
    w.current = {
      top: (k = (v = f.current) === null || v === void 0 ? void 0 : v.scrollTop) !== null && k !== void 0 ? k : 0,
      left: (D = (K = f.current) === null || K === void 0 ? void 0 : K.scrollLeft) !== null && D !== void 0 ? D : 0
    };
  });
  let g = (v) => {
    if (e.isFocused) {
      v.currentTarget.contains(v.target) || e.setFocused(!1);
      return;
    }
    if (v.currentTarget.contains(v.target)) {
      if (e.setFocused(!0), e.focusedKey == null) {
        var K, k;
        let a = (H) => {
          H != null && (e.setFocusedKey(H), d && e.replaceSelection(H));
        }, C = v.relatedTarget;
        var D, M;
        C && v.currentTarget.compareDocumentPosition(C) & Node.DOCUMENT_POSITION_FOLLOWING ? a((D = e.lastSelectedKey) !== null && D !== void 0 ? D : (K = t.getLastKey) === null || K === void 0 ? void 0 : K.call(t)) : a((M = e.firstSelectedKey) !== null && M !== void 0 ? M : (k = t.getFirstKey) === null || k === void 0 ? void 0 : k.call(t));
      } else
        !p && f.current && (f.current.scrollTop = w.current.top, f.current.scrollLeft = w.current.left);
      if (e.focusedKey != null && f.current) {
        let a = f.current.querySelector(`[data-key="${CSS.escape(e.focusedKey.toString())}"]`);
        a && (a.contains(document.activeElement) || Pe(a), $e() === "keyboard" && Le(a, {
          containingElement: l.current
        }));
      }
    }
  }, P = (v) => {
    v.currentTarget.contains(v.relatedTarget) || e.setFocused(!1);
  }, x = F(!1);
  re(l, pt, u ? (v) => {
    let { detail: K } = v;
    v.stopPropagation(), e.setFocused(!0), (K == null ? void 0 : K.focusStrategy) === "first" && (x.current = !0);
  } : void 0);
  let B = be(() => {
    var v, K;
    let k = (K = (v = t.getFirstKey) === null || v === void 0 ? void 0 : v.call(t)) !== null && K !== void 0 ? K : null;
    if (k == null) {
      var D;
      (D = l.current) === null || D === void 0 || D.dispatchEvent(new CustomEvent(Xe, {
        cancelable: !0,
        bubbles: !0
      })), e.collection.size > 0 && (x.current = !1);
    } else
      e.setFocusedKey(k), x.current = !1;
  });
  De(() => {
    x.current && B();
  }, [
    e.collection,
    B
  ]);
  let I = be(() => {
    e.collection.size > 0 && (x.current = !1);
  });
  De(() => {
    I();
  }, [
    e.focusedKey,
    I
  ]), re(l, vt, u ? (v) => {
    v.stopPropagation(), e.setFocused(!1), e.setFocusedKey(null);
  } : void 0);
  const E = F(r);
  j(() => {
    if (E.current) {
      var v, K;
      let M = null;
      var k;
      r === "first" && (M = (k = (v = t.getFirstKey) === null || v === void 0 ? void 0 : v.call(t)) !== null && k !== void 0 ? k : null);
      var D;
      r === "last" && (M = (D = (K = t.getLastKey) === null || K === void 0 ? void 0 : K.call(t)) !== null && D !== void 0 ? D : null);
      let a = e.selectedKeys;
      if (a.size) {
        for (let C of a)
          if (e.canSelectItem(C)) {
            M = C;
            break;
          }
      }
      e.setFocused(!0), e.setFocusedKey(M), M == null && !u && l.current && ie(l.current);
    }
  }, []);
  let A = F(e.focusedKey);
  j(() => {
    if (e.isFocused && e.focusedKey != null && (e.focusedKey !== A.current || E.current) && f.current && l.current) {
      let v = $e(), K = l.current.querySelector(`[data-key="${CSS.escape(e.focusedKey.toString())}"]`);
      if (!K)
        return;
      (v === "keyboard" || E.current) && (Ye(f.current, K), v !== "virtual" && Le(K, {
        containingElement: l.current
      }));
    }
    !u && e.isFocused && e.focusedKey == null && A.current != null && l.current && ie(l.current), A.current = e.focusedKey, E.current = !1;
  }), re(l, "react-aria-focus-scope-restore", (v) => {
    v.preventDefault(), e.setFocused(!0);
  });
  let Y = {
    onKeyDown: b,
    onFocus: g,
    onBlur: P,
    onMouseDown(v) {
      f.current === v.target && v.preventDefault();
    }
  }, { typeSelectProps: V } = It({
    keyboardDelegate: t,
    selectionManager: e
  });
  c || (Y = xe(V, Y));
  let te;
  return u ? te = -1 : te = e.focusedKey == null ? 0 : -1, {
    collectionProps: {
      ...Y,
      tabIndex: te
    }
  };
}
function el(n) {
  let { id: e, selectionManager: t, key: l, ref: r, shouldSelectOnPressUp: o, shouldUseVirtualFocus: i, focus: s, isDisabled: d, onAction: c, allowsDifferentPressOrigin: u, linkBehavior: h = "action" } = n, p = Ge();
  e = Ve(e);
  let f = (a) => {
    if (a.pointerType === "keyboard" && we(a))
      t.toggleSelection(l);
    else {
      if (t.selectionMode === "none")
        return;
      if (t.isLink(l)) {
        if (h === "selection" && r.current) {
          let C = t.getItemProps(l);
          p.open(r.current, a, C.href, C.routerOptions), t.setSelectedKeys(t.selectedKeys);
          return;
        } else if (h === "override" || h === "none")
          return;
      }
      t.selectionMode === "single" ? t.isSelected(l) && !t.disallowEmptySelection ? t.toggleSelection(l) : t.replaceSelection(l) : a && a.shiftKey ? t.extendSelection(l) : t.selectionBehavior === "toggle" || a && (_(a) || a.pointerType === "touch" || a.pointerType === "virtual") ? t.toggleSelection(l) : t.replaceSelection(l);
    }
  };
  j(() => {
    if (l === t.focusedKey && t.isFocused)
      if (!i)
        s ? s() : document.activeElement !== r.current && r.current && ie(r.current);
      else {
        var C;
        let H = new CustomEvent(Xe, {
          cancelable: !0,
          bubbles: !0
        });
        (C = r.current) === null || C === void 0 || C.dispatchEvent(H);
      }
  }, [
    r,
    l,
    t.focusedKey,
    t.childFocusStrategy,
    t.isFocused,
    i
  ]), d = d || t.isDisabled(l);
  let $ = {};
  !i && !d ? $ = {
    tabIndex: l === t.focusedKey ? 0 : -1,
    onFocus(a) {
      a.target === r.current && t.setFocusedKey(l);
    }
  } : d && ($.onMouseDown = (a) => {
    a.preventDefault();
  });
  let S = t.isLink(l) && h === "override", m = t.isLink(l) && h !== "selection" && h !== "none", b = !d && t.canSelectItem(l) && !S, w = (c || m) && !d, g = w && (t.selectionBehavior === "replace" ? !b : !b || t.isEmpty), P = w && b && t.selectionBehavior === "replace", x = g || P, B = F(null), I = x && b, E = F(!1), A = F(!1), Y = (a) => {
    if (c && c(), m && r.current) {
      let C = t.getItemProps(l);
      p.open(r.current, a, C.href, C.routerOptions);
    }
  }, V = {};
  o ? (V.onPressStart = (a) => {
    B.current = a.pointerType, E.current = I, a.pointerType === "keyboard" && (!x || Be()) && f(a);
  }, u ? (V.onPressUp = g ? void 0 : (a) => {
    a.pointerType === "mouse" && b && f(a);
  }, V.onPress = g ? Y : (a) => {
    a.pointerType !== "keyboard" && a.pointerType !== "mouse" && b && f(a);
  }) : V.onPress = (a) => {
    if (g || P && a.pointerType !== "mouse") {
      if (a.pointerType === "keyboard" && !Re())
        return;
      Y(a);
    } else
      a.pointerType !== "keyboard" && b && f(a);
  }) : (V.onPressStart = (a) => {
    B.current = a.pointerType, E.current = I, A.current = g, b && (a.pointerType === "mouse" && !g || a.pointerType === "keyboard" && (!w || Be())) && f(a);
  }, V.onPress = (a) => {
    (a.pointerType === "touch" || a.pointerType === "pen" || a.pointerType === "virtual" || a.pointerType === "keyboard" && x && Re() || a.pointerType === "mouse" && A.current) && (x ? Y(a) : b && f(a));
  }), $["data-key"] = l, V.preventFocusOnPress = i;
  let { pressProps: te, isPressed: v } = qe(V), K = P ? (a) => {
    B.current === "mouse" && (a.stopPropagation(), a.preventDefault(), Y(a));
  } : void 0, { longPressProps: k } = bt({
    isDisabled: !I,
    onLongPress(a) {
      a.pointerType === "touch" && (f(a), t.setSelectionBehavior("toggle"));
    }
  }), D = (a) => {
    B.current === "touch" && E.current && a.preventDefault();
  }, M = t.isLink(l) ? (a) => {
    st.isOpening || a.preventDefault();
  } : void 0;
  return {
    itemProps: xe($, b || g ? te : {}, I ? k : {}, {
      onDoubleClick: K,
      onDragStartCapture: D,
      onClick: M,
      id: e
    }),
    isPressed: v,
    isSelected: t.isSelected(l),
    isFocused: t.isFocused && t.focusedKey === l,
    isDisabled: d,
    allowsSelection: b,
    hasAction: x
  };
}
function Re() {
  let n = window.event;
  return (n == null ? void 0 : n.key) === "Enter";
}
function Be() {
  let n = window.event;
  return (n == null ? void 0 : n.key) === " " || (n == null ? void 0 : n.code) === "Space";
}
class Rt {
  build(e, t) {
    return this.context = t, Oe(() => this.iterateCollection(e));
  }
  *iterateCollection(e) {
    let { children: t, items: l } = e;
    if (N.isValidElement(t) && t.type === N.Fragment)
      yield* this.iterateCollection({
        children: t.props.children,
        items: l
      });
    else if (typeof t == "function") {
      if (!l)
        throw new Error("props.children was a function but props.items is missing");
      let r = 0;
      for (let o of l)
        yield* this.getFullNode({
          value: o,
          index: r
        }, {
          renderer: t
        }), r++;
    } else {
      let r = [];
      N.Children.forEach(t, (i) => {
        i && r.push(i);
      });
      let o = 0;
      for (let i of r) {
        let s = this.getFullNode({
          element: i,
          index: o
        }, {});
        for (let d of s)
          o++, yield d;
      }
    }
  }
  getKey(e, t, l, r) {
    if (e.key != null)
      return e.key;
    if (t.type === "cell" && t.key != null)
      return `${r}${t.key}`;
    let o = t.value;
    if (o != null) {
      var i;
      let s = (i = o.key) !== null && i !== void 0 ? i : o.id;
      if (s == null)
        throw new Error("No key found for item");
      return s;
    }
    return r ? `${r}.${t.index}` : `$.${t.index}`;
  }
  getChildState(e, t) {
    return {
      renderer: t.renderer || e.renderer
    };
  }
  *getFullNode(e, t, l, r) {
    if (N.isValidElement(e.element) && e.element.type === N.Fragment) {
      let m = [];
      N.Children.forEach(e.element.props.children, (w) => {
        m.push(w);
      });
      var o;
      let b = (o = e.index) !== null && o !== void 0 ? o : 0;
      for (const w of m)
        yield* this.getFullNode({
          element: w,
          index: b++
        }, t, l, r);
      return;
    }
    let i = e.element;
    if (!i && e.value && t && t.renderer) {
      let m = this.cache.get(e.value);
      if (m && (!m.shouldInvalidate || !m.shouldInvalidate(this.context))) {
        m.index = e.index, m.parentKey = r ? r.key : null, yield m;
        return;
      }
      i = t.renderer(e.value);
    }
    if (N.isValidElement(i)) {
      let m = i.type;
      if (typeof m != "function" && typeof m.getCollectionNode != "function") {
        let P = i.type;
        throw new Error(`Unknown element <${P}> in collection.`);
      }
      let b = m.getCollectionNode(i.props, this.context);
      var s;
      let w = (s = e.index) !== null && s !== void 0 ? s : 0, g = b.next();
      for (; !g.done && g.value; ) {
        let P = g.value;
        e.index = w;
        var d;
        let x = (d = P.key) !== null && d !== void 0 ? d : null;
        x == null && (x = P.element ? null : this.getKey(i, e, t, l));
        let I = [
          ...this.getFullNode({
            ...P,
            key: x,
            index: w,
            wrapper: Bt(e.wrapper, P.wrapper)
          }, this.getChildState(t, P), l ? `${l}${i.key}` : i.key, r)
        ];
        for (let E of I) {
          var c, u;
          E.value = (u = (c = P.value) !== null && c !== void 0 ? c : e.value) !== null && u !== void 0 ? u : null, E.value && this.cache.set(E.value, E);
          var h;
          if (e.type && E.type !== e.type)
            throw new Error(`Unsupported type <${he(E.type)}> in <${he((h = r == null ? void 0 : r.type) !== null && h !== void 0 ? h : "unknown parent type")}>. Only <${he(e.type)}> is supported.`);
          w++, yield E;
        }
        g = b.next(I);
      }
      return;
    }
    if (e.key == null || e.type == null)
      return;
    let p = this;
    var f, $;
    let S = {
      type: e.type,
      props: e.props,
      key: e.key,
      parentKey: r ? r.key : null,
      value: (f = e.value) !== null && f !== void 0 ? f : null,
      level: r ? r.level + 1 : 0,
      index: e.index,
      rendered: e.rendered,
      textValue: ($ = e.textValue) !== null && $ !== void 0 ? $ : "",
      "aria-label": e["aria-label"],
      wrapper: e.wrapper,
      shouldInvalidate: e.shouldInvalidate,
      hasChildNodes: e.hasChildNodes || !1,
      childNodes: Oe(function* () {
        if (!e.hasChildNodes || !e.childNodes)
          return;
        let m = 0;
        for (let b of e.childNodes()) {
          b.key != null && (b.key = `${S.key}${b.key}`);
          let w = p.getFullNode({
            ...b,
            index: m
          }, p.getChildState(t, b), S.key, S);
          for (let g of w)
            m++, yield g;
        }
      })
    };
    yield S;
  }
  constructor() {
    this.cache = /* @__PURE__ */ new WeakMap();
  }
}
function Oe(n) {
  let e = [], t = null;
  return {
    *[Symbol.iterator]() {
      for (let l of e)
        yield l;
      t || (t = n());
      for (let l of t)
        e.push(l), yield l;
    }
  };
}
function Bt(n, e) {
  if (n && e)
    return (t) => n(e(t));
  if (n)
    return n;
  if (e)
    return e;
}
function he(n) {
  return n[0].toUpperCase() + n.slice(1);
}
function Ot(n, e, t) {
  let l = W(() => new Rt(), []), { children: r, items: o, collection: i } = n;
  return W(() => {
    if (i)
      return i;
    let d = l.build({
      children: r,
      items: o
    }, t);
    return e(d);
  }, [
    l,
    r,
    o,
    i,
    t,
    e
  ]);
}
function zt(n, e) {
  return typeof e.getChildren == "function" ? e.getChildren(n.key) : n.childNodes;
}
function Ut(n) {
  return Wt(n, 0);
}
function Wt(n, e) {
  if (e < 0)
    return;
  let t = 0;
  for (let l of n) {
    if (t === e)
      return l;
    t++;
  }
}
function tl(n) {
  let e;
  for (let t of n)
    e = t;
  return e;
}
function ye(n, e, t) {
  if (e.parentKey === t.parentKey)
    return e.index - t.index;
  let l = [
    ...ze(n, e),
    e
  ], r = [
    ...ze(n, t),
    t
  ], o = l.slice(0, r.length).findIndex((i, s) => i !== r[s]);
  return o !== -1 ? (e = l[o], t = r[o], e.index - t.index) : l.findIndex((i) => i === t) >= 0 ? 1 : (r.findIndex((i) => i === e) >= 0, -1);
}
function ze(n, e) {
  let t = [], l = e;
  for (; (l == null ? void 0 : l.parentKey) != null; )
    l = n.getItem(l.parentKey), l && t.unshift(l);
  return t;
}
class Ue {
  *[Symbol.iterator]() {
    yield* this.iterable;
  }
  get size() {
    return this.keyMap.size;
  }
  getKeys() {
    return this.keyMap.keys();
  }
  getKeyBefore(e) {
    let t = this.keyMap.get(e);
    var l;
    return t && (l = t.prevKey) !== null && l !== void 0 ? l : null;
  }
  getKeyAfter(e) {
    let t = this.keyMap.get(e);
    var l;
    return t && (l = t.nextKey) !== null && l !== void 0 ? l : null;
  }
  getFirstKey() {
    return this.firstKey;
  }
  getLastKey() {
    return this.lastKey;
  }
  getItem(e) {
    var t;
    return (t = this.keyMap.get(e)) !== null && t !== void 0 ? t : null;
  }
  at(e) {
    const t = [
      ...this.getKeys()
    ];
    return this.getItem(t[e]);
  }
  getChildren(e) {
    let t = this.keyMap.get(e);
    return (t == null ? void 0 : t.childNodes) || [];
  }
  constructor(e) {
    this.keyMap = /* @__PURE__ */ new Map(), this.firstKey = null, this.lastKey = null, this.iterable = e;
    let t = (i) => {
      if (this.keyMap.set(i.key, i), i.childNodes && i.type === "section")
        for (let s of i.childNodes)
          t(s);
    };
    for (let i of e)
      t(i);
    let l = null, r = 0;
    for (let [i, s] of this.keyMap)
      l ? (l.nextKey = i, s.prevKey = l.key) : (this.firstKey = i, s.prevKey = void 0), s.type === "item" && (s.index = r++), l = s, l.nextKey = void 0;
    var o;
    this.lastKey = (o = l == null ? void 0 : l.key) !== null && o !== void 0 ? o : null;
  }
}
class z extends Set {
  constructor(e, t, l) {
    super(e), e instanceof z ? (this.anchorKey = t ?? e.anchorKey, this.currentKey = l ?? e.currentKey) : (this.anchorKey = t ?? null, this.currentKey = l ?? null);
  }
}
function Vt(n, e) {
  if (n.size !== e.size)
    return !1;
  for (let t of n)
    if (!e.has(t))
      return !1;
  return !0;
}
function jt(n) {
  let { selectionMode: e = "none", disallowEmptySelection: t = !1, allowDuplicateSelectionEvents: l, selectionBehavior: r = "toggle", disabledBehavior: o = "all" } = n, i = F(!1), [, s] = ee(!1), d = F(null), c = F(null), [, u] = ee(null), h = W(() => We(n.selectedKeys), [
    n.selectedKeys
  ]), p = W(() => We(n.defaultSelectedKeys, new z()), [
    n.defaultSelectedKeys
  ]), [f, $] = ht(h, p, n.onSelectionChange), S = W(() => n.disabledKeys ? new Set(n.disabledKeys) : /* @__PURE__ */ new Set(), [
    n.disabledKeys
  ]), [m, b] = ee(r);
  r === "replace" && m === "toggle" && typeof f == "object" && f.size === 0 && b("replace");
  let w = F(r);
  return j(() => {
    r !== w.current && (b(r), w.current = r);
  }, [
    r
  ]), {
    selectionMode: e,
    disallowEmptySelection: t,
    selectionBehavior: m,
    setSelectionBehavior: b,
    get isFocused() {
      return i.current;
    },
    setFocused(g) {
      i.current = g, s(g);
    },
    get focusedKey() {
      return d.current;
    },
    get childFocusStrategy() {
      return c.current;
    },
    setFocusedKey(g, P = "first") {
      d.current = g, c.current = P, u(g);
    },
    selectedKeys: f,
    setSelectedKeys(g) {
      (l || !Vt(g, f)) && $(g);
    },
    disabledKeys: S,
    disabledBehavior: o
  };
}
function We(n, e) {
  return n ? n === "all" ? "all" : new z(n) : e;
}
class Ht {
  /**
  * The type of selection that is allowed in the collection.
  */
  get selectionMode() {
    return this.state.selectionMode;
  }
  /**
  * Whether the collection allows empty selection.
  */
  get disallowEmptySelection() {
    return this.state.disallowEmptySelection;
  }
  /**
  * The selection behavior for the collection.
  */
  get selectionBehavior() {
    return this.state.selectionBehavior;
  }
  /**
  * Sets the selection behavior for the collection.
  */
  setSelectionBehavior(e) {
    this.state.setSelectionBehavior(e);
  }
  /**
  * Whether the collection is currently focused.
  */
  get isFocused() {
    return this.state.isFocused;
  }
  /**
  * Sets whether the collection is focused.
  */
  setFocused(e) {
    this.state.setFocused(e);
  }
  /**
  * The current focused key in the collection.
  */
  get focusedKey() {
    return this.state.focusedKey;
  }
  /** Whether the first or last child of the focused key should receive focus. */
  get childFocusStrategy() {
    return this.state.childFocusStrategy;
  }
  /**
  * Sets the focused key.
  */
  setFocusedKey(e, t) {
    (e == null || this.collection.getItem(e)) && this.state.setFocusedKey(e, t);
  }
  /**
  * The currently selected keys in the collection.
  */
  get selectedKeys() {
    return this.state.selectedKeys === "all" ? new Set(this.getSelectAllKeys()) : this.state.selectedKeys;
  }
  /**
  * The raw selection value for the collection.
  * Either 'all' for select all, or a set of keys.
  */
  get rawSelection() {
    return this.state.selectedKeys;
  }
  /**
  * Returns whether a key is selected.
  */
  isSelected(e) {
    if (this.state.selectionMode === "none")
      return !1;
    let t = this.getKey(e);
    return t == null ? !1 : this.state.selectedKeys === "all" ? this.canSelectItem(t) : this.state.selectedKeys.has(t);
  }
  /**
  * Whether the selection is empty.
  */
  get isEmpty() {
    return this.state.selectedKeys !== "all" && this.state.selectedKeys.size === 0;
  }
  /**
  * Whether all items in the collection are selected.
  */
  get isSelectAll() {
    if (this.isEmpty)
      return !1;
    if (this.state.selectedKeys === "all")
      return !0;
    if (this._isSelectAll != null)
      return this._isSelectAll;
    let e = this.getSelectAllKeys(), t = this.state.selectedKeys;
    return this._isSelectAll = e.every((l) => t.has(l)), this._isSelectAll;
  }
  get firstSelectedKey() {
    let e = null;
    for (let l of this.state.selectedKeys) {
      let r = this.collection.getItem(l);
      (!e || r && ye(this.collection, r, e) < 0) && (e = r);
    }
    var t;
    return (t = e == null ? void 0 : e.key) !== null && t !== void 0 ? t : null;
  }
  get lastSelectedKey() {
    let e = null;
    for (let l of this.state.selectedKeys) {
      let r = this.collection.getItem(l);
      (!e || r && ye(this.collection, r, e) > 0) && (e = r);
    }
    var t;
    return (t = e == null ? void 0 : e.key) !== null && t !== void 0 ? t : null;
  }
  get disabledKeys() {
    return this.state.disabledKeys;
  }
  get disabledBehavior() {
    return this.state.disabledBehavior;
  }
  /**
  * Extends the selection to the given key.
  */
  extendSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single") {
      this.replaceSelection(e);
      return;
    }
    let t = this.getKey(e);
    if (t == null)
      return;
    let l;
    if (this.state.selectedKeys === "all")
      l = new z([
        t
      ], t, t);
    else {
      let i = this.state.selectedKeys;
      var r;
      let s = (r = i.anchorKey) !== null && r !== void 0 ? r : t;
      l = new z(i, s, t);
      var o;
      for (let d of this.getKeyRange(s, (o = i.currentKey) !== null && o !== void 0 ? o : t))
        l.delete(d);
      for (let d of this.getKeyRange(t, s))
        this.canSelectItem(d) && l.add(d);
    }
    this.state.setSelectedKeys(l);
  }
  getKeyRange(e, t) {
    let l = this.collection.getItem(e), r = this.collection.getItem(t);
    return l && r ? ye(this.collection, l, r) <= 0 ? this.getKeyRangeInternal(e, t) : this.getKeyRangeInternal(t, e) : [];
  }
  getKeyRangeInternal(e, t) {
    var l;
    if (!((l = this.layoutDelegate) === null || l === void 0) && l.getKeyRange)
      return this.layoutDelegate.getKeyRange(e, t);
    let r = [], o = e;
    for (; o != null; ) {
      let i = this.collection.getItem(o);
      if (i && (i.type === "item" || i.type === "cell" && this.allowsCellSelection) && r.push(o), o === t)
        return r;
      o = this.collection.getKeyAfter(o);
    }
    return [];
  }
  getKey(e) {
    let t = this.collection.getItem(e);
    if (!t || t.type === "cell" && this.allowsCellSelection)
      return e;
    for (; t && t.type !== "item" && t.parentKey != null; )
      t = this.collection.getItem(t.parentKey);
    return !t || t.type !== "item" ? null : t.key;
  }
  /**
  * Toggles whether the given key is selected.
  */
  toggleSelection(e) {
    if (this.selectionMode === "none")
      return;
    if (this.selectionMode === "single" && !this.isSelected(e)) {
      this.replaceSelection(e);
      return;
    }
    let t = this.getKey(e);
    if (t == null)
      return;
    let l = new z(this.state.selectedKeys === "all" ? this.getSelectAllKeys() : this.state.selectedKeys);
    l.has(t) ? l.delete(t) : this.canSelectItem(t) && (l.add(t), l.anchorKey = t, l.currentKey = t), !(this.disallowEmptySelection && l.size === 0) && this.state.setSelectedKeys(l);
  }
  /**
  * Replaces the selection with only the given key.
  */
  replaceSelection(e) {
    if (this.selectionMode === "none")
      return;
    let t = this.getKey(e);
    if (t == null)
      return;
    let l = this.canSelectItem(t) ? new z([
      t
    ], t, t) : new z();
    this.state.setSelectedKeys(l);
  }
  /**
  * Replaces the selection with the given keys.
  */
  setSelectedKeys(e) {
    if (this.selectionMode === "none")
      return;
    let t = new z();
    for (let l of e) {
      let r = this.getKey(l);
      if (r != null && (t.add(r), this.selectionMode === "single"))
        break;
    }
    this.state.setSelectedKeys(t);
  }
  getSelectAllKeys() {
    let e = [], t = (l) => {
      for (; l != null; ) {
        if (this.canSelectItem(l)) {
          var r;
          let i = this.collection.getItem(l);
          (i == null ? void 0 : i.type) === "item" && e.push(l);
          var o;
          i != null && i.hasChildNodes && (this.allowsCellSelection || i.type !== "item") && t((o = (r = Ut(zt(i, this.collection))) === null || r === void 0 ? void 0 : r.key) !== null && o !== void 0 ? o : null);
        }
        l = this.collection.getKeyAfter(l);
      }
    };
    return t(this.collection.getFirstKey()), e;
  }
  /**
  * Selects all items in the collection.
  */
  selectAll() {
    !this.isSelectAll && this.selectionMode === "multiple" && this.state.setSelectedKeys("all");
  }
  /**
  * Removes all keys from the selection.
  */
  clearSelection() {
    !this.disallowEmptySelection && (this.state.selectedKeys === "all" || this.state.selectedKeys.size > 0) && this.state.setSelectedKeys(new z());
  }
  /**
  * Toggles between select all and an empty selection.
  */
  toggleSelectAll() {
    this.isSelectAll ? this.clearSelection() : this.selectAll();
  }
  select(e, t) {
    this.selectionMode !== "none" && (this.selectionMode === "single" ? this.isSelected(e) && !this.disallowEmptySelection ? this.toggleSelection(e) : this.replaceSelection(e) : this.selectionBehavior === "toggle" || t && (t.pointerType === "touch" || t.pointerType === "virtual") ? this.toggleSelection(e) : this.replaceSelection(e));
  }
  /**
  * Returns whether the current selection is equal to the given selection.
  */
  isSelectionEqual(e) {
    if (e === this.state.selectedKeys)
      return !0;
    let t = this.selectedKeys;
    if (e.size !== t.size)
      return !1;
    for (let l of e)
      if (!t.has(l))
        return !1;
    for (let l of t)
      if (!e.has(l))
        return !1;
    return !0;
  }
  canSelectItem(e) {
    var t;
    if (this.state.selectionMode === "none" || this.state.disabledKeys.has(e))
      return !1;
    let l = this.collection.getItem(e);
    return !(!l || !(l == null || (t = l.props) === null || t === void 0) && t.isDisabled || l.type === "cell" && !this.allowsCellSelection);
  }
  isDisabled(e) {
    var t, l;
    return this.state.disabledBehavior === "all" && (this.state.disabledKeys.has(e) || !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.isDisabled));
  }
  isLink(e) {
    var t, l;
    return !!(!((l = this.collection.getItem(e)) === null || l === void 0 || (t = l.props) === null || t === void 0) && t.href);
  }
  getItemProps(e) {
    var t;
    return (t = this.collection.getItem(e)) === null || t === void 0 ? void 0 : t.props;
  }
  constructor(e, t, l) {
    this.collection = e, this.state = t;
    var r;
    this.allowsCellSelection = (r = l == null ? void 0 : l.allowsCellSelection) !== null && r !== void 0 ? r : !1, this._isSelectAll = null, this.layoutDelegate = (l == null ? void 0 : l.layoutDelegate) || null;
  }
}
function ll(n) {
  let { filter: e, layoutDelegate: t } = n, l = jt(n), r = W(() => n.disabledKeys ? new Set(n.disabledKeys) : /* @__PURE__ */ new Set(), [
    n.disabledKeys
  ]), o = je((u) => e ? new Ue(e(u)) : new Ue(u), [
    e
  ]), i = W(() => ({
    suppressTextValueWarning: n.suppressTextValueWarning
  }), [
    n.suppressTextValueWarning
  ]), s = Ot(n, o, i), d = W(() => new Ht(s, l, {
    layoutDelegate: t
  }), [
    s,
    l,
    t
  ]);
  const c = F(null);
  return j(() => {
    if (l.focusedKey != null && !s.getItem(l.focusedKey) && c.current) {
      const b = c.current.getItem(l.focusedKey), w = [
        ...c.current.getKeys()
      ].map((E) => {
        const A = c.current.getItem(E);
        return (A == null ? void 0 : A.type) === "item" ? A : null;
      }).filter((E) => E !== null), g = [
        ...s.getKeys()
      ].map((E) => {
        const A = s.getItem(E);
        return (A == null ? void 0 : A.type) === "item" ? A : null;
      }).filter((E) => E !== null);
      var u, h;
      const P = ((u = w == null ? void 0 : w.length) !== null && u !== void 0 ? u : 0) - ((h = g == null ? void 0 : g.length) !== null && h !== void 0 ? h : 0);
      var p, f, $;
      let x = Math.min(P > 1 ? Math.max(((p = b == null ? void 0 : b.index) !== null && p !== void 0 ? p : 0) - P + 1, 0) : (f = b == null ? void 0 : b.index) !== null && f !== void 0 ? f : 0, (($ = g == null ? void 0 : g.length) !== null && $ !== void 0 ? $ : 0) - 1), B = null, I = !1;
      for (; x >= 0; ) {
        if (!d.isDisabled(g[x].key)) {
          B = g[x];
          break;
        }
        if (x < g.length - 1 && !I)
          x++;
        else {
          I = !0;
          var S, m;
          x > ((S = b == null ? void 0 : b.index) !== null && S !== void 0 ? S : 0) && (x = (m = b == null ? void 0 : b.index) !== null && m !== void 0 ? m : 0), x--;
        }
      }
      l.setFocusedKey(B ? B.key : null);
    }
    c.current = s;
  }, [
    s,
    d,
    l,
    l.focusedKey
  ]), {
    collection: s,
    disabledKeys: r,
    selectionManager: d
  };
}
export {
  G as $,
  ht as a,
  ll as b,
  at as c,
  At as d,
  el as e,
  Jt as f,
  _t as g,
  zt as h,
  Zt as i,
  ft as j,
  tl as k,
  Le as l,
  Qt as m,
  bt as n,
  Ue as o
};
