import { createPortal as c } from "react-dom";
import a, { forwardRef as l, useContext as d, createContext as o } from "react";
import { b as f } from "./utils.ec96ba96.js";
if (typeof HTMLTemplateElement < "u") {
  const e = Object.getOwnPropertyDescriptor(Node.prototype, "firstChild").get;
  Object.defineProperty(HTMLTemplateElement.prototype, "firstChild", {
    configurable: !0,
    enumerable: !0,
    get: function() {
      return this.dataset.reactAriaHidden ? this.content.firstChild : e.call(this);
    }
  });
}
const n = /* @__PURE__ */ o(!1), u = typeof DocumentFragment < "u" ? new DocumentFragment() : null;
function $(e) {
  let t = d(n), i = f();
  if (t)
    return /* @__PURE__ */ a.createElement(a.Fragment, null, e.children);
  let r = /* @__PURE__ */ a.createElement(n.Provider, {
    value: !0
  }, e.children);
  return i ? /* @__PURE__ */ a.createElement("template", {
    "data-react-aria-hidden": !0
  }, r) : /* @__PURE__ */ c(r, u);
}
function h(e) {
  let t = (i, r) => d(n) ? null : e(i, r);
  return t.displayName = e.displayName || e.name, l(t);
}
function g() {
  return d(n);
}
export {
  h as $,
  $ as a,
  g as b
};
