import { useLastFourDigits, useStoredPaymentActions } from './useStoredPaymentActionsStore';

const useStoredPayment = () => {
  const lastFourDigits = useLastFourDigits();
  const { setLastFourDigits } = useStoredPaymentActions();

  const updateDigits = (digits: string) => setLastFourDigits(digits);

  return { lastFourDigits, updateDigits };
};

export { useStoredPayment };
