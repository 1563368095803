import { useQuery } from '@tanstack/react-query';
import { getOrders } from '../api';

type UseOrderQueryProps = {
  id: string;
};

const ORDERS_QUERY_KEY = {
  shared: ['user', 'orders'],
  personal: (id: string | null) => ['user', 'orders', id],
};

const useOrderQuery = ({ id = '' }: UseOrderQueryProps) =>
  useQuery({
    enabled: !!id,
    queryKey: ORDERS_QUERY_KEY.personal(id),
    queryFn: () => getOrders(id || ''),
  });

export { useOrderQuery, ORDERS_QUERY_KEY };
export type { UseOrderQueryProps };
