import { omit, map, orderBy } from 'lodash';
// Specifically require `index`, because the default entry module (`entry-node`) requires ALL locales:
import countries from 'i18n-iso-countries/index';
import { i18n } from 'next-i18next';
import { baseLocale } from 'config';
import { BLACKLISTED_COUNTRIES } from 'constants/blacklists';
import { getLanguage } from 'utils/locale';
import { getLocale } from 'services/site';
// FIXME: File should be transformed to TS
import { LANGUAGE } from 'modules/market/constants';

const OVERRIDES = {
  'United States of America': 'US',
  'United States': 'US',
};

const registerCountries = (localeCountries) => countries.registerLocale(localeCountries);

const getCountries = () => {
  const locale = getLocale() || baseLocale;
  return omit(
    countries.getNames(getLanguage(locale)),
    BLACKLISTED_COUNTRIES.map((code) => code.toUpperCase()),
  );
};

const normalizeCountryCode = (countryCode) =>
  (countryCode.length === 3
    ? countries.alpha3ToAlpha2(countryCode.toUpperCase())
    : countryCode
  ).toLowerCase();

const getCountryName = (
  countryCode,
  { overrideIgnored = false, inNativeLanguage = false } = {},
) => {
  countryCode = normalizeCountryCode(countryCode);

  if (inNativeLanguage) {
    return LANGUAGE[countryCode] || LANGUAGE.en;
  }

  const countryNames = getCountries();
  const countryName = countryNames[countryCode.toUpperCase()] || i18n.t(`countries.${countryCode}`);

  return !overrideIgnored ? OVERRIDES[countryName] ?? countryName : countryName;
};

const getCountryCodeFromCountryName = (countryName) =>
  OVERRIDES[countryName] || countries.getAlpha2Code(countryName, 'en');

const getCountriesOptions = () =>
  orderBy(
    map(getCountries(), (name, countryCode) => ({
      name,
      value: countryCode.toLowerCase(),
    })),
    ['name'],
    ['asc'],
  );

export {
  registerCountries,
  getCountries,
  normalizeCountryCode,
  getCountryName,
  getCountryCodeFromCountryName,
  getCountriesOptions,
};
