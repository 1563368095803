import addDays from 'date-fns/add_days';
import store from 'services/store';

const PREVENT_MARKET_SUGGESTION_DAYS = 30;
const PREVENT_SUGGEST_MARKET_NAME = 'suggestMarketDismissed';

const storeMarketPreference = () => {
  store.set(PREVENT_SUGGEST_MARKET_NAME, '1', {
    ttl: addDays(new Date(), PREVENT_MARKET_SUGGESTION_DAYS),
  });
};

export { storeMarketPreference, PREVENT_SUGGEST_MARKET_NAME };
