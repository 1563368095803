import { useEffect } from 'react';

type UseFocusAdyenCardFieldsProps = {
  fieldsRef: React.MutableRefObject<HTMLDivElement | null>;
  ready?: boolean;
  iframeHeight: `${number}px`;
};

const useFocusAdyenCardFields = ({
  iframeHeight,
  fieldsRef,
  ready,
}: UseFocusAdyenCardFieldsProps) => {
  useEffect(() => {
    if (!ready) {
      return;
    }

    const fieldIframes = fieldsRef.current?.querySelectorAll('iframe');

    // Increase the clickable area of the input by increasing the height of the iframe
    fieldIframes?.forEach((fieldIframe) => {
      if (fieldIframe) {
        fieldIframe.style.setProperty('height', iframeHeight);
      }
    });
  }, [iframeHeight, fieldsRef, ready]);
};

export { useFocusAdyenCardFields };
